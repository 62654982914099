import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidEmailRegExp } from '@core/constants/patterns';
import { LocalStorageKey } from '@core/enums/local-storage-key.enum';
import { Locale } from '@core/enums/locale';
import { LocalStorageService } from '@core/services/local-storage.service';
import { AppState } from '@core/store';
import { selectUser, selectUserLoading } from '@core/store/user';
import { UserModel } from '@core/store/user/user-state-models';
import { fetchUser, updateUser } from '@core/store/user/user.actions';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { emailValidator } from '@shared/utils/email-validator-utils';
import { b2cPolicies, createB2CUrl } from 'app/auth-config';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

enum profileFormKey {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phoneNumber = 'phoneNumber',
  newsletters = 'newsletters',
  shareWithConsultant = 'shareWithConsultant',
}

@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  readonly profileFormKey = profileFormKey;
  readonly OldPasswordRequired = 'oldPasswordRequired';

  user$: Observable<any>;
  disableSave$: Observable<boolean>;
  phoneNumberIsVerified$: Observable<boolean>;
  profileForm: FormGroup;
  userSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private store$: Store<AppState>,
    private localStorageService: LocalStorageService,
    @Inject(LOCALE_ID) private localeId: Locale,
  ) {}

  ngOnInit(): void {
    this.profileForm = this.fb.group({
      [profileFormKey.firstName]: ['', Validators.required],
      [profileFormKey.lastName]: ['', Validators.required],
      [profileFormKey.email]: ['', [Validators.required, emailValidator(ValidEmailRegExp)]],
      [profileFormKey.phoneNumber]: '',
      [profileFormKey.newsletters]: false,
      [profileFormKey.shareWithConsultant]: true,
    });

    this.store$.dispatch(fetchUser());
    this.user$ = this.store$.select(selectUser);
    this.disableSave$ = this.store$.select(selectUserLoading);
    this.userSubscription = this.user$.subscribe((user) => {
      this.updateForm(user);
    });
    this.phoneNumberIsVerified$ = combineLatest([
      this.profileForm.get(profileFormKey.phoneNumber).valueChanges,
      this.user$,
    ]).pipe(map(([value, user]) => user.phoneNumberValidated && user.phoneNumber === value));
  }

  get showPhoneVerificationPart(): boolean {
    return (
      !!this.profileForm.get(this.profileFormKey.phoneNumber).value &&
      environment.feature.mobileLogin
    );
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  onCancel = (): void => {
    this.store$.dispatch(fetchUser());
  };

  onSave = (): void => {
    if (this.profileForm.valid) {
      this.store$.dispatch(updateUser({ user: this.profileForm.value }));
    }
  };

  changePassword(): void {
    const email = this.profileForm.get(profileFormKey.email).value;
    const redirectUri = `${window.location.origin}${window.location.pathname}`;

    const passwordChangeUrl = createB2CUrl(
      b2cPolicies.names.profileEditPasswordChange,
      this.localeId,
      redirectUri,
    );

    if (email) {
      window.location.href = `${passwordChangeUrl}&prefill_email=${email}`;
    }
  }

  verifyPhoneNumber(): void {
    const phoneNumber = this.profileForm.get(profileFormKey.phoneNumber).value;
    if (!phoneNumber) return;

    const email = this.profileForm.get(profileFormKey.email).value;
    const redirectUri = `${window.location.origin}${window.location.pathname}`;

    const verifyPhoneNumberUrl = createB2CUrl(
      b2cPolicies.names.verifyPhoneNumber,
      this.localeId,
      redirectUri,
    );

    this.localStorageService.setItem(LocalStorageKey.toVerifyPhoneNumber, phoneNumber);
    // eslint-disable-next-line max-len
    window.location.href = `${verifyPhoneNumberUrl}&prefill_phone=${phoneNumber}&prefill_email=${email}`;
  }

  updateForm = (user: UserModel): void => {
    this.profileForm.patchValue(user);
  };

  validInput = (controlName: string): boolean => {
    const formControl = this.profileForm.get(controlName);
    if (!formControl.touched) {
      return true;
    }
    return formControl.valid;
  };
}

import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserRoute } from '@core/constants/routes.const';
import { MobileMedia } from '@core/constants/screen-sizes';
import { AuthService } from '@core/services/auth.service';
import { isUsaEnv } from '@shared/utils/environment-utils';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit, OnDestroy {
  readonly isUsaEnv = isUsaEnv;
  readonly UserRoute = UserRoute;
  navBarActive: boolean;
  activeId: string = '';
  subscriptions: Subscription = new Subscription();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
  ) {}

  deactivate(): void {
    if (this.isInMobileView()) {
      this.navBarActive = false;
      this.activeId = '';
    }
  }

  activate(): void {
    if (this.isInMobileView()) {
      this.navBarActive = true;
    }
  }

  ngOnInit(): void {
    this.onNavigation();
    this.subscriptions.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => this.onNavigation()),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  logout() {
    this.authService.logOut();
    this.router.navigate(['/']);
  }

  private isInMobileView(): boolean {
    return this.breakpointObserver.isMatched(MobileMedia);
  }

  private isInCurrentRoute(route: string): boolean {
    return this.router.url.endsWith(route) || this.router.url.endsWith(route + '/');
  }

  private navigateToProfile(): void {
    this.activeId = UserRoute.Profile;
    this.router.navigate([UserRoute.Profile], { relativeTo: this.activatedRoute });
  }

  private onNavigation(): void {
    if (!this.isInMobileView()) {
      if (this.isInCurrentRoute(UserRoute.MyAccount)) {
        this.navigateToProfile();
      } else {
        this.activeId = this.router.url.split('/').pop();
      }
    }
  }
}

<div class="root container my-4">
  <div class="row">
    <div class="col-12 col-md-6 container">
      <div class="row align-items-center">
        <h2 i18n class="col-6 font-weight-bolder">Sign in</h2>
        <a i18n *ngIf="mobileLogin" class="col-6 text-right" routerLink="/{{ LoginViaPhone }}"
          >Use mobile number</a
        >
      </div>
      <iframe
        id="loginFrame"
        #loginframe
        [src]="(prefillEmail ? B2CSignin + '?prefill_email=' + prefillEmail : B2CSignin) | safeUrl"
        frameborder="0"
        scrolling="no"
      >
      </iframe>
      <div *ngIf="showRegisterButton" class="row">
        <div class="col-12 my-3">
          <label i18n class="font-size-smaller"
            >Don't have an account yet? Create one by clicking the button below.</label
          >
          <button
            i18n
            class="w-100 btn btn-outline-dark btn-lg btn-uppercase-lg"
            routerLink="/{{ RegistrationRoute }}"
          >
            Create an account
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

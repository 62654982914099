/* eslint-disable indent */
import { Inject, Injectable } from '@angular/core';
import { ProductRoutes } from '@core/constants/routes.const';
import { ConsultantFinancialStateEnum } from '@core/enums/consultant-financial-state.enum';
import { MediaSuffix } from '@core/enums/media-suffix.enum';
import { ProductType } from '@core/enums/product-type.enum';
import { RelatedCarouselSlide } from '@core/models/related-carousel-slide.model';
import { AppInitService } from '@core/services/app-init.service';
import { AppState } from '@core/store';
import { AddToCartConfirmModelData } from '@core/store/cart/cart-state-models';
import { selectConsultantFinancialState } from '@core/store/consultant';
import {
  ProductDetailsItem,
  ProductItem,
  ProductQuickView,
  ProductRecommendation,
} from '@core/store/product/product-state-models';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AltTextUtilService } from './alt-text-util.service';
import { IS_USA_ENV } from './environment-utils';

@Injectable({
  providedIn: 'root',
})
export class ProductUtilService {
  isShoppingEnabled$: Observable<boolean> = this.isUsaEnv
    ? of(this.appInitService.Settings.ec.isCartEnabled)
    : this.store$
        .select(selectConsultantFinancialState)
        .pipe(
          map((financialState) =>
            financialState === ConsultantFinancialStateEnum.NotAllowedToPlaceOrders
              ? false
              : this.appInitService.Settings.ec.isCartEnabled,
          ),
        );

  constructor(
    private altTextUtil: AltTextUtilService,
    private store$: Store<AppState>,
    private appInitService: AppInitService,
    @Inject(IS_USA_ENV) private isUsaEnv: boolean,
  ) {}

  createProductDetailsUrl(collectionUrl: string, productUrl: string, sku: string): string {
    if (!sku) {
      return '';
    }

    let url = `/${ProductRoutes.Product}/`;
    if (collectionUrl && productUrl) {
      url += `${collectionUrl}/${productUrl}/`;
    }
    url += `${ProductRoutes.P}/${sku}`;
    return url.toLowerCase();
  }

  getRelatedSlidesFromRecommendations(
    recommendations: ProductRecommendation[],
  ): RelatedCarouselSlide[] {
    return recommendations.map((r) => ({
      images: this.altTextUtil.getProductImagesWithAltText(r.primaryImages, {
        productName: r.name,
        collectionName: r.collectionName,
        suffix: MediaSuffix.primaryImage,
      }),
      targetUrl: this.createProductDetailsUrl(r.collectionUrlName, r.productUrlName, r.sku),
    }));
  }

  getAddToCartButtonVisibility$(
    product: ProductDetailsItem | ProductItem | ProductQuickView | AddToCartConfirmModelData,
  ): Observable<boolean> {
    if (!this.appInitService.Settings.ec.isCartEnabled) {
      return of(false);
    }

    if (!product.price) {
      return of(false);
    }

    switch (product.type) {
      case ProductType.ItemNoLongerAvailable:
      case ProductType.CatalogItemOutOfStock:
      case ProductType.HostSpecialOutOfCatalog:
      case ProductType.ThreeBooking:
      case ProductType.BuyOneGetOne50:
      case ProductType.HostBookSpec:
      case ProductType.ComingSoon:
        return of(false);

      default:
        return this.isUsaEnv
          ? of(true)
          : this.store$
              .select(selectConsultantFinancialState)
              .pipe(
                map(
                  (financialState) =>
                    financialState !== ConsultantFinancialStateEnum.NotAllowedToPlaceOrders,
                ),
              );
    }
  }

  getProductPrice(
    product: ProductDetailsItem | ProductItem | ProductQuickView | AddToCartConfirmModelData,
  ) {
    switch (product.type) {
      case ProductType.CustomerSpecial:
      case ProductType.RegularCustomerSpecial:
      case ProductType.HealthySavingsSpecial:
      case ProductType.SurpriseBuy:
      case ProductType.CollectAndSave:
      case ProductType.ECommerceCustomerSpecial:
        return product.discountedPrice;

      default:
        return product.price;
    }
  }
}

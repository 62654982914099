import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { AuthRoutes } from '@core/constants/routes.const';
import { BaseComponent } from '@shared/components/base-component/base-component';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-login-phone',
  templateUrl: './login-phone.component.html',
  styleUrls: ['./login-phone.component.scss'],
})
export class LoginPhoneComponent extends BaseComponent implements OnInit {
  readonly B2CSigninPhone = AuthRoutes.B2CSigninPhone;

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
  ) {
    super();
  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.subscriptions.add(
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((event: EventMessage) =>
            [
              EventType.LOGIN_SUCCESS,
              EventType.ACCOUNT_ADDED,
              EventType.ACQUIRE_TOKEN_SUCCESS,
              EventType.SSO_SILENT_SUCCESS,
            ].includes(event.eventType),
          ),
        )
        .subscribe(() => this.router.navigateByUrl('/')),
    );
  }
}

import { CartRoutes } from '@core/constants/routes.const';
import { ConsultantLocatorErrorType } from '@core/enums/consultant-locator-error-type.enum';
import { LocalStorageKey } from '@core/enums/local-storage-key.enum';
import { SuccessfulStatus } from '@core/enums/successful-status.enum';
import { produce } from 'immer';
import { ConsultantState } from './consultant-state-models';
import { ConsultantActionTypes } from './consultant.actions';

export const initialState: ConsultantState = {
  currentConsultant: null,
  partiesLoading: false,
  assignedConsultant: null,
  isCurrentConsultantFetched: false,
  isAssignedConsultFetched: false,
  isConsultantCheckingDone: true,
  isCurrentConsultantCleared: false,
  isOrderCreditToHostParty: null,
  fetchCurrentConsultantSuccessfulStatus: SuccessfulStatus.BeforeCall,
  party: null,
  consultantLocatorErrorType: ConsultantLocatorErrorType.None,
  consultantFinder: {
    loading: false,
    hasMore: true,
    results: [],
  },
  isOutdatedData: true,
  openPartyVerificationFailedModal: false,
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case ConsultantActionTypes.fetchCurrentConsultantByVanityName:
    case ConsultantActionTypes.fetchCurrentConsultantByLoginName:
      draft.fetchCurrentConsultantSuccessfulStatus = SuccessfulStatus.BeforeCall;
      draft.isConsultantCheckingDone = false;
      return;

    case ConsultantActionTypes.fetchCurrentConsultantSuccess:
      draft.isCurrentConsultantCleared = false;
      draft.currentConsultant = action.consultant;
      draft.isCurrentConsultantFetched = true;
      draft.isConsultantCheckingDone = true;
      draft.fetchCurrentConsultantSuccessfulStatus = SuccessfulStatus.Success;
      draft.isOutdatedData = action.isPartyEnabled;
      return;

    case ConsultantActionTypes.fetchCurrentConsultantFailure:
      draft.isCurrentConsultantCleared = false;
      draft.isCurrentConsultantFetched = true;
      draft.fetchCurrentConsultantSuccessfulStatus = SuccessfulStatus.Failed;
      draft.currentConsultant = initialState.currentConsultant;
      draft.isOutdatedData = false;
      return;

    case ConsultantActionTypes.resetCurrentConsultant:
      draft.isCurrentConsultantCleared = true;
      draft.currentConsultant = initialState.currentConsultant;
      draft.isCurrentConsultantFetched = action.isCurrentConsultantFetched;
      draft.party = initialState.party;
      return;

    case ConsultantActionTypes.resetFetchCurrentConsultantSuccessfulStatus:
      draft.fetchCurrentConsultantSuccessfulStatus =
        initialState.fetchCurrentConsultantSuccessfulStatus;
      return;

    case ConsultantActionTypes.fetchAssignedConsultantSuccess:
      draft.assignedConsultant = action.consultant;
      draft.isAssignedConsultFetched = true;
      return;

    case ConsultantActionTypes.fetchAssignedConsultantFailure:
      draft.isAssignedConsultFetched = true;
      return;

    case ConsultantActionTypes.resetAssignedConsultant:
      draft.assignedConsultant = initialState.assignedConsultant;
      return;

    case ConsultantActionTypes.fetchAssignedConsultantOnCheckout:
      draft.isOutdatedData = true;
      return;

    case ConsultantActionTypes.fetchAssignedConsultantOnCheckoutSuccess:
      draft.currentConsultant = action.consultant;
      draft.isOutdatedData = false;
      return;

    case ConsultantActionTypes.fetchAssignedConsultantOnCheckoutFailure:
      draft.assignedConsultant = initialState.assignedConsultant;
      draft.currentConsultant = initialState.currentConsultant;
      draft.isOutdatedData = false;
      return;

    case ConsultantActionTypes.locateConsultant:
      draft.consultantFinder.loading = true;
      draft.consultantFinder.results = initialState.consultantFinder.results;
      return;

    case ConsultantActionTypes.findConsultant:
      draft.consultantFinder.loading = true;
      if (action.payload.pageNumber === 1) {
        draft.consultantFinder.results = initialState.consultantFinder.results;
      }
      return;

    case ConsultantActionTypes.findConsultantSuccess:
    case ConsultantActionTypes.locateConsultantSuccess:
      draft.consultantFinder.loading = false;
      draft.consultantFinder.results = [...draft.consultantFinder.results, ...action.results];
      draft.consultantFinder.hasMore = action.hasMore;
      return;

    case ConsultantActionTypes.findConsultantFailure:
      draft.consultantFinder.loading = false;
      return;

    case ConsultantActionTypes.locateConsultantFailure:
      draft.consultantLocatorErrorType = action.error.status;
      draft.consultantFinder.loading = false;
      return;

    case ConsultantActionTypes.resetConsultantFinderState:
      draft.consultantFinder = initialState.consultantFinder;
      return;

    case ConsultantActionTypes.resetConsultantLocatorErrorType:
      draft.consultantLocatorErrorType = initialState.consultantLocatorErrorType;
      return;

    case ConsultantActionTypes.fetchConsultantParties:
      draft.partiesLoading = true;
      return;

    case ConsultantActionTypes.fetchConsultantPartiesSuccess:
      draft.currentConsultant.parties = action.parties;
      draft.party = action.selectedParty;
      draft.partiesLoading = false;
      draft.isOutdatedData = false;
      return;

    case ConsultantActionTypes.fetchConsultantPartiesFailure:
      draft.partiesLoading = true;
      draft.isOutdatedData = false;
      if (
        window.localStorage.getItem(LocalStorageKey.partyId) &&
        window.location.pathname === `/${CartRoutes.Checkout}`
      ) {
        draft.openPartyVerificationFailedModal = true;
      }
      return;

    case ConsultantActionTypes.resetConsultantPartyState:
      draft.currentConsultant.parties = initialState.currentConsultant.parties;
      draft.partiesLoading = initialState.partiesLoading;
      draft.party = initialState.party;
      return;

    case ConsultantActionTypes.selectParty:
      draft.party = action.party;
      return;

    case ConsultantActionTypes.resetPartyAndIsOrderCreditToHostParty:
      draft.party = initialState.party;
      draft.isOrderCreditToHostParty = initialState.isOrderCreditToHostParty;
      return;

    case ConsultantActionTypes.resetParty:
      draft.party = initialState.party;
      return;

    case ConsultantActionTypes.selectIsOrderCreditToHostParty:
      draft.isOrderCreditToHostParty = action.isOrderCreditToHostParty;
      return;

    case ConsultantActionTypes.consultantCheckingInProgress:
      draft.isConsultantCheckingDone = false;
      return;

    case ConsultantActionTypes.consultantCheckingIsDone:
      draft.isConsultantCheckingDone = true;
      return;

    case ConsultantActionTypes.setIsOutdatedData:
      draft.isOutdatedData = action.isOutdatedData;
      return;

    case ConsultantActionTypes.resetOpenPartyVerificationFailedModal:
      draft.openPartyVerificationFailedModal = initialState.openPartyVerificationFailedModal;
      return;

    default:
      return;
  }
}, initialState);

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from '@base/components/layout/default-layout/default-layout.component';
import { DEFAULT_PAGE_DESCRIPTION } from '@core/constants/page-title';
import { ConditionalContentRoutes } from '@core/constants/routes.const';
import { PageCategory } from '@core/enums/page-category.enum';
import { CategoryConditionalContentPageComponent } from './components/category-conditional-content-page/category-conditional-content-page.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        component: CategoryConditionalContentPageComponent,
        data: {
          pageCategory: PageCategory.home,
          title: $localize`Home`,
          description: DEFAULT_PAGE_DESCRIPTION,
        },
      },
      {
        path: ConditionalContentRoutes.HostessSpecialOffers,
        component: CategoryConditionalContentPageComponent,
        data: {
          pageCategory: PageCategory.hostSpecials,
          title: $localize`Current special offers for hosts`,
          describe: $localize`Current special offers for hosts - Description`,
        },
      },
      {
        path: ConditionalContentRoutes.About,
        component: CategoryConditionalContentPageComponent,
        data: {
          pageCategory: PageCategory.about,
          title: $localize`About Princess House`,
          description: $localize`About Princess House - Description`,
        },
      },
      {
        path: ConditionalContentRoutes.StartYourBusiness,
        component: CategoryConditionalContentPageComponent,
        data: {
          pageCategory: PageCategory.startYourBusiness,
          title: $localize`Learn about starting a Princess House Business`,
          description: $localize`Learn about starting a Princess House Business - Description`,
        },
      },
      {
        path: ConditionalContentRoutes.HostParty,
        component: CategoryConditionalContentPageComponent,
        data: {
          pageCategory: PageCategory.host,
          title: $localize`Learn about a Princess House party`,
          description: $localize`Learn about a Princess House party - Description`,
        },
      },
      {
        path: ConditionalContentRoutes.Event,
        component: CategoryConditionalContentPageComponent,
        data: {
          pageCategory: PageCategory.event,
          title: $localize`Event`,
          description: $localize`Event - Description`,
        },
      },
      {
        path: ConditionalContentRoutes.EventStaging1,
        component: CategoryConditionalContentPageComponent,
        data: {
          pageCategory: PageCategory.eventStaging1,
          title: $localize`Event staging 1`,
          description: $localize`Event staging 1 - Description`,
        },
      },
      {
        path: ConditionalContentRoutes.EventStaging2,
        component: CategoryConditionalContentPageComponent,
        data: {
          pageCategory: PageCategory.eventStaging2,
          title: $localize`Event staging 2`,
          description: $localize`Event staging 2 - Description`,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConditionalContentRoutingModule {}

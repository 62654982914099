<div class="container pl-md-0">
  <div class="d-flex justify-content-between align-items-center">
    <app-account-header i18n-title title="Shipping Addresses"></app-account-header>
    <button
      [disabled]="loading$ | async"
      class="btn btn-primary"
      [routerLink]="NewAddressRoute"
      i18n
    >
      New Address
    </button>
  </div>

  <div *ngIf="loading$ | async; else content" class="d-flex justify-content-center m-5">
    <app-ph-spinner width="60px"></app-ph-spinner>
  </div>

  <ng-template #content>
    <ng-container [ngTemplateOutlet]="isInMobileView() ? mobile : desktop"> </ng-container>
  </ng-template>
</div>

<ng-template #desktop>
  <app-sortable-table [columns]="columns" [rows]="addresses$ | async"></app-sortable-table>
</ng-template>

<ng-template #mobile>
  <div class="mobile address-container">
    <div class="address mb-2 p-2" *ngFor="let address of addresses$ | async">
      <a [routerLink]="address.id"><i class="bi bi-pen"></i></a>
      <div>
        <div class="font-weight-bold">{{ address.name }}</div>
        <div class="font-weight-bold" data-private>
          {{ address.addressLine1 }} {{ address.addressLine2 }} {{ address.addressLine3 }}
        </div>
        <div class="font-weight-light small-text">
          <span data-private>
            {{ address.city }}
          </span>
          <span> {{ address.state }} {{ address.zipCode }} </span>
        </div>
      </div>
      <div class="default-badge" *ngIf="address.type === addressType.default" i18n>Default</div>
    </div>
  </div>
</ng-template>

import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  AddressesResponse,
  AddressValidationResponse,
  CreateAddressRequest,
  UpdateAddressRequest,
} from '@core/dto/address.dto';
import { UserRegistrationRequest, UserRegistrationResponse } from '@core/dto/user-registration.dto';
import { UpdateUserRequest, UserResponse } from '@core/dto/user.dto';
import { Locale } from '@core/enums/locale';
import { environment } from '@env';
import { getCulture } from '@shared/utils/locale-utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  baseUrl: string = environment.ecommerceBackendUri;
  options: object;

  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) localeId: Locale,
  ) {
    this.options = {
      params: new HttpParams({
        fromObject: { culture: getCulture(localeId) },
      }),
    };
  }

  registerUser(request: UserRegistrationRequest): Observable<UserRegistrationResponse> {
    return this.http.post<UserRegistrationResponse>(
      this.baseUrl + 'api/user',
      request,
      this.options,
    );
  }

  fetchCurrentUser(): Observable<UserResponse> {
    return this.http.get<UserResponse>(`${this.baseUrl}api/user`);
  }

  updateUser(request: UpdateUserRequest): Observable<UserResponse> {
    return this.http.put<UserResponse>(`${this.baseUrl}api/user`, request, this.options);
  }

  fetchAddresses(): Observable<AddressesResponse> {
    return this.http.get<AddressesResponse>(`${this.baseUrl}api/user/address`);
  }

  createAddress(request: CreateAddressRequest): Observable<AddressValidationResponse> {
    return this.http.post<AddressValidationResponse>(`${this.baseUrl}api/user/address`, request);
  }

  updateAddress(request: UpdateAddressRequest): Observable<AddressValidationResponse> {
    return this.http.put<AddressValidationResponse>(`${this.baseUrl}api/user/address`, request);
  }

  deleteAddress(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}api/user/address/${id}`);
  }

  updateAssignedConsultant(consultantProwessId: number): Observable<any> {
    return this.http.put(
      `${this.baseUrl}api/user/consultant`,
      {},
      {
        params: new HttpParams({
          fromObject: { consultantProwessId: consultantProwessId.toString() },
        }),
      },
    );
  }
}

<div class="header-content px-3 px-md-5">
  <div class="container-fluid">
    <div class="row">
      <div class="col-4 p-0 d-flex align-items-center justify-content-start">
        <button
          class="navbar-toggler p-0"
          type="button"
          (click)="toggleMenu.emit()"
          aria-haspopup="true"
          aria-controls="navigation"
          id="navigationToggler"
        >
          <img class="icon" src="assets/images/header/ALIGN_JUSTIFY.svg" alt="navbar" />
        </button>
        <div class="d-none d-md-block w-100">
          <app-search-box></app-search-box>
        </div>
      </div>
      <div class="col-4 p-0 d-flex align-items-center justify-content-center">
        <a
          routerLink=""
          class="logo-container w-100 d-flex align-items-center justify-content-center"
        >
          <img class="icon img-fluid" src="assets/images/header/LOGO.svg" alt="logo" />
        </a>
      </div>
      <div class="col-4 p-0 d-flex align-items-center justify-content-end">
        <app-cart-icon
          *ngIf="productUtilService.isShoppingEnabled$ | async"
          [quantityCount$]="quantityCount$"
        ></app-cart-icon>
        <ng-container *ngIf="userUtilService.isUserFeatureEnabled$ | async">
          <div *ngIf="isAuthenticated$ | async; else signIn">
            <a routerLink="/{{ MyAccountRoute }}">
              <img class="icon" src="assets/images/header/USER.svg" alt="myaccount" />
            </a>
          </div>
          <ng-template #signIn>
            <a
              class="link-highlight sign-in ml-1"
              [ngClass]="{ 'sign-in-spanish-width': isSpanishLanguage }"
              routerLink="/{{ LoginRoute }}"
              i18n
              >Sign in</a
            >
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
</div>

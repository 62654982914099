<ng-container *ngIf="orderLine">
  <div class="row d-lt-none my-2">
    <div class="col-md-5">
      <div class="row">
        <div class="col">
          <a
            [routerLink]="'/{{ productRoute }}/{{ pRoute }}/' + orderLine.sku"
            [ngClass]="{ 'link-disabled': !productClickable }"
          >
            <img
              phImage
              [images]="orderLine.images"
              [thumbnailDesktop]="thumbnail.productCheckoutDesktop"
              [thumbnailMobile]="thumbnail.productCheckoutMobile"
              class="img-thumbnail"
            />
          </a>
        </div>
        <div class="col d-flex flex-column align-self-center">
          <div class="category-name">{{ orderLine.collectionName }}</div>
          <a
            [routerLink]="'/{{ productRoute }}/{{ pRoute }}/' + orderLine.sku"
            [ngClass]="{
              'product-name font-size-bigger': true,
              'link-disabled': !productClickable
            }"
          >
            {{ orderLine.productName }}
          </a>
          <div class="sku">#{{ orderLine.sku }}</div>
        </div>
      </div>
    </div>
    <div class="col-md-2 d-flex flex-column justify-content-center">
      <p
        *ngIf="orderLine.discountedPrice !== orderLine.originalPrice"
        class="text-black-50 line-through font-size-smaller"
      >
        {{ orderLine.originalPrice | phCurrency }}
      </p>
      <p
        [ngClass]="{
          'font-size-bigger': true,
          'discounted-price': orderLine.discountedPrice !== orderLine.originalPrice
        }"
        >{{ orderLine.discountedPrice | phCurrency }}</p
      >
    </div>
    <div class="col-md-2 font-size-bigger align-self-center">
      <div class="font-size-smaller" *ngIf="readOnly === true; else quantitySelector">
        {{ orderLine.quantity }}
      </div>
      <ng-template #quantitySelector>
        <select
          class="custom-select w-50"
          aria-label="quantity"
          [value]="orderLine.quantity"
          (change)="updateQty(orderLine, $event.target)"
        >
          <option
            *ngFor="let n of counter(99); let i = index"
            [value]="i + 1"
            [selected]="i + 1 === orderLine.quantity"
          >
            {{ i + 1 }}
          </option>
        </select>
      </ng-template>
    </div>
    <div class="col-md-2 font-size-bigger align-self-center">
      <p>{{ orderLine.quantity * orderLine.discountedPrice | phCurrency }}</p>
    </div>
    <div
      *ngIf="readOnly !== true"
      class="col-md-1 font-size-bigger d-flex justify-content-end align-items-center"
    >
      <button
        type="button"
        aria-label="Remove from cart"
        i18n-aria-label
        (click)="remove(orderLine.orderLineId)"
        class="bg-transparent border-0 text-primary"
      >
        <i class="bi bi-trash"></i>
      </button>
    </div>
  </div>
  <div class="row d-block d-md-none background-gray">
    <div class="root p-2">
      <a
        [routerLink]="'/{{ productRoute }}/{{ pRoute }}/' + orderLine.sku"
        [ngClass]="{ 'link-disabled': !productClickable }"
      >
        <img
          class="align-self-center w-100"
          phImage
          [images]="orderLine.images"
          [thumbnailDesktop]="thumbnail.productCheckoutDesktop"
          [thumbnailMobile]="thumbnail.productCheckoutMobile"
        />
      </a>
      <div class="d-flex flex-column">
        <div class="category-name">{{ orderLine.collectionName }}</div>
        <a
          [routerLink]="'/{{ productRoute }}/{{ pRoute }}/' + orderLine.sku"
          [ngClass]="{ 'product-name': true, 'link-disabled': !productClickable }"
          >{{ orderLine.productName }}</a
        >
        <div class="sku mb-2">{{ orderLine.sku }}</div>
        <div class="font-size-smaller" *ngIf="readOnly === true">
          <span class="text-double-dot" i18n>Qty</span>
          {{ orderLine.quantity }}
        </div>
        <div class="mb-1">
          <span
            [ngClass]="{
              'item-price mr-2': true,
              'discounted-price': orderLine.originalPrice !== orderLine.discountedPrice
            }"
            >${{ orderLine.discountedPrice }}</span
          >
          <span
            class="original-price"
            *ngIf="orderLine.originalPrice !== orderLine.discountedPrice"
          >
            ${{ orderLine.originalPrice }}
          </span>
        </div>
        <app-plus-minus-counter
          *ngIf="readOnly !== true"
          min="1"
          size="sm"
          value="{{ orderLine.quantity }}"
          (quantityChange)="updateQty(orderLine, $event)"
        ></app-plus-minus-counter>
      </div>
      <div
        *ngIf="readOnly !== true"
        class="d-flex flex-column justify-content-between align-items-end"
      >
        <a (click)="remove(orderLine.orderLineId)" class="pointer">
          <i class="bi bi-trash"></i>
        </a>
        <div class="total-price">${{ orderLine.total }}</div>
      </div>
    </div>
  </div>
</ng-container>

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sna-info-card-header',
  templateUrl: './sna-info-card-header.component.html',
  styleUrls: ['./sna-info-card-header.component.scss'],
})
export class SnaInfoCardHeaderComponent {
  @Input() cardTitle: string;
  @Input() isChangeable: boolean = true;

  @Output() navigateTo: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  navigate(): void {
    this.navigateTo.emit();
  }
}

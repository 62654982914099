<div class="card">
  <div class="ph-row p-md-2">
    <span class="text-double-dot" i18n>Name</span>
    <strong>{{ fullName }}</strong>
  </div>
  <div class="ph-row px-md-2 pb-md-2" [ngClass]="{ 'border-bottom-0': !isUsaEnv }">
    <span class="text-double-dot" i18n>Date of Birth</span>
    <strong *ngIf="dateFormat">
      {{ userInfo.birthday | date: dateFormat : 'UTC' }}
    </strong>
  </div>
  <div class="ph-row px-md-2 pb-md-2 border-bottom-0" *ngIf="isUsaEnv">
    <span class="text-double-dot" i18n>Social security number</span>
    <strong data-private>{{ maskedSsnNumber }}</strong>
  </div>
</div>

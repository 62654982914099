import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerSpecialsPageComponent } from '@base/components/customer-specials-page/customer-specials-page.component';
import { MagazineListComponent } from '@base/components/magazine-list/magazine-list.component';
import { BaseRoutes } from '@core/constants/routes.const';
import { PartyOrderPaymentFeatureGuardService } from '@core/services/party-order-payment-feature-guard.service';
import { ClearConsultantComponent } from './components/clear-consultant/clear-consultant.component';
import { ContactUsPageComponent } from './components/contact-us-page/contact-us-page.component';
import { DefaultLayoutComponent } from './components/layout/default-layout/default-layout.component';
import { PartyOrderPaymentComponent } from './components/party-order-payment/party-order-payment.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: BaseRoutes.ContactUs,
        component: ContactUsPageComponent,
        data: {
          title: $localize`PH - Contact Us Page - Title`,
          description: $localize`PH - Contact Us Page - Description`,
        },
      },
      {
        path: BaseRoutes.CatalogsAndMore,
        component: MagazineListComponent,
        data: {
          title: $localize`Browse latest catalogs and magazines`,
          description: $localize`Browse latest catalogs and magazines - Description`,
        },
      },
      {
        path: BaseRoutes.ClearConsultant,
        component: ClearConsultantComponent,
      },
      {
        path: BaseRoutes.PartyOrderPayment,
        component: PartyOrderPaymentComponent,
        canActivate: [PartyOrderPaymentFeatureGuardService],
        data: {
          title: $localize`Apply a payment to a Party Order`,
          description: $localize`Apply a payment to a Party Order - Description`,
        },
      },
      {
        path: `${BaseRoutes.PartyOrderPayment}/:orderNumber`,
        component: PartyOrderPaymentComponent,
        canActivate: [PartyOrderPaymentFeatureGuardService],
        data: {
          title: $localize`Apply a payment to a Party Order`,
          description: $localize`Apply a payment to a Party Order - Description`,
        },
      },
      {
        path: BaseRoutes.CustomerSpecialOffers,
        component: CustomerSpecialsPageComponent,
        data: {
          title: $localize`Current special offers for customers`,
          description: $localize`Current special offers for customers - Description`,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BaseRoutingModule {}

<h1 class="text-title mb-3" i18n>{{ titles[country] }}</h1>
<h6 class="text-subtitle mb-3" i18n>
  Your Starter Kit contains a selection of items to help you build your Princess House business.
</h6>

<div class="row" *ngIf="starterKits$ | async">
  <div
    class="col-12 pb-2 mb-md-3"
    [class]="{
      'col-md-8': isUsaEnv,
      'col-md-6': isMexEnv
    }"
    *ngFor="let starterKit of starterKits$ | async"
  >
    <app-starter-kit-card
      [starterKit]="starterKit"
      [isSelected]="isSelected(selectedStarterKit, starterKit)"
      (selectChange)="selectStarterKit($event)"
    ></app-starter-kit-card>
  </div>
</div>

<div class="row" *ngIf="isUsaEnv">
  <div class="col-12 col-md-8">
    <span class="font-italic" i18n>
      Note: Your Starter Kit may be received in two shipments. Products and printed material boxes
      may arrive separately in 5 – 7 business days after your order is processed for shipment. To
      ensure kits are current, products and tools included are subject to change.
    </span>
  </div>
</div>

<div

   *ngIf="isUsaEnv && {depositPaymentValue: depositPaymentValue$ | async, reducedDepositPaymentValue: reducedDepositPaymentValue$ | async} as payment"
  class="mb-4"
  ngbRadioGroup
  [(ngModel)]="selectedStartNowPaymentType"
  (ngModelChange)="onChangeStartNowPaymentType($event)"
>
  <h5 class="mt-3" i18n>Select payment option</h5>
  <div class="row radio-button-color">
    <div class="col-lg-12">
      <div class="col-12 col-md-4">
        <label ngbButtonLabel class="pl-0 py-0 font-weight-normal shadow-none">
          <input ngbButton type="radio" name="subject" [value]="StartNowPaymentType.FullPayment" />
          <span class="radio-span" i18n> Pay full price</span>
        </label>
      </div>
    
      <div class="col-12 col-md-4 text-nowrap">
        <label ngbButtonLabel class="pl-0 py-0 font-weight-normal shadow-none">
          <input ngbButton type="radio" name="subject" [value]="StartNowPaymentType.DepositAmount"  />
          <span class="radio-span"  i18n> Pay only {{ payment.depositPaymentValue | phCurrency }} deposit</span>
        </label>
      </div>

      <div class="col-12 col-md-4 text-nowrap" *ngIf="(isReducedDepositAvailable$ | async) === true">
        <label ngbButtonLabel class="pl-0 py-0 font-weight-normal shadow-none">
          <input ngbButton type="radio" name="subject" [value]="StartNowPaymentType.ReducedDepositAmount" />
          <span class="radio-span"  *ngIf="(isZeroDepositAvailable$ | async) === false" i18n>Pay {{ payment.reducedDepositPaymentValue | phCurrency }} Reduced Deposit</span>
          <span class="radio-span"  *ngIf="(isZeroDepositAvailable$ | async) === true" i18n>{{ payment.reducedDepositPaymentValue | phCurrency }}  Deposit Option*</span>
        </label>
      </div>
    </div>
  </div>
  <div class="col-12">
    <span *ngIf="isDepositPayment" class="font-italic" i18n>
      If you only pay the {{ payment.depositPaymentValue | phCurrency }} deposit, the difference will be deducted from
      your
      first comissions.
    </span>
    <span
      *ngIf="(isZeroDepositAvailable$ | async) === false && (isReducedDepositAvailable$ | async) === true && selectedStartNowPaymentType === StartNowPaymentType.ReducedDepositAmount"
      class="font-italic">
  
      By selecting {{ payment.reducedDepositPaymentValue | phCurrency }} deposit, you acknowledge that your Starter Kit
      will
      not ship until you have submitted $750 in sales subject to profit (VSG) OR paid your Starter Kit in full within 60
      days.
      Your profits will be applied to your Starter Kit balance until it is paid in full.
    </span>

    <div
      *ngIf="
        (isZeroDepositAvailable$ | async) &&
        (isReducedDepositAvailable$ | async) &&
        selectedStartNowPaymentType === StartNowPaymentType.ReducedDepositAmount
      "
    >
      <app-less-deposit-information></app-less-deposit-information>
    </div>
  </div>

  <p class="font-italic mb-5" i18n *ngIf="isMexEnv">*Tax and delivery is included in the price.</p>
  <div *ngIf="starterKitStepData$ | async as starterKitStepData">
    <div
      *ngIf="
        (!!starterKitStepData.statusCode && starterKitStepData.statusCode !== StatusCodes.OK) ||
        !!starterKitStepData.errors.length
      "
      class="validation-error-message mb-3"
    >
      <app-sna-http-status-errors
        [statusCode]="starterKitStepData.statusCode"
      ></app-sna-http-status-errors>
    </div>
  </div>
</div>
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  PatterForLettersNumbersAndSpecialChars,
  PatternForFourAnyCharacter,
  PatternForLetterAndNumberOrSpecialChar,
  PatternForOneAnyCharacter,
} from '@core/constants/patterns';

interface passwordStrengthParams {
  category: string;
  activeColor: string;
  level: number;
}

@Component({
  selector: 'app-password-strength-bar',
  templateUrl: './password-strength-bar.component.html',
  styleUrls: ['./password-strength-bar.component.scss'],
})
export class PasswordStrengthBarComponent implements OnInit, OnChanges {
  @Input()
  password: string = '';

  currentLevel: passwordStrengthParams;
  passwordBarLevels: passwordStrengthParams[];

  private regexForVeryWeak = PatternForOneAnyCharacter;
  private regexForWeak = PatternForFourAnyCharacter;
  private regexForMedium = PatternForLetterAndNumberOrSpecialChar();
  private regexForStrong = PatterForLettersNumbersAndSpecialChars;

  constructor() {}

  ngOnInit(): void {
    this.initializePasswordBar();

    if (this.password) {
      this.evaluatePassword(this.password);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.evaluatePassword(changes.password.currentValue);
  }

  private initializePasswordBar() {
    this.passwordBarLevels = [
      {
        category: $localize`Very weak`,
        activeColor: 'darkred',
        level: 1,
      },
      {
        category: $localize`Weak`,
        activeColor: 'orange',
        level: 2,
      },
      {
        category: $localize`Good`,
        activeColor: 'yellowgreen',
        level: 3,
      },
      {
        category: $localize`Strong`,
        activeColor: 'green',
        level: 4,
      },
    ];
  }

  private evaluatePassword(password: string) {
    let passwordScore = 0;
    if (this.regexForVeryWeak.test(password)) {
      passwordScore++;
    }
    if (this.regexForWeak.test(password)) {
      passwordScore++;
    }
    if (this.regexForMedium.test(password)) {
      passwordScore++;
    }
    if (this.regexForStrong.test(password)) {
      passwordScore++;
    }
    this.currentLevel = this.passwordBarLevels?.find((e) => e.level === passwordScore);
    return;
  }
}

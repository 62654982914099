<div class="container pl-md-0">
  <div class="dropdown-divider w-100 d-md-none"></div>
  <div class="d-flex d-md-none">
    <a routerLink=".." class="text-secondary" i18n>Shipping addresses ></a>
    <div class="font-weight-bold ml-1">{{ currentAddress?.name || newAddressTitle }}</div>
  </div>
  <div class="dropdown-divider w-100 d-md-none"></div>
  <a routerLink=".." type="button" class="d-none d-md-block">
    <i class="bi bi-arrow-left"></i>
    <span i18n> My Shipping Addresses</span>
  </a>
  <div class="d-flex justify-content-between align-items-center">
    <app-account-header
      class="d-md-none"
      title="{{ currentAddress?.name || newAddressTitle }}"
    ></app-account-header>
    <app-account-header
      class="d-none d-md-block"
      title="{{ currentAddress?.name || newAddressTitle }}"
    ></app-account-header>
    <button
      *ngIf="!isNewAddress"
      [disabled]="loading$ | async"
      class="btn btn-primary mb-3"
      i18n
      (click)="deleteAddress()"
    >
      Delete Address
    </button>
  </div>
  <form [formGroup]="addressForm" (ngSubmit)="onSave()">
    <div class="row">
      <div class="my-2 col-12 col-md-6">
        <div class="form-group">
          <app-text-field-input
            [identifier]="FirstName"
            label="First Name"
            i18n-label
            [formControlName]="FirstName"
          >
          </app-text-field-input>
        </div>
      </div>
      <div class="my-2 col-12 col-md-6">
        <div class="form-group">
          <app-text-field-input
            [identifier]="LastName"
            label="Last Name"
            i18n-label
            [formControlName]="LastName"
          >
          </app-text-field-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="my-2 col-12 col-md-6">
        <div class="form-group">
          <app-text-field-input
            [identifier]="Name"
            label="Address Name"
            i18n-label
            [formControlName]="Name"
          >
          </app-text-field-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="my-2 col-12 col-md-6">
        <div class="form-group">
          <app-text-field-input
            [identifier]="AddressLine1"
            label="Address"
            i18n-label
            placeholder="Address line 1"
            i18n-placeholder
            [formControlName]="AddressLine1"
            [isDataPrivate]="true"
          >
          </app-text-field-input>
        </div>
      </div>
      <div class="my-2 col-12 col-md-6 d-flex flex-column justify-content-end">
        <div class="form-group mt-2">
          <app-text-field-input
            [identifier]="AddressLine2"
            placeholder="Address line 2"
            i18n-placeholder
            [formControlName]="AddressLine2"
            [isDataPrivate]="true"
          >
          </app-text-field-input>
        </div>
      </div>
    </div>
    <div *ngIf="isMexEnv" class="row">
      <div class="mb-2 col-12 col-md-6">
        <div class="form-group">
          <app-text-field-input
            [identifier]="AddressLine3"
            placeholder="Address line 3"
            i18n-placeholder
            [formControlName]="AddressLine3"
            [isDataPrivate]="true"
          >
          </app-text-field-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="my-2 col-12 col-md-6">
        <div class="form-group">
          <app-text-field-input
            [identifier]="City"
            label="City"
            i18n-label
            i18n-placeholder
            [formControlName]="City"
            [isDataPrivate]="true"
          >
          </app-text-field-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="my-2 col-12 col-md-6">
        <div class="form-group">
          <app-select
            [identifier]="State"
            label="State"
            i18n-label
            [formControlName]="State"
            [options]="countryStates$ | async"
          ></app-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="my-2 col-12 col-md-6">
        <div class="form-group">
          <app-text-field-input
            [identifier]="ZipCode"
            label="ZIP Code"
            i18n-label
            i18n-placeholder
            [formControlName]="ZipCode"
          >
          </app-text-field-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="my-2 col-12">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            [formControlName]="Default"
            id="default"
          />
          <label i18n class="form-check-label" for="default"> Make default address </label>
        </div>
      </div>
    </div>

    <app-address-validation
      [addressName]="addressForm.value.name"
      [isDefault]="addressForm.value.default"
      [addressId]="addressId"
      [addressValidation$]="addressValidation$"
      [countryStates$]="countryStates$"
      [finalizeAddress]="finalizeAddress"
      [onDismiss]="onDismiss"
    ></app-address-validation>

    <div class="row">
      <div class="my-2 col-12 col-lg-6 d-flex">
        <button i18n class="w-100 btn btn-lg btn-uppercase-lg btn-outline-dark m-1" routerLink="..">
          Cancel
        </button>
        <button
          type="submit"
          i18n
          class="w-100 btn btn-uppercase-lg btn-lg btn-primary m-1"
          [disabled]="saveInProgress$ | async"
        >
          Save changes
        </button>
      </div>
    </div>
  </form>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaymentModule } from '@payment/payment.module';
import { SafeResourceUrlPipe } from '@shared/pipes/safe-url.pipe';
import { SharedModule } from '@shared/shared.module';
import { SnaAddressErrorMessagesComponent } from './components/sna-address-validation-modal/sna-address-validation-error-messages/sna-address-error-messages.component';
import { SnaAddressValidationModalComponent } from './components/sna-address-validation-modal/sna-address-validation-modal.component';
import { SnaConsultantSubmitComponent } from './components/sna-consultant-submit/sna-consultant-submit.component';
import { SnaDisabledPageComponent } from './components/sna-disabled-page/sna-disabled-page.component';
import { SnaHttpStatusErrorsComponent } from './components/sna-http-status-errors/sna-http-status-errors.component';
import { AddressCardComponent } from './components/sna-info-cards/address-card/address-card.component';
import { ContactInformationCardComponent } from './components/sna-info-cards/contact-information-card/contact-information-card.component';
import { LessDepositInformationComponent } from './components/sna-info-cards/less-deposit-information/less-deposit-information.component';
import { PasswordCardComponent } from './components/sna-info-cards/password-card/password-card.component';
import { PaymentCardComponent } from './components/sna-info-cards/payment-card/payment-card.component';
import { PersonalInfoCardComponent } from './components/sna-info-cards/personal-info-card/personal-info-card.component';
import { RecruiterCardComponent } from './components/sna-info-cards/recruiter-card/recruiter-card.component';
import { SnaInfoCardHeaderComponent } from './components/sna-info-cards/sna-info-card-header/sna-info-card-header.component';
import { StarterKitInfoCardComponent } from './components/sna-info-cards/starter-kit-info-card/starter-kit-info-card.component';
import { SnaQueryParamCheckerComponent } from './components/sna-query-param-checker/sna-query-param-checker.component';
import { SnaReCaptchaComponent } from './components/sna-recaptcha/sna-recaptcha.component';
import { StartNowAppConsultantStepWrapperComponent } from './components/start-now-app-consultant-step-wrapper/start-now-app-consultant-step-wrapper.component';
import { StartNowAppOfflineModalComponent } from './components/start-now-app-offline-modal/start-now-app-offline-modal.component';
import { StartNowAppProgressBarComponent } from './components/start-now-app-progress-bar/start-now-app-progress-bar.component';
import { BackForthButtonsComponent } from './components/start-now-app-stepper/back-forth-buttons/back-forth-buttons.component';
import { StartNowStepperComponent } from './components/start-now-app-stepper/start-now-app-stepper.component';
import { AboutYouStepComponent } from './components/start-now-app-steps/about-you-step/about-you-step.component';
import { ConfirmConsultantStepComponent } from './components/start-now-app-steps/confirm-consultant-step/confirm-consultant-step.component';
import { ConsultantBoxComponent } from './components/start-now-app-steps/confirm-consultant-step/consultant-box/consultant-box.component';
import { CongratulationSummaryComponent } from './components/start-now-app-steps/congratulation/congratulation-summary/congratulation-summary.component';
import { CongratulationComponent } from './components/start-now-app-steps/congratulation/congratulation.component';
import { ConnectWithYouStepComponent } from './components/start-now-app-steps/connect-with-you-step/connect-with-you-step.component';
import { ConsultantAgreementStepComponent } from './components/start-now-app-steps/consultant-agreement-step/consultant-agreement-step.component';
import { DoYouKnowConsultantComponent } from './components/start-now-app-steps/do-you-know-consultant-step/do-you-know-consultant-step.component';
import { ConsultantSubmitFailedModalComponent } from './components/start-now-app-steps/double-check-step/consultant-submit-failed-modal/consultant-submit-failed-modal.component';
import { DoubleCheckStepComponent } from './components/start-now-app-steps/double-check-step/double-check-step.component';
import { FindConsultantByZipCodeComponent } from './components/start-now-app-steps/find-consultant-by-zip-code/find-consultant-by-zip-code.component';
import { PasswordBackOfficeStepComponent } from './components/start-now-app-steps/password-backoffice-portal-step/password-backoffice-portal-step.component';
import { PasswordStrengthBarComponent } from './components/start-now-app-steps/password-backoffice-portal-step/password-strength-bar/password-strength-bar.component';
import { CreateVoucherPaymentErrorComponent } from './components/start-now-app-steps/payment-step/create-voucher-payment-error/create-voucher-payment-error.component';
import { OrderSummaryComponent } from './components/start-now-app-steps/payment-step/order-summary/order-summary.component';
import { PaymentStepComponent } from './components/start-now-app-steps/payment-step/payment-step.component';
import { PaymentTypeChangedModalComponent } from './components/start-now-app-steps/payment-step/payment-type-changed-modal/payment-type-changed-modal.component';
import { PaymentTypeSelectorComponent } from './components/start-now-app-steps/payment-step/payment-type-selector/payment-type-selector.component';
import { RegistrationCodeStepComponent } from './components/start-now-app-steps/registration-code-step/registration-code-step.component';
import { NoConsultantFoundModalComponent } from './components/start-now-app-steps/select-consultant-step/no-consultant-found-modal/no-consultant-found-modal.component';
import { SelectConsultantStepComponent } from './components/start-now-app-steps/select-consultant-step/select-consultant-step.component';
import { SelectStarterKitStepComponent } from './components/start-now-app-steps/select-starter-kit-step/select-starter-kit-step.component';
import { StarterKitCardComponent } from './components/start-now-app-steps/select-starter-kit-step/starter-kit-card/starter-kit-card.component';
import { WaitBusinessToolsStepComponent } from './components/start-now-app-steps/wait-business-tools-step/wait-business-tools-step.component';
import { StartNowAppComponent } from './components/start-now-app.component';
import { StartNowAppRoutingModule } from './start-now-app-routing.module';

// TODO EC-3876
// export function appInitializerFactory(appInitService: AppInitService) {
//   return () => appInitService.initSnaConfiguration();
// }

@NgModule({
  declarations: [
    StartNowAppComponent,
    DoYouKnowConsultantComponent,
    SelectConsultantStepComponent,
    FindConsultantByZipCodeComponent,
    StartNowStepperComponent,
    StartNowAppConsultantStepWrapperComponent,
    ConfirmConsultantStepComponent,
    AboutYouStepComponent,
    ConnectWithYouStepComponent,
    WaitBusinessToolsStepComponent,
    SelectStarterKitStepComponent,
    PaymentStepComponent,
    PasswordBackOfficeStepComponent,
    ConsultantAgreementStepComponent,
    DoubleCheckStepComponent,
    BackForthButtonsComponent,
    StartNowAppProgressBarComponent,
    PasswordStrengthBarComponent,
    StarterKitCardComponent,
    CongratulationComponent,
    CongratulationSummaryComponent,
    ConsultantBoxComponent,
    NoConsultantFoundModalComponent,
    PaymentCardComponent,
    SnaAddressValidationModalComponent,
    SnaAddressErrorMessagesComponent,
    CreateVoucherPaymentErrorComponent,
    PaymentTypeChangedModalComponent,
    OrderSummaryComponent,
    PasswordCardComponent,
    AddressCardComponent,
    PersonalInfoCardComponent,
    ContactInformationCardComponent,
    SnaInfoCardHeaderComponent,
    StarterKitInfoCardComponent,
    RecruiterCardComponent,
    LessDepositInformationComponent,
    ConsultantSubmitFailedModalComponent,
    StartNowAppOfflineModalComponent,
    RegistrationCodeStepComponent,
    SnaQueryParamCheckerComponent,
    SnaDisabledPageComponent,
    SnaHttpStatusErrorsComponent,
    SnaReCaptchaComponent,
    PaymentTypeSelectorComponent,
    SnaConsultantSubmitComponent,
  ],
  imports: [
    CommonModule,
    StartNowAppRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    PaymentModule,
  ],
  exports: [],
  providers: [
    SafeResourceUrlPipe,
    // TODO EC-3876
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializerFactory,
    //   deps: [AppInitService],
    //   multi: true,
    // },
  ],
})
export class StartNowAppModule {}

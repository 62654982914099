import { MsalBroadcastService } from '@azure/msal-angular';
import { EventType } from '@azure/msal-browser';
import { AuthErrorCodes } from '@core/enums/auth-error-codes.enum';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export function getB2CError(msalBroadcastService: MsalBroadcastService): Observable<string> {
  return msalBroadcastService.msalSubject$.pipe(
    filter((event) => event.eventType === EventType.LOGIN_FAILURE),
    map((event) => {
      const isUnregisteredPhoneNumber = event.error.message.includes(
        AuthErrorCodes.B2CUserDoesNotExistError,
      );
      return isUnregisteredPhoneNumber
        ? $localize`This phone number is not registered`
        : $localize`Login failure`;
    }),
  );
}

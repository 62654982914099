import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductRoutes } from '@core/constants/routes.const';
import { Thumbnail } from '@core/enums/thumbnail.enum';
import { OrderLine } from '@core/store/cart/cart-state-models';

@Component({
  selector: 'app-order-line',
  templateUrl: './order-line.component.html',
  styleUrls: ['./order-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderLineComponent {
  readonly productRoute = ProductRoutes.Product;
  readonly pRoute = ProductRoutes.P;

  @Input() readOnly: boolean;
  @Input() orderLine: OrderLine;
  @Input() updateQty: (orderLine: OrderLine, option: any) => void;
  @Input() remove: (orderLineId: number) => void;
  @Input() productClickable: boolean = false;

  thumbnail = Thumbnail;

  constructor() {}

  counter(number: number): any[] {
    return new Array(number);
  }
}

<div class="root">
  <app-header-container>
    <app-header-top></app-header-top>
    <app-header-content
      [menuCollapsed]="menuCollapsed"
      (toggleMenu)="toggleMenu()"
    ></app-header-content>
    <app-header-navigation
      [menuCollapsed]="menuCollapsed"
      (toggleMenu)="toggleMenu()"
    ></app-header-navigation>
    <app-header-consultant></app-header-consultant>
    <app-header-offers></app-header-offers>
  </app-header-container>

  <div id="mainContent">
    <router-outlet></router-outlet>
  </div>

  <app-footer-container>
    <app-footer-top [shade]="shade.Shade2"></app-footer-top>
    <app-footer-links [shade]="shade.Shade3" [links]="primaryLinks$"></app-footer-links>
    <app-footer-links
      [shade]="shade.Shade4"
      [links]="secondaryLinks$"
      [isSecondary]="true"
    ></app-footer-links>
    <app-footer-bottom [shade]="shade.Shade5"></app-footer-bottom>
  </app-footer-container>
</div>

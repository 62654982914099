import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { findConsultantResultPageSize } from '@core/constants/find-consultant';
import { CartRoutes } from '@core/constants/routes.const';
import { ConsultantFinderResponse, SnaConsultantResponse } from '@core/dto/consultant.dto';
import { LocalStorageKey } from '@core/enums/local-storage-key.enum';
import { RouterQueryParams } from '@core/enums/router-query-param.enum';
import { AppInitService } from '@core/services/app-init.service';
import { ConsultantService } from '@core/services/consultant.service';
import { LocalStorageService } from '@core/services/local-storage.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, tap } from 'rxjs/operators';
import { selectAssignedConsultant, selectConsultantFinder, selectCurrentConsultant } from '.';
import { AppState } from '..';
import { selectIsAuthenticated } from '../auth';
import { findConsultantSuccess } from '../start-now-app/start-now-app.actions';
import { selectUser } from '../user';
import { Consultant } from './consultant-state-models';
import * as consultantActions from './consultant.actions';

const REMOVE_CID_QUERY_PARAM_TIMEOUT = 500;

@Injectable()
export class ConsultantEffects {
  fetchCurrentConsultantByVanityName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consultantActions.fetchCurrentConsultantByVanityName),
      mergeMap(({ vanityName: newVanityName, filterCountry }) => {
        const vanityName =
          newVanityName ||
          (this.localStorageService.getItem(LocalStorageKey.consultantVanityName) as string);
        if (vanityName) {
          return this.consultantService.fetchConsultantByVanityName(vanityName, filterCountry).pipe(
            map((consultant) =>
              consultantActions.fetchCurrentConsultantSuccess({
                consultant,
                isPartyEnabled: this.appInitService.Settings.ec.isPartyEnabled,
              }),
            ),
            catchError(({ error }) =>
              of(
                consultantActions.fetchCurrentConsultantFailure({
                  error,
                  searchedVanityName: vanityName,
                }),
              ),
            ),
          );
        } else {
          return of(consultantActions.resetCurrentConsultant(true));
        }
      }),
    ),
  );

  fetchCurrentConsultantByLoginName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consultantActions.fetchCurrentConsultantByLoginName),
      mergeMap(({ loginName: newLoginName, filterCountry }) => {
        if (newLoginName) {
          return this.consultantService
            .fetchConsultantByLoginName(newLoginName, filterCountry)
            .pipe(
              map((consultant) =>
                consultantActions.fetchCurrentConsultantSuccess({
                  consultant: this.mapSnaConsultantResult(consultant),
                  isPartyEnabled: this.appInitService.Settings.ec.isPartyEnabled,
                }),
              ),
              catchError(({ error }) =>
                of(
                  consultantActions.fetchCurrentConsultantFailure({
                    error,
                    searchedVanityName: newLoginName,
                  }),
                ),
              ),
            );
        } else {
          return of(consultantActions.resetCurrentConsultant(true));
        }
      }),
    ),
  );

  fetchCurrentConsultantSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(consultantActions.fetchCurrentConsultantSuccess),
        tap(({ consultant, isPartyEnabled }) => {
          this.localStorageService.setItem(
            LocalStorageKey.consultantVanityName,
            consultant.vanityName,
          );

          if (isPartyEnabled) {
            this.store$.dispatch(consultantActions.fetchConsultantParties());
          }
        }),
      ),
    { dispatch: false },
  );

  fetchCurrentConsultantFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consultantActions.fetchCurrentConsultantFailure),
      concatLatestFrom(() => this.store$.select(selectIsAuthenticated)),
      map(([payload, isAuthenticated]) => {
        const vanityNameFromLocalStorage = this.localStorageService.getItem(
          LocalStorageKey.consultantVanityName,
        ) as string;
        if (
          vanityNameFromLocalStorage.localeCompare(payload.searchedVanityName, undefined, {
            sensitivity: 'accent',
          }) !== 0 &&
          !isAuthenticated
        ) {
          return consultantActions.fetchCurrentConsultantByVanityName({
            vanityName: vanityNameFromLocalStorage,
            filterCountry: true,
          });
        }
        return consultantActions.resetCurrentConsultant();
      }),
    ),
  );

  resetCurrentConsultant$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(consultantActions.resetCurrentConsultant),
        tap(() => {
          setTimeout(() => {
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.delete(RouterQueryParams.cid);
            this.location.replaceState(this.router.url.split('?')[0], urlParams.toString());
          }, REMOVE_CID_QUERY_PARAM_TIMEOUT);
          this.localStorageService.removeItem(LocalStorageKey.consultantVanityName);
        }),
      ),
    { dispatch: false },
  );

  fetchAssignedConsultant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consultantActions.fetchAssignedConsultant),
      concatLatestFrom(() => this.store$.select(selectUser)),
      mergeMap(([, user]) =>
        this.consultantService.fetchConsultantById(user.activeConsultantProwessId, false).pipe(
          map((res) => consultantActions.fetchAssignedConsultantSuccess({ consultant: res })),
          catchError(() => of(consultantActions.fetchAssignedConsultantFailure())),
        ),
      ),
    ),
  );

  fetchAssignedConsultantFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(consultantActions.fetchAssignedConsultantFailure),
        tap(() => {
          if (!window.location.href.includes(`/${CartRoutes.Checkout}`)) {
            this.toastr.error(
              $localize`Failed to fetch from server`,
              $localize`Assigned consultant`,
            );
          }
        }),
      ),
    { dispatch: false },
  );

  fetchAssignedConsultantOnCheckout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consultantActions.fetchAssignedConsultantOnCheckout),
      concatLatestFrom(() => this.store$.select(selectAssignedConsultant)),
      mergeMap(([, assignedConsultant]) =>
        this.consultantService
          .fetchConsultantByVanityName(assignedConsultant.vanityName, true)
          .pipe(
            map((res) =>
              consultantActions.fetchAssignedConsultantOnCheckoutSuccess({
                consultant: res,
              }),
            ),
            catchError(() => of(consultantActions.fetchAssignedConsultantOnCheckoutFailure())),
          ),
      ),
    ),
  );

  /**
   * Find consultant
   */
  findConsultant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consultantActions.findConsultant),
      concatLatestFrom(() => this.store$.select(selectConsultantFinder)),
      mergeMap(
        ([
          {
            payload: { pageNumber, ...rest },
          },
          consultantFinder,
        ]) =>
          this.consultantService
            .findConsultant({
              ...rest,
              ...(pageNumber === 1
                ? { pageNumber }
                : {
                    pageNumber:
                      Math.ceil(consultantFinder.results.length / findConsultantResultPageSize) + 1,
                  }),
            })
            .pipe(
              map((res) =>
                consultantActions.findConsultantSuccess(this.mapConsultantFinderResults(res)),
              ),
              catchError(() => of(consultantActions.findConsultantFailure())),
            ),
      ),
    ),
  );

  findConsultantSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consultantActions.fetchCurrentConsultantSuccess),
      map(({ consultant }) => findConsultantSuccess({ hasMore: false, items: [consultant] })),
    ),
  );

  locateConsultant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consultantActions.locateConsultant),
      concatLatestFrom(() => this.store$.select(selectConsultantFinder)),
      exhaustMap(
        ([
          {
            payload: { pageNumber, ...rest },
          },
        ]) =>
          this.consultantService
            .locateConsultant({
              ...rest,
              pageNumber,
            })
            .pipe(
              map((res) =>
                consultantActions.locateConsultantSuccess(this.mapConsultantFinderResults(res)),
              ),
              catchError(({ error }) => of(consultantActions.locateConsultantFailure({ error }))),
            ),
      ),
    ),
  );

  consultantFinderFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(consultantActions.findConsultantFailure, consultantActions.locateConsultantFailure),
        tap(() =>
          this.toastr.error(
            $localize`Failed to fetch result from server`,
            $localize`Consultant finder`,
          ),
        ),
      ),
    { dispatch: false },
  );

  fetchConsultantParties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consultantActions.fetchConsultantParties),
      concatLatestFrom(() => this.store$.select(selectCurrentConsultant)),
      mergeMap(([, currentConsultant]) =>
        this.consultantService.fetchConsultantParties(currentConsultant.beeNumber).pipe(
          map(({ parties }) => {
            const selectedParty =
              parties.find(
                (party) =>
                  party.partyId.toString() ===
                  this.localStorageService.getItem(RouterQueryParams.partyId)?.toString(),
              ) || null;

            return consultantActions.fetchConsultantPartiesSuccess({ parties, selectedParty });
          }),
          catchError(() => of(consultantActions.fetchConsultantPartiesFailure())),
        ),
      ),
    ),
  );

  fetchConsultantPartiesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(consultantActions.fetchConsultantPartiesSuccess),
      filter((party) => party.selectedParty === null),
      map(() => consultantActions.resetPartyAndIsOrderCreditToHostParty()),
    ),
  );

  fetchConsultantPartiesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(consultantActions.fetchConsultantPartiesFailure),
        tap(() =>
          this.toastr.error(
            $localize`Failed to fetch consultant parties`,
            $localize`Consultant parties`,
          ),
        ),
      ),
    { dispatch: false },
  );

  selectParty$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(consultantActions.selectParty),
        filter(({ party }) => !!party),
        tap(({ party }) => {
          this.localStorageService.setItem(RouterQueryParams.partyId, party.partyId.toString());
        }),
      ),
    { dispatch: false },
  );

  resetParty$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(consultantActions.resetParty),
        tap(() => this.localStorageService.removeItem(RouterQueryParams.partyId)),
      ),
    { dispatch: false },
  );

  private mapConsultantFinderResults(res: ConsultantFinderResponse): {
    hasMore: boolean;
    results: Consultant[];
  } {
    return {
      hasMore: res.hasMore,
      results: res.items.map((item) => ({
        id: item.id,
        beeNumber: item.beeNumber,
        displayName: item.displayName,
        fullName: item.fullName,
        city: item.city,
        state: item.state,
        email: item.email,
        phoneNumber: item.phoneNumber,
        vanityName: item.vanityName,
        profileImage: item.profileImage,
        story: null,
        financialState: item.financialState,
      })),
    };
  }

  private mapSnaConsultantResult(res: SnaConsultantResponse): Consultant {
    return {
      id: res.id,
      beeNumber: res.beeNumber,
      displayName: res.displayName,
      fullName: res.fullName,
      city: res.city,
      state: res.state,
      email: res.email,
      phoneNumber: res.phoneNumber,
      vanityName: res.vanityName,
      profileImage: res.profileImage,
      story: null,
      financialState: res.financialState,
    };
  }

  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private consultantService: ConsultantService,
    private toastr: ToastrService,
    private store$: Store<AppState>,
    private appInitService: AppInitService,
    private router: Router,
    private location: Location,
  ) {}
}

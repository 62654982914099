import { Component, OnInit } from '@angular/core';
import { DefaultConsultantImagePlaceholderSrc } from '@core/constants/image-placeholders';
import { BaseRoutes } from '@core/constants/routes.const';
import { AppState } from '@core/store';
import { selectCurrentConsultant } from '@core/store/consultant';
import { Consultant } from '@core/store/consultant/consultant-state-models';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-consultant-widget',
  templateUrl: './consultant-widget.component.html',
  styleUrls: ['./consultant-widget.component.scss'],
})
export class ConsultantWidgetComponent implements OnInit {
  readonly Routes = {
    CustomerSpecialOffers: BaseRoutes.CustomerSpecialOffers,
  };

  consultant$: Observable<Consultant>;
  imagePlaceholder = DefaultConsultantImagePlaceholderSrc;
  feature = environment.feature;

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    this.consultant$ = this.store$.select(selectCurrentConsultant);
  }
}

<hr class="d-md-none my-0" />
<div class="d-flex justify-content-between">
  <h5 class="font-weight-bold title my-3 my-md-2">{{ cardTitle }}</h5>
  <div *ngIf="isChangeable" class="my-md-0 my-2">
    <a
      class="d-md-block d-none text-uppercase font-weight-bold my-2 pointer"
      (click)="navigate()"
      i18n
    >
      Change
    </a>
    <a class="d-md-none title" (click)="navigate()">
      <i class="bi bi-pencil-square"></i>
    </a>
  </div>
</div>
<hr class="d-md-none mt-0" />

<app-modal #pfasModal [modalConfig]="modalConfig">
  <div class="text-center">
    <div class="text-center">
      <div class="title-desc mb-3" i18n>
        Unable to ship select product(s) in your cart to a Minnesota address
      </div>
      <div class="col-md-12 mt-5 pfas-law-desc">
        <span i18n> Due to the Minnesota</span>
        <a i18n aria-label="Minnesota" target="_blank"
          href="https://www.pca.state.mn.us/air-water-land-climate/2025-pfas-prohibitions"
          class="link-highlight">&nbsp;2025 PFAS prohibition</a><span i18n>&nbsp;,the following product(s) need to be
          removed from your cart before the order can be submitted.</span>
      </div>
      <div *ngIf="pfasCartItems" class="text-align: center">
        <div class="ph-row" *ngFor="let cartItem of pfasCartItems">
          <div class="col-xs-2 ml-5" i18n>{{ cartItem.sku | titlecase }}</div>
          <div class="product-desc col-md-9 " i18n>{{ cartItem.productName }}</div>
        </div>
      </div>
    </div>
  </div>
</app-modal>
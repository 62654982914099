import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProductRoutes } from '@core/constants/routes.const';
import { ProductErrorType } from '@core/enums/product-error-type.enum';
import { CatalogService } from '@core/services/catalog.service';
import { GtmService } from '@core/services/gtm.service';
import { ProductService } from '@core/services/product.service';
import { RecipeService } from '@core/services/recipe.service';
import { VimeoService } from '@core/services/vimeo.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getErrorMessage } from '@shared/utils/get-error-message';
import { getErrorTitle } from '@shared/utils/get-error-title';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AppState } from '..';
import * as productActions from './product.actions';

@Injectable()
export class ProductEffects {
  fetchProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.fetchProducts),
      exhaustMap((payload) =>
        this.productService.fetchProducts(payload).pipe(
          map((res) =>
            productActions.fetchProductsSuccess({
              payload: res.products,
              catalogName: res.catalogName,
              pageSize: payload.pageSize,
            }),
          ),
          catchError((error) => of(productActions.fetchProductsFailure({ error }))),
        ),
      ),
    ),
  );

  fetchProductsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productActions.fetchProductsFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  fetchProductDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.fetchProductDetails),
      mergeMap(({ sku, date }) =>
        this.productService.fetchProductDetails(sku, date).pipe(
          map((product) =>
            productActions.fetchProductDetailsSuccess({
              product: {
                ...product,
                videos: product.vimeoIds.map((i) => ({
                  id: i,
                })),
              },
            }),
          ),
          catchError((error) =>
            of(productActions.fetchProductDetailsFailure({ error: error.error })),
          ),
        ),
      ),
    ),
  );

  fetchProductDetailsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productActions.fetchProductDetailsSuccess),
        tap(({ product }) => {
          this.gtmService.productDetailsView(product);
          product.videos.forEach((video) => {
            this.store$.dispatch(productActions.fetchProductVideoDetails({ vimeoId: video.id }));
          });
        }),
      ),
    { dispatch: false },
  );

  fetchProductDetailsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productActions.fetchProductDetailsFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
          if (error.ErrorType == ProductErrorType.ProductNotFoundException) {
            this.router.navigateByUrl(`${ProductRoutes.Product}/${ProductRoutes.NotFound}`);
          }
        }),
      ),
    { dispatch: false },
  );

  fetchCatalogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.fetchCatalogs),
      exhaustMap(({ catalogType, pageNumber, pageSize, shouldResetStore }) =>
        this.catalogService.fetchCatalogs(catalogType, pageNumber, pageSize).pipe(
          switchMap((res) => {
            const actions = [];
            if (shouldResetStore) {
              actions.push(productActions.resetCatalogs());
            }
            actions.push(productActions.fetchCatalogsSuccess({ payload: res.catalogs, pageSize }));
            return actions;
          }),
          catchError((error) => of(productActions.fetchCatalogsFailure({ error }))),
        ),
      ),
    ),
  );

  fetchCatalogsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productActions.fetchCatalogsFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  fetchProductQuickView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.fetchProductQuickView),
      mergeMap(({ sku }) =>
        this.productService.fetchProductQuickView(sku).pipe(
          map((product) =>
            productActions.fetchProductQuickViewSuccess({
              product: { sku, ...product, videos: product.vimeoIds?.map((id) => ({ id })) },
            }),
          ),
          catchError(({ error }) => of(productActions.fetchProductQuickViewFailure(error))),
        ),
      ),
    ),
  );

  fetchProductQuickViewSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productActions.fetchProductQuickViewSuccess),
        tap(({ product }) => {
          product.videos?.forEach((video) => {
            this.store$.dispatch(
              productActions.fetchProductQuickViewVideoDetails({ vimeoId: video.id }),
            );
          });
        }),
      ),
    { dispatch: false },
  );

  fetchProductQuickViewFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productActions.fetchProductQuickViewFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  /**
   * Product recommendations
   */
  fetchProductRecommendations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.fetchProductRecommendations),
      mergeMap(({ sku, date }) =>
        this.productService.fetchProductRecommendations(sku, date).pipe(
          map((res) =>
            productActions.fetchProductRecommendationsSuccess({
              recommendations: res.recommendations,
            }),
          ),
          catchError(() => of(productActions.fetchProductRecommendationsFailure())),
        ),
      ),
    ),
  );

  fetchProductRecommendationsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productActions.fetchProductRecommendationsFailure),
        tap(() => {
          this.toastr.error(
            $localize`Failed to fetch product recommendations`,
            $localize`Product recommendations error`,
          );
        }),
      ),
    { dispatch: false },
  );

  /**
   * Related recipes
   */
  fetchRelatedRecipes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.fetchRelatedRecipes),
      mergeMap(({ sku, date }) =>
        this.recipeService.fetchRelatedRecipes(sku, date).pipe(
          map((res) =>
            productActions.fetchRelatedRecipesSuccess({
              recipes: res.recipes,
            }),
          ),
          catchError(() => of(productActions.fetchRelatedRecipesFailure())),
        ),
      ),
    ),
  );

  fetchRelatedRecipesFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productActions.fetchRelatedRecipesFailure),
        tap(() => {
          this.toastr.error(
            $localize`Failed to fetch related recipes`,
            $localize`Related recipes error`,
          );
        }),
      ),
    { dispatch: false },
  );

  /**
   * More product from collection
   */
  fetchMoreFromCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.fetchMoreFromCollection),
      mergeMap(({ catalogUrlName, sku, date }) =>
        this.productService.fetchMoreProductFromCollection(catalogUrlName, date).pipe(
          map((res) =>
            productActions.fetchMoreFromCollectionSuccess({
              moreFromCollection: res.recommendations.filter((r) => r.sku !== sku),
            }),
          ),
          catchError(() => of(productActions.fetchMoreFromCollectionFailure())),
        ),
      ),
    ),
  );

  fetchMoreFromCollectionFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productActions.fetchMoreFromCollectionFailure),
        tap(() => {
          this.toastr.error(
            $localize`Failed to fetch more product from this collection`,
            $localize`More from this collection error`,
          );
        }),
      ),
    { dispatch: false },
  );

  fetchEcommerceCustomerSpecialOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.fetchEcommerceCustomerSpecialOffers),
      mergeMap(({ viewAs }) =>
        this.productService.fetchEcommerceCustomerSpecialOffers(viewAs).pipe(
          map((res) =>
            productActions.fetchEcommerceCustomerSpecialOffersSuccess({
              payload: res.products,
            }),
          ),
          catchError((error) =>
            of(productActions.fetchEcommerceCustomerSpecialOffersFailure({ error })),
          ),
        ),
      ),
    ),
  );

  fetchEcommerceCustomerSpecialOffersFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productActions.fetchEcommerceCustomerSpecialOffersFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  fetchHealthySavingsCustomerSpecialOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.fetchHealthySavingsCustomerSpecialOffers),
      mergeMap(({ viewAs }) =>
        this.productService.fetchHealthySavingsCustomerSpecialOffers(viewAs).pipe(
          map((res) =>
            productActions.fetchHealthySavingsCustomerSpecialOffersSuccess({
              payload: res.products,
            }),
          ),
          catchError((error) =>
            of(productActions.fetchHealthySavingsCustomerSpecialOffersFailure({ error })),
          ),
        ),
      ),
    ),
  );

  fetchHealthySavingsCustomerSpecialOffersFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productActions.fetchHealthySavingsCustomerSpecialOffersFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  fetchCollectAndSaveCustomerSpecialOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.fetchCollectAndSaveCustomerSpecialOffers),
      mergeMap(({ viewAs }) =>
        this.productService.fetchCollectAndSaveCustomerSpecialOffers(viewAs).pipe(
          map((res) =>
            productActions.fetchCollectAndSaveCustomerSpecialOffersSuccess({
              payload: res.products,
            }),
          ),
          catchError((error) =>
            of(productActions.fetchCollectAndSaveCustomerSpecialOffersFailure({ error })),
          ),
        ),
      ),
    ),
  );

  fetchCollectAndSaveCustomerSpecialOffersFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productActions.fetchCollectAndSaveCustomerSpecialOffersFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  fetchRegularCustomerSpecialOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.fetchRegularCustomerSpecialOffers),
      mergeMap(({ viewAs }) =>
        this.productService.fetchRegularCustomerSpecialOffers(viewAs).pipe(
          map((res) =>
            productActions.fetchRegularCustomerSpecialOffersSuccess({
              payload: res.products,
            }),
          ),
          catchError((error) =>
            of(productActions.fetchRegularCustomerSpecialOffersFailure({ error })),
          ),
        ),
      ),
    ),
  );

  fetchRegularCustomerSpecialOffersFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(productActions.fetchRegularCustomerSpecialOffersFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  fetchProductVideoDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.fetchProductVideoDetails),
      mergeMap(({ vimeoId }) =>
        this.vimeoService.fetchVimeoThumbnail(vimeoId).pipe(
          map((thumbnail) =>
            productActions.fetchProductVideoDetailsSuccess({ vimeoId, thumbnail }),
          ),
          catchError(() => of(productActions.fetchProductVideoDetailsFailure())),
        ),
      ),
    ),
  );

  fetchProductQuickViewVideoDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.fetchProductQuickViewVideoDetails),
      mergeMap(({ vimeoId }) =>
        this.vimeoService.fetchVimeoThumbnail(vimeoId).pipe(
          map((thumbnail) =>
            productActions.fetchProductQuickViewVideoDetailsSuccess({ vimeoId, thumbnail }),
          ),
          catchError(() => of(productActions.fetchProductQuickViewVideoDetailsFailure())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private productService: ProductService,
    private catalogService: CatalogService,
    private recipeService: RecipeService,
    private toastr: ToastrService,
    private gtmService: GtmService,
    private router: Router,
    private vimeoService: VimeoService,
    private store$: Store<AppState>,
  ) {}
}

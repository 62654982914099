<div class="mt-5" *ngIf="errorMessage$ | async as errorMessage; else spinner">
  <div class="text-center validation-error-message">{{ errorMessage }}</div>
  <div class="d-flex justify-content-center mt-4">
    <button class="btn btn-primary w-50" (click)="resetError()" i18n>Try again</button>
  </div>
</div>

<ng-template #spinner>
  <div class="root">
    <app-ph-spinner></app-ph-spinner>
  </div>
</ng-template>

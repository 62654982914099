import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from '@base/components/layout/default-layout/default-layout.component';
import { CartPageComponent } from '@cart/components/cart-page/cart-page.component';
import { CheckoutPageComponent } from '@cart/components/checkout-page/checkout-page.component';
import { CartRoutes } from '@core/constants/routes.const';
import { CartFeatureGuardService } from '@core/services/cart-feature-guard.service';
import { EmptyCartGuardService } from '@core/services/empty-cart-guard.service';

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [CartFeatureGuardService],
    children: [
      {
        path: CartRoutes.ShoppingCart,
        component: CartPageComponent,
        canActivate: [EmptyCartGuardService],
        data: { title: $localize`Shopping Cart` },
      },
      {
        path: CartRoutes.Checkout,
        component: CheckoutPageComponent,
        canActivate: [EmptyCartGuardService],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CartRoutingModule {}

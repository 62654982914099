import { Injectable } from '@angular/core';
import { GenericErrorMessage } from '@core/constants/sna-error-messages';
import { ToastrConfigCenterBiggerPermanent } from '@core/constants/toaster.config';
import {
  AddressVerificationRequest,
  ConsultantDto,
  FindConsultantByAreaRequest,
  FindConsultantByZipCodeResponse,
  FindConsultantResponse,
  OrderSummaryRequest,
} from '@core/dto/start-now-app.dto';
import { AddressType } from '@core/enums/address-type.enum';
import { ConsultantSubmissionErrorType } from '@core/enums/consultant-submission-error-type.enum';
import { LoggerService } from '@core/services/logger.service';
import { StartNowAppService } from '@core/services/start-now-app.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { mapContactInfoToSubmitContactInfoDto } from '@shared/utils/create-submit-consultant-info-utils';
import { isMexEnv } from '@shared/utils/environment-utils';
import {
  getCheckCanSubmitSnaErrorMessage,
  getCheckCanSubmitSnaErrorTitle,
  getSnaErrorMessage,
  getSnaErrorTitle,
  getSnaSubmitErrorMessage,
  getSnaSubmitErrorTitle,
} from '@shared/utils/sna-error-message-utils';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  selectAddresses,
  selectApplicationRegistrationId,
  selectConsultantFinderCriteria,
  selectSecurityKey,
  selectSelectedStarterKit,
} from '.';
import { AppState } from '..';
import { Consultant } from '../consultant/consultant-state-models';
import {
  Address,
  ConsultantFinderCriteria,
  FoundConsultantsByZipCode,
} from './start-now-app-state-models';
import * as startNowAppActions from './start-now-app.actions';

@Injectable()
export class StartNowAppEffects {
  validateReCaptcha$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.validateReCaptcha),
      mergeMap(({ token }) =>
        this.startNowAppService.validateReCaptcha(token).pipe(
          map((res) => startNowAppActions.validateReCaptchaSuccess({ isValid: res.isSuccess })),
          catchError(() => of(startNowAppActions.validateReCaptchaFailure())),
        ),
      ),
    ),
  );

  findConsultantByArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.findConsultantByArea),
      mergeMap(({ consultantFinder }) =>
        this.startNowAppService.findConsultant(consultantFinder).pipe(
          map((res) => {
            if (res.items?.length > 0) {
              return startNowAppActions.findConsultantSuccess(this.mapConsultantFinderResults(res));
            } else {
              return startNowAppActions.findConsultantFailure();
            }
          }),
          catchError(() => of(startNowAppActions.findConsultantFailure())),
        ),
      ),
    ),
  );

  findConsultantByAreaNextPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.findConsultantByAreaNextPage),
      concatLatestFrom(() => [this.store$.select(selectConsultantFinderCriteria)]),
      mergeMap(([pageData, otherCriteria]) =>
        this.startNowAppService
          .findConsultant(this.mapConsultantFinderCriteria(pageData, otherCriteria))
          .pipe(
            map((res) =>
              startNowAppActions.findConsultantSuccess(this.mapConsultantFinderResults(res)),
            ),
            catchError(() => of(startNowAppActions.findConsultantFailure())),
          ),
      ),
    ),
  );

  findConsultantByZipCodeNextPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.findConsultantByZipCodeNextPage),
      concatLatestFrom(() => [
        this.store$
          .select(selectConsultantFinderCriteria)
          .pipe(select((consultantFinderCriteria) => consultantFinderCriteria.zipCode)),
      ]),
      mergeMap(([pageData, zipCode]) =>
        this.startNowAppService
          .findConsultantByZipCode({
            zipCode: zipCode,
            pageNumber: pageData.pageNumber,
            pageSize: pageData.pageSize,
          })
          .pipe(
            map((res) =>
              startNowAppActions.findConsultantByZipCodeSuccess({
                res: this.mapFindConsultantByZipCodeResponse(res),
              }),
            ),
            catchError(({ error }) =>
              of(startNowAppActions.findConsultantByZipCodeFailure({ error })),
            ),
          ),
      ),
    ),
  );

  findConsultantByConsultantCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.findConsultantByConsultantCode),
      mergeMap(({ request }) =>
        this.startNowAppService.findConsultantByConsultantCode(request).pipe(
          map((res) =>
            startNowAppActions.findConsultantSuccess({
              hasMore: false,
              items: [this.mapConsultantItemResponseToConsultant(res)],
            }),
          ),
          catchError(() => of(startNowAppActions.findConsultantFailure())),
        ),
      ),
    ),
  );

  findConsultantByZipCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.findConsultantByZipCode),
      mergeMap(({ request }) =>
        this.startNowAppService.findConsultantByZipCode(request).pipe(
          map((res) =>
            startNowAppActions.findConsultantByZipCodeSuccess({
              res: this.mapFindConsultantByZipCodeResponse(res),
            }),
          ),
          catchError(({ error }) =>
            of(startNowAppActions.findConsultantByZipCodeFailure({ error })),
          ),
        ),
      ),
    ),
  );

  findConsultantByZipCodeFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.findConsultantByZipCodeFailure),
        tap(() => {
          const title: string = $localize`Consultant fetch`;
          let message: string;
          if (isMexEnv) {
            message = $localize`Default consultant fetch failed`;
          } else {
            message = $localize`Consultant failed`;
          }
          this.toastr.error(message, title);
          this.loggerService.error(message);
        }),
      ),
    { dispatch: false },
  );

  validateRegistrationCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.validateRegistrationCode),
      mergeMap(({ validateRegistrationCode: registrationCode }) =>
        this.startNowAppService.validateRegistrationCode(registrationCode).pipe(
          map((res) => startNowAppActions.validateRegistrationCodeSuccess({ res })),
          catchError(() => of(startNowAppActions.validateRegistrationCodeFailure())),
        ),
      ),
    ),
  );

  validateRegistrationCodeFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.validateRegistrationCodeFailure),
        tap(() => {
          this.toastr.error(
            $localize`Registration code validity check`,
            $localize`Registration code validity check failed`,
          );
        }),
      ),
    { dispatch: false },
  );

  updateStartNowAppUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.updateStartNowAppUserInfo),
      concatLatestFrom(() => [
        this.store$.select(selectApplicationRegistrationId),
        this.store$.select(selectSecurityKey),
      ]),
      mergeMap(([{ userInfo }, applicationRegistrationId, securityKey]) =>
        this.startNowAppService.saveUserInfo(userInfo, applicationRegistrationId, securityKey).pipe(
          map((res) =>
            startNowAppActions.updateStartNowAppUserInfoSuccess({
              saveUserInfoResult: {
                applicationRegistrationId: res.applicationRegistrationId,
                isSuccess: res.isSuccess,
                errors: res.errorTypes,
                securityKey: res.securityKey,
              },
            }),
          ),
          catchError((error) => of(startNowAppActions.updateStartNowAppUserInfoFailure(error))),
        ),
      ),
    ),
  );

  updateStartNowAppUserInfoFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.updateStartNowAppUserInfoFailure),
        tap((error) => {
          this.toastr.error(
            getSnaErrorMessage(error?.error?.status),
            getSnaErrorTitle(error?.error?.status),
          );
          this.loggerService.error('Update user info failed: ', error);
        }),
      ),
    { dispatch: false },
  );

  updateStartNowAppContactInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.updateStartNowAppContactInfo),
      concatLatestFrom(() => [
        this.store$.select(selectApplicationRegistrationId),
        this.store$.select(selectSecurityKey),
      ]),
      mergeMap(([{ contactInfo }, applicationRegistrationId, securityKey]) =>
        this.startNowAppService
          .saveContactInfo({
            ...mapContactInfoToSubmitContactInfoDto(contactInfo),
            applicationRegistrationId,
            securityKey,
          })
          .pipe(
            map((res) =>
              startNowAppActions.updateStartNowAppContactInfoSuccess({
                saveConsultantDataResult: {
                  isSuccess: res.isSuccess,
                  errors: res.errorTypes,
                },
              }),
            ),
            catchError((error) =>
              of(startNowAppActions.updateStartNowAppContactInfoFailure(error)),
            ),
          ),
      ),
    ),
  );

  updateStartNowAppContactInfoFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.updateStartNowAppContactInfoFailure),
        tap((error) => {
          this.toastr.error(
            getSnaErrorMessage(error?.error?.status),
            getSnaErrorTitle(error?.error?.status),
          );
          this.loggerService.error('Update contact info failed: ', error);
        }),
      ),
    { dispatch: false },
  );

  updateStartNowAppAddressInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.updateStartNowAppAddressInfo),
      concatLatestFrom(() => [
        this.store$.select(selectApplicationRegistrationId),
        this.store$.select(selectSecurityKey),
      ]),
      mergeMap(([{ addresses, languagePreferred }, applicationRegistrationId, securityKey]) =>
        this.startNowAppService
          .saveAddressInfo({
            addresses,
            applicationRegistrationId,
            securityKey,
            languagePreferred,
          })
          .pipe(
            map((res) =>
              startNowAppActions.updateStartNowAppAddressInfoSuccess({
                saveConsultantDataResult: {
                  isSuccess: res.isSuccess,
                  errors: res.errorTypes,
                },
              }),
            ),
            catchError((error) =>
              of(startNowAppActions.updateStartNowAppAddressInfoFailure(error)),
            ),
          ),
      ),
    ),
  );

  updateStartNowAppAddressInfoFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.updateStartNowAppAddressInfoFailure),
        tap((error) => {
          this.toastr.error(
            getSnaErrorMessage(error?.error?.status),
            getSnaErrorTitle(error?.error?.status),
          );
          this.loggerService.error('Update address info failed: ', error);
        }),
      ),
    { dispatch: false },
  );

  validateAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.validateAddress),
      mergeMap(({ address }) =>
        this.startNowAppService.verifyAddress(address).pipe(
          map((res) => startNowAppActions.validateAddressSuccess({ addressValidation: res })),
          catchError((error) => of(startNowAppActions.validateAddressFailure(error))),
        ),
      ),
    ),
  );

  updateStartNowAppSelectedStarterKit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.updateStartNowAppSelectedStarterKit),
      concatLatestFrom(() => [
        this.store$.select(selectApplicationRegistrationId),
        this.store$.select(selectSecurityKey),
      ]),
      mergeMap(
        ([
          { selectedStarterKit, isDepositPayment, isReducedDepositPayment, isZeroDepositPayment },
          applicationRegistrationId,
          securityKey,
        ]) =>
          this.startNowAppService
            .saveStarterKit({
              starterKitId: selectedStarterKit.id,
              isDepositPayment,
              isReducedDepositPayment,
              isZeroDepositPayment,
              applicationRegistrationId,
              securityKey,
            })
            .pipe(
              map((res) =>
                startNowAppActions.updateStartNowAppSelectedStarterKitSuccess({
                  saveConsultantDataResult: {
                    isSuccess: res.isSuccess,
                    errors: res.errorTypes,
                  },
                }),
              ),
              catchError((error) =>
                of(startNowAppActions.updateStartNowAppSelectedStarterKitFailure(error)),
              ),
            ),
      ),
    ),
  );

  updateStartNowAppSelectedStarterKitFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.updateStartNowAppSelectedStarterKitFailure),
        tap((error) => {
          this.toastr.error(
            getSnaErrorMessage(error?.error?.status),
            getSnaErrorTitle(error?.error?.status),
          );
          this.loggerService.error('Update selected starter kit failed ', error);
        }),
      ),
    { dispatch: false },
  );

  fetchCountryStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.fetchCountryStates),
      mergeMap(() =>
        this.startNowAppService.fetchStates().pipe(
          map((res) => startNowAppActions.fetchCountryStatesSuccess({ countryStates: res })),
          catchError((error) => of(startNowAppActions.fetchCountryStatesFailure(error))),
        ),
      ),
    ),
  );

  emailUniquenessCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.emailUniquenessCheck),
      mergeMap(({ email }) =>
        this.startNowAppService.emailUniquenessCheck(email).pipe(
          map(({ emailIsValid }) =>
            startNowAppActions.emailUniquenessCheckSuccess({ emailIsValid }),
          ),
          catchError((error) => of(startNowAppActions.emailUniquenessCheckFailure(error))),
        ),
      ),
    ),
  );

  phoneNumberUniquenessCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.phoneNumberUniquenessCheck),
      mergeMap(({ request }) =>
        this.startNowAppService.checkPhoneNumberUniqueness(request).pipe(
          map((response) => startNowAppActions.phoneNumberUniquenessCheckSuccess({ response })),
          catchError((error) => of(startNowAppActions.phoneNumberUniquenessCheckFailure(error))),
        ),
      ),
    ),
  );

  phoneNumberUniquenessCheckFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.phoneNumberUniquenessCheckFailure),
        tap(() => {
          this.toastr.error(
            $localize`Phone number uniqueness check`,
            $localize`Phone number uniqueness check failed`,
          );
        }),
      ),
    { dispatch: false },
  );

  fetchStarterKits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.fetchStartNowAppStarterKits),
      mergeMap(({ starterKitLanguageCode, isNonPfas }) =>
        this.startNowAppService.fetchStarterKits(starterKitLanguageCode, isNonPfas).pipe(
          map((res) =>
            startNowAppActions.fetchStartNowAppStarterKitsSuccess({ starterKits: res.starterKits }),
          ),
          catchError((error) =>
            of(startNowAppActions.fetchStartNowAppStarterKitsFailure({ error })),
          ),
        ),
      ),
    ),
  );

  fetchDepositPaymentValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.fetchDepositPaymentValue),
      mergeMap(() =>
        this.startNowAppService
          .fetchDepositPaymentValue()
          .pipe(map((res) => startNowAppActions.fetchDepositPaymentValueSuccess({ res }))),
      ),
    ),
  );

  fetchReducedDepositPaymentValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.fetchReducedDepositPaymentValue),
      mergeMap(() =>
        this.startNowAppService
          .fetchReducedDepositPaymentValue()
          .pipe(map((res) => startNowAppActions.fetchReducedDepositPaymentValueSuccess({ res }))),
      ),
    ),
  );

  fetchIsReducedDepositAvailable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.fetchIsReducedDepositAvailable),
      mergeMap(() =>
        this.startNowAppService
          .fetchIsReducedDepositAvailable()
          .pipe(map((res) => startNowAppActions.fetchIsReducedDepositAvailableSuccess({ res }))),
      ),
    ),
  );

  fetchIsZeroDepositAvailable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.fetchIsZeroDepositAvailable),
      mergeMap(() =>
        this.startNowAppService
          .fetchIsZeroDepositAvailable()
          .pipe(map((res) => startNowAppActions.fetchIsZeroDepositAvailableSuccess({ res }))),
      ),
    ),
  );

  checkIfNewPaymentRequired$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.checkIfNewPaymentRequired),
      mergeMap(
        ({
          applicationRegistrationId,
          starterKitId,
          isDepositPayment,
          isReducedDepositPayment,
          isZeroDepositPayment,
        }) =>
          this.startNowAppService
            .checkIfNewPaymentRequired(
              applicationRegistrationId,
              starterKitId,
              isDepositPayment,
              isReducedDepositPayment,
              isZeroDepositPayment,
            )
            .pipe(
              map((res) => startNowAppActions.checkIfNewPaymentRequiredSuccess({ res })),
              catchError((error) => of(startNowAppActions.checkIfNewPaymentRequiredFailure(error))),
            ),
      ),
    ),
  );

  checkIfNewPaymentRequiredSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.checkIfNewPaymentRequiredSuccess),
      filter(({ res }) => res),
      switchMap(() => [
        startNowAppActions.resetPaymentMethod(),
        startNowAppActions.resetClientToken(),
        startNowAppActions.resetVoucherInfo(),
      ]),
    ),
  );

  checkIfNewPaymentRequiredFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.checkIfNewPaymentRequiredFailure),
        tap((error) => {
          this.toastr.error(
            getSnaErrorMessage(error?.error?.status),
            getSnaErrorTitle(error?.error?.status),
          );
        }),
      ),
    { dispatch: false },
  );

  fetchOrderSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.fetchOrderSummary),
      concatLatestFrom(() => [
        this.store$.select(selectSelectedStarterKit),
        this.store$.select(selectAddresses),
        this.store$.select(selectApplicationRegistrationId),
        this.store$.select(selectSecurityKey),
      ]),
      mergeMap(([, starterKit, addresses, applicationRegistrationId, securityKey]) => {
        const shippingAddress = this.createAddressRequest(addresses);
        const request: OrderSummaryRequest = {
          starterKitId: starterKit.id,
          isDepositPayment: !!starterKit.isDepositPayment,
          shippingAddress,
          applicationRegistrationId,
          securityKey,
          isReducedDepositPayment: !!starterKit.isReducedDepositPayment,
          isZeroDepositPayment: !!starterKit.isZeroDepositPayment,
        };
        return this.startNowAppService.fetchOrderSummary(request).pipe(
          map((res) => startNowAppActions.fetchOrderSummarySuccess({ res })),
          catchError(() => of(startNowAppActions.fetchOrderSummaryFailure())),
        );
      }),
    ),
  );

  fetchOrderSummaryFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.fetchOrderSummaryFailure),
        tap(() => {
          this.toastr.error($localize`Order summary fetch`, $localize`Order summary fetch failed`);
        }),
      ),
    { dispatch: false },
  );

  fetchClientToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.fetchClientToken),
      mergeMap(({ payload }) =>
        this.startNowAppService.fetchClientToken(payload).pipe(
          map((res) => startNowAppActions.fetchClientTokenSuccess({ payload: res })),
          catchError((error) => of(startNowAppActions.fetchClientTokenFailure({ error }))),
        ),
      ),
    ),
  );

  storeExternalPaymentInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.storeExternalPaymentInfo),
      map(({ paymentInfo }) =>
        startNowAppActions.storeExternalPaymentInfoSuccess({ payload: paymentInfo }),
      ),
    ),
  );

  createOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.createOrder),
      mergeMap(({ payload }) =>
        this.startNowAppService.createOrder(payload).pipe(
          map((res) => startNowAppActions.createOrderSuccess({ payload: res })),
          catchError((error) => of(startNowAppActions.createOrderFailure({ error }))),
        ),
      ),
    ),
  );

  createOrderFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.createOrderFailure),
        tap(({ error }) => {
          this.toastr.error(GenericErrorMessage.message, GenericErrorMessage.title);
          this.loggerService.error('Create order failed: ', error);
        }),
      ),
    { dispatch: false },
  );

  updateOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.updateOrder),
      mergeMap(({ payload }) =>
        this.startNowAppService.updateOrder(payload).pipe(
          map((response) => startNowAppActions.updateOrderSuccess({ response })),
          catchError((error) => of(startNowAppActions.updateOrderFailure({ error }))),
        ),
      ),
    ),
  );

  updateOrderFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.updateOrderFailure),
        tap(({ error }) => {
          this.toastr.error(GenericErrorMessage.message, GenericErrorMessage.title);
          this.loggerService.error('Update order failed: ', error);
        }),
      ),
    { dispatch: false },
  );

  createPaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.createPaymentMethod),
      mergeMap(({ payload }) =>
        this.startNowAppService.createPaymentMethod(payload).pipe(
          map((res) => startNowAppActions.createPaymentMethodSuccess({ payload: res })),
          catchError((error) => of(startNowAppActions.createPaymentMethodFailure({ error }))),
        ),
      ),
    ),
  );

  createPaymentMethodFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.createPaymentMethodFailure),
        tap((error) => {
          this.toastr.error(
            getSnaErrorMessage(error?.error?.status),
            getSnaErrorTitle(error?.error?.status),
          );
          this.loggerService.error('Create payment method failed: ', error);
        }),
      ),
    { dispatch: false },
  );

  createVoucher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.createVoucher),
      mergeMap(({ payload }) =>
        this.startNowAppService.createVoucher(payload).pipe(
          map((res) => startNowAppActions.createVoucherSuccess({ payload: res })),
          catchError((error) => of(startNowAppActions.createVoucherFailure({ error }))),
        ),
      ),
    ),
  );

  createVoucherFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.createVoucherFailure),
        tap((error) => {
          this.toastr.error(
            getSnaErrorMessage(error?.error?.status),
            getSnaErrorTitle(error?.error?.status),
            ToastrConfigCenterBiggerPermanent,
          );
          this.loggerService.error('Create voucher failed: ', error);
        }),
      ),
    { dispatch: false },
  );

  receiveVouchers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.receiveVouchers),
      mergeMap(({ vouchers }) =>
        this.startNowAppService.receiveVouchers(vouchers).pipe(
          map((res) => startNowAppActions.receiveVouchersSuccess({ payload: res })),
          catchError((error) => of(startNowAppActions.receiveVouchersFailure({ error }))),
        ),
      ),
    ),
  );

  receiveVoucherFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.receiveVouchersFailure),
        tap((error) => {
          this.toastr.error(
            $localize`Receive voucher failed`,
            $localize`Please try again to recreate`,
          );
          this.loggerService.error('Receive voucher failed: ', error);
        }),
      ),
    { dispatch: false },
  );

  logCardAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.logCardAttempt),
      mergeMap(({ request }) =>
        this.startNowAppService.logCardAttempt(request).pipe(
          map(() => startNowAppActions.logCardAttemptSuccess()),
          catchError(({ error }) => of(startNowAppActions.logCardAttemptFailure({ error }))),
        ),
      ),
    ),
  );

  logCardAttemptFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.logCardAttemptFailure),
        tap(() => this.loggerService.error('Log card attempt failure')),
      ),
    { dispatch: false },
  );

  checkIfCanSubmitConsultant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.checkIfCanSubmitConsultant),
      mergeMap(({ payload }) =>
        this.startNowAppService.checkIfCanSubmitConsultant(payload).pipe(
          map((res) => startNowAppActions.checkIfCanSubmitConsultantSuccess({ payload: res })),
          catchError((error) =>
            of(startNowAppActions.checkIfCanSubmitConsultantFailure({ error })),
          ),
        ),
      ),
    ),
  );

  checkIfCanSubmitConsultantFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.checkIfCanSubmitConsultantFailure),
        tap(({ error }) => {
          if (!this.isVoucherLimitError(error.error.errorTypes)) {
            this.toastr.error(
              getCheckCanSubmitSnaErrorMessage(error?.error?.status),
              getCheckCanSubmitSnaErrorTitle(error?.error?.status),
            );
          }
          this.loggerService.error('Consultant submission check failed:', error);
        }),
      ),
    { dispatch: false },
  );

  submitConsultant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.submitConsultant),
      mergeMap(({ payload }) =>
        this.startNowAppService.submitConsultant(payload).pipe(
          map((res) => startNowAppActions.submitConsultantSuccess({ payload: res })),
          catchError((error) => of(startNowAppActions.submitConsultantFailure({ error }))),
        ),
      ),
    ),
  );

  submitConsultantFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.submitConsultantFailure),
        tap(({ error }) => {
          this.toastr.error(
            getSnaSubmitErrorMessage(error?.error?.status),
            getSnaSubmitErrorTitle(error?.error?.status),
          );
          this.loggerService.error('Consultant submission failed:', error);
        }),
      ),
    { dispatch: false },
  );

  fetchVoucherBarcodeUrls$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.fetchVoucherBarCodeUrls),
      mergeMap(({ applicationRegistrationId }) =>
        this.startNowAppService
          .fetchVoucherBarCodeUrls(applicationRegistrationId)
          .pipe(map((urls) => startNowAppActions.fetchVoucherBarCodeUrlsSuccess({ urls }))),
      ),
      catchError(() => of(startNowAppActions.fetchVoucherBarCodeUrlsFailure())),
    ),
  );

  fetchVoucherBarCodeUrlsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.fetchVoucherBarCodeUrlsFailure),
        tap(() => {
          this.toastr.error(
            $localize`Voucher url fetching error`,
            $localize`Fetch voucher urls fail`,
          );
        }),
      ),
    { dispatch: false },
  );

  updateSsnNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startNowAppActions.updateSsnNumber),
      concatLatestFrom(() => [
        this.store$.select(selectApplicationRegistrationId),
        this.store$.select(selectSecurityKey),
      ]),
      mergeMap(([{ ssnNumber }, applicationRegistrationId, securityKey]) =>
        this.startNowAppService
          .saveSsnNumber({
            governmentId: ssnNumber,
            applicationRegistrationId,
            securityKey,
          })
          .pipe(
            map((res) =>
              startNowAppActions.updateSsnNumberSuccess({
                saveConsultantDataResult: {
                  isSuccess: res.isSuccess,
                  errors: res.errorTypes,
                },
              }),
            ),
            catchError((error) => of(startNowAppActions.updateSsnNumberFailure(error))),
          ),
      ),
    ),
  );

  updateSsnNumberFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startNowAppActions.updateSsnNumberFailure),
        tap(({ error }) => {
          if (!error?.errorTypes?.includes(ConsultantSubmissionErrorType.InvalidSsnNumber)) {
            this.toastr.error(getSnaErrorMessage(error?.status), getSnaErrorTitle(error?.status));
          }
          this.loggerService.error('Update ssn number failed: ', error);
        }),
      ),
    { dispatch: false },
  );

  private mapConsultantFinderCriteria(
    { pageNumber, pageSize },
    otherCriteria: ConsultantFinderCriteria,
  ): FindConsultantByAreaRequest {
    return {
      ...otherCriteria,
      pageNumber: pageNumber.toString(),
      pageSize: pageSize.toString(),
    };
  }

  private mapConsultantFinderResults(res: FindConsultantResponse): {
    hasMore: boolean;
    items: Consultant[];
  } {
    return {
      hasMore: res.hasMore,
      items: res.items.map((item) => this.mapConsultantItemResponseToConsultant(item)),
    };
  }

  private mapFindConsultantByZipCodeResponse(
    res: FindConsultantByZipCodeResponse,
  ): FoundConsultantsByZipCode {
    return {
      hasMore: res.hasMore,
      skipConsultantList: res.skipDisplayItems,
      items: res.items?.map((item) => this.mapConsultantItemResponseToConsultant(item)),
    };
  }

  private mapConsultantItemResponseToConsultant(item: ConsultantDto): Consultant {
    if (item) {
      return {
        id: item.id,
        beeNumber: item.beeNumber,
        firstName: item.firstName,
        lastName: item.lastName,
        middleName: item.middleName,
        fullName: item.fullName,
        displayName: item.displayName,
        city: item.city,
        state: item.state,
        email: item.email,
        phoneNumber: item.phoneNumber,
        vanityName: item.vanityName,
        profileImage: item.profileImage,
        facebookUrl: item.facebookUrl,
        story: null,
        isPrivateRecruitment: item.isPrivateRecruitment,
        financialState: item.financialState,
      };
    }
  }

  private createAddressRequest(addresses: Address[]): AddressVerificationRequest {
    let shippingAddress: Address = addresses.find(
      (address) => address.addressType === AddressType.Shipping,
    );
    if (!shippingAddress) {
      shippingAddress = addresses.find((address) => address.addressType === AddressType.Home);
    }

    return shippingAddress;
  }

  private isVoucherLimitError(errorTypes: ConsultantSubmissionErrorType[]): boolean {
    return (
      errorTypes.includes(ConsultantSubmissionErrorType.DailyVoucherAmountReached) ||
      errorTypes.includes(ConsultantSubmissionErrorType.MonthlyVoucherAmountReached) ||
      errorTypes.includes(ConsultantSubmissionErrorType.YearlyVoucherAmountReached)
    );
  }

  constructor(
    private actions$: Actions,
    private startNowAppService: StartNowAppService,
    private store$: Store<AppState>,
    private toastr: ToastrService,
    private loggerService: LoggerService,
  ) {}
}

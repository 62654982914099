import {
  Component,
  EventEmitter,
  Injector,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AddressType } from '@core/enums/address-type.enum';
import { PaymentHandlerType } from '@core/enums/payment-handler-type.enum';
import { PaymentType } from '@core/enums/payment-type.enum';
import { LoggerService } from '@core/services/logger.service';
import { AppState } from '@core/store';
import { selectConsultantState } from '@core/store/consultant';
import { Consultant } from '@core/store/consultant/consultant-state-models';
import {
  selectConfirmRecruiter,
  selectConsultantDataCanNotBeModified,
  selectIsZeroDepositAvailable,
  selectOrderSummary,
  selectPaymentStepSkipped,
  selectSnaCountryStates,
  selectStartNowAppData,
  selectStepProcessing,
} from '@core/store/start-now-app';
import {
  Address,
  CountryState,
  OrderSummary,
  StartNowAppData,
  UserInfo,
} from '@core/store/start-now-app/start-now-app-state-models';
import { stepProcessing, storePaymentType } from '@core/store/start-now-app/start-now-app.actions';
import { Store } from '@ngrx/store';
import { PaymentHandlerBase } from '@payment/payment-handler/payment-handler-base.model';
import { PaymentHandlerFactory } from '@payment/payment-handler/payment-handler-factory';
import { isMexEnv } from '@shared/utils/environment-utils';
import { ssnMasking } from '@shared/utils/ssn-masking-utils';
import { StartNowAppStep } from 'app/modules/start-now-app/enums/start-now-app-step.enum';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { SnaConsultantSubmitComponent } from '../../sna-consultant-submit/sna-consultant-submit.component';
import { StartNowStepBaseComponent } from '../../start-now-app-step-base/start-now-step-base.component';

@Component({
  selector: 'app-double-check-step',
  templateUrl: './double-check-step.component.html',
  styleUrls: ['./double-check-step.component.scss'],
})
export class DoubleCheckStepComponent
  extends StartNowStepBaseComponent
  implements OnInit, OnDestroy
{
  @Output() goToStep: EventEmitter<number> = new EventEmitter<number>();
  readonly AddressType = AddressType;

  ssnMasking = ssnMasking;
  public isMexEnv = isMexEnv;

  StartNowAppStep = StartNowAppStep;
  orderSummary$: Observable<OrderSummary>;
  stepProcessing$: Observable<boolean>;
  consultantDataCanNotBeModified$: Observable<boolean>;
  startNowAppData: StartNowAppData;
  confirmRecruiter: boolean = false;
  isSubmitted: boolean = false;
  replicatedSiteConsultant: Consultant;
  paymentHandler: PaymentHandlerBase;
  countryStates$: Observable<CountryState[]>;
  paymentStepSkipped$: Observable<boolean>;
  isZeroDepositAvailable$: Observable<boolean>;
  consultantDataIsChangable$: Observable<boolean>;
  isLoading: boolean = false;
  addressTitles = {
    [AddressType.Home]: $localize`Address`,
    [AddressType.Shipping]: $localize`Shipping`,
    [AddressType.Billing]: $localize`Billing`,
  };
  public skipPayment: boolean;

  @ViewChild('snaConsultantSubmit')
  private snaConsultantSubmitComponent: SnaConsultantSubmitComponent;

  constructor(
    private store$: Store<AppState>,
    private paymentHandlerFactory: PaymentHandlerFactory,
    private logger: LoggerService,
    injector: Injector,
  ) {
    super(injector, 'SNA Step - 8 Double Check');
  }

  ngOnInit(): void {
    this.initObservables();
    this.paymentHandler = this.paymentHandlerFactory.getPaymentHandler(
      PaymentHandlerType.StartNowApp,
    );
    this.isZeroDepositAvailable$ = this.store$.select(selectIsZeroDepositAvailable);
    this.requestConfirmRecruiter();
    this.requestStartNowAppData();
    this.listenReplicatedSiteConsultant();
    this.paymentStepSkipped$ = this.store$
      .select(selectPaymentStepSkipped)
      .pipe(map((skipPayment) => skipPayment));
    this.subscriptions.add(
      this.paymentStepSkipped$.subscribe((skipPayment) => {
        this.skipPayment = skipPayment;
      }),
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  submitStep(): void {
    this.isSubmitted = true;
    this.store$.dispatch(stepProcessing({ stepProcessing: true }));

    if (this.confirmRecruiter && !this.skipPayment) {
      this.goToNextStep.emit();
      this.store$.dispatch(stepProcessing({ stepProcessing: false }));
    } else if (this.confirmRecruiter && this.skipPayment) {
      this.submitConsultant();
    } else {
      this.store$.dispatch(stepProcessing({ stepProcessing: false }));
    }
  }

  onConsultantSubmitted(isSubmitted): void {
    if (isSubmitted) {
      this.goToNextStep.emit();
    } else {
      this.logger.error('Consultant submission failed');
    }
  }

  fullName(userInfo: UserInfo): string {
    let fullName: string;
    if (userInfo.middleName === '') {
      fullName = userInfo.firstName + ' ' + userInfo.lastName;
    } else {
      fullName = userInfo.firstName + ' ' + userInfo.middleName + ' ' + userInfo.lastName;
    }
    return fullName;
  }

  selectAddress(addresses: Address[], addressType: AddressType): Address {
    let selectedAddress: Address;
    addresses.forEach((address) => {
      if (address.addressType === addressType) {
        selectedAddress = address;
      }
    });
    return selectedAddress;
  }

  navigateTo(step: number) {
    this.goToStep.emit(step);
  }

  protected createFormGroup(): FormGroup {
    throw new Error('Method not implemented.');
  }

  private requestConfirmRecruiter() {
    this.subscriptions.add(
      this.store$
        .select(selectConfirmRecruiter)
        .pipe(take(1))
        .subscribe((confirmRecruiter) => (this.confirmRecruiter = confirmRecruiter)),
    );
  }

  private requestStartNowAppData() {
    this.subscriptions.add(
      this.store$
        .select(selectStartNowAppData)
        .pipe(
          filter((startNowAppData) => !!startNowAppData),
          take(1),
        )
        .subscribe((startNowAppData) => {
          this.startNowAppData = startNowAppData;
        }),
    );
  }

  private listenReplicatedSiteConsultant() {
    this.subscriptions.add(
      this.store$
        .select(selectConsultantState)
        .pipe(
          filter((state) => state.isCurrentConsultantFetched),
          take(1),
        )
        .subscribe((state) => {
          this.replicatedSiteConsultant = state.currentConsultant;
        }),
    );
  }

  private initObservables(): void {
    this.orderSummary$ = this.store$.select(selectOrderSummary);
    this.stepProcessing$ = this.store$.select(selectStepProcessing);
    this.consultantDataCanNotBeModified$ = this.store$
      .select(selectConsultantDataCanNotBeModified)
      .pipe(map((consultantDataCanNotBeModified) => !!consultantDataCanNotBeModified));
    this.countryStates$ = this.store$.select(selectSnaCountryStates);
    this.consultantDataIsChangable$ = combineLatest([
      this.store$.select(selectStepProcessing),
      this.store$.select(selectConsultantDataCanNotBeModified),
    ]).pipe(
      map(
        ([isStepProcessing, consultantDataCanNotBeModified]) =>
          !isStepProcessing && !consultantDataCanNotBeModified,
      ),
    );
  }

  private submitConsultant() {
    this.store$.dispatch(storePaymentType({ paymentType: PaymentType.Finance }));
    this.store$.dispatch(stepProcessing({ stepProcessing: true }));
    this.snaConsultantSubmitComponent.submit();
  }
}

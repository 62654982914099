import { Component, Input } from '@angular/core';
import { OrderSummary, StarterKit } from '@core/store/start-now-app/start-now-app-state-models';
import { isMexEnv } from '@shared/utils/environment-utils';

@Component({
  selector: 'app-starter-kit-info-card',
  templateUrl: './starter-kit-info-card.component.html',
  styleUrls: ['./starter-kit-info-card.component.scss'],
})
export class StarterKitInfoCardComponent {
  @Input() orderSummary: OrderSummary;
  @Input() starterKit: StarterKit;
  @Input() isZeroDepositAvailable: boolean;
  @Input() paymentStepSkipped: boolean;

  readonly UsaStarterKitName: string = $localize`Share + Earn`;

  isMexEnv = isMexEnv;

  constructor() {}
}

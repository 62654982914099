<div class="card">
  <div class="ph-row p-md-2">
    <span class="text-double-dot" i18n>E-mail Address</span>
    <strong data-private>{{ contactInfo.email }}</strong>
  </div>
  <div class="ph-row px-md-2 pb-md-2" [ngClass]="{ 'border-bottom-0': !contactInfo.homeNumber }">
    <span class="text-double-dot" i18n>Cell Phone Number</span>
    <strong data-private>{{ contactInfo.cellPhoneNumber }}</strong>
  </div>
  <div class="ph-row px-md-2 pb-md-2 border-bottom-0" *ngIf="!!contactInfo.homeNumber">
    <span class="text-double-dot" i18n>Home Phone Number</span>
    <strong data-private>{{ contactInfo.homeNumber }}</strong>
  </div>
</div>

import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalConfig } from '@core/models/modal-config.model';
import { AppState } from '@core/store';
import { OrderLine } from '@core/store/cart/cart-state-models';
import { setAddToCartConfirmModal } from '@core/store/cart/cart.actions';
import { Party } from '@core/store/consultant/consultant-state-models';
import { Store } from '@ngrx/store';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cart-pfas-popup',
  templateUrl: './app-cart-pfas-popup.component.html',
  styleUrls: ['./app-cart-pfas-popup.component.scss'],
})
export class AppCartPfasPopupComponent {
  party$: Observable<Party>;

  @Input() mandatory: boolean = false;
  @Input() showExtraHint: boolean = false;
  public pfasCartItems: OrderLine[] = [];

  modalConfig: ModalConfig = {
    hideDismissButton: true,
    hideCloseButton: false,
    onDismiss: this.onDismiss.bind(this),
    onClose: this.onClose.bind(this),
    onDismissHeader: this.onClose.bind(this),
    closeButtonLabel: $localize`RETURN TO CART`,

    ngbModalOptions: {
      size: 'md',
      beforeDismiss: this.onClose.bind(this),
      centered: false,
    },
  };

  @ViewChild('pfasModal') private pfasModal: ModalComponent;

  constructor(
    private store$: Store<AppState>,
    private router: Router,
  ) {}

  openModal(orderLines: OrderLine[]) {
    this.pfasCartItems = orderLines;

    this.pfasModal.open();
  }

  onClose() {
    this.store$.dispatch(setAddToCartConfirmModal({ payload: null }));
    this.router.navigate(['/shopping-cart']);
  }

  onDismiss() {
    this.store$.dispatch(setAddToCartConfirmModal({ payload: null }));
    this.router.navigate(['/shopping-cart']);
  }

  close(): void {
    this.pfasModal.close();
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppState } from '@core/store';
import { updateConfirmRecruiter } from '@core/store/start-now-app/start-now-app.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-recruiter-card',
  templateUrl: './recruiter-card.component.html',
  styleUrls: ['./recruiter-card.component.scss'],
})
export class RecruiterCardComponent {
  @Input() consultantName: string;
  @Input() stepProcessing: boolean;
  @Input() consultantDataCanNotBeModified: boolean;
  @Input() confirmRecruiter: boolean;

  @Output() confirmRecruiterChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private sotre$: Store<AppState>) {}

  navigateToStartNowApp() {
    window.location.reload();
  }

  confirmRecruiterChangeHandler(event: boolean) {
    this.sotre$.dispatch(updateConfirmRecruiter({ confirmRecruiter: event }));
    this.confirmRecruiterChange.emit(event);
  }
}

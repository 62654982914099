<h1 class="text-title" i18n>Your information is important to us</h1>
<h6 class="text-subtitle" i18n> Please double-check that everything is correct </h6>

<div *ngIf="startNowAppData" class="mb-3">
  <div class="row">
    <div class="col-12 col-md-6 mt-md-5 mt-3">
      <app-sna-info-card-header
        cardTitle="Personal Information"
        i18n-cardTitle
        [isChangeable]="consultantDataIsChangable$ | async"
        (navigateTo)="navigateTo(StartNowAppStep.AboutYou)"
      ></app-sna-info-card-header>
      <app-personal-info-card [userInfo]="startNowAppData.userInfo"></app-personal-info-card>
    </div>

    <div class="col-12 col-md-6 mt-md-5 mt-3">
      <app-sna-info-card-header
        cardTitle="Contact Information"
        i18n-cardTitle
        [isChangeable]="consultantDataIsChangable$ | async"
        (navigateTo)="navigateTo(StartNowAppStep.ConnectWithYou)"
      ></app-sna-info-card-header>
      <app-contact-information-card
        [contactInfo]="startNowAppData.contactInfo"
      ></app-contact-information-card>
    </div>

    <div class="col-12 col-md-6 mt-3">
      <app-sna-info-card-header
        [cardTitle]="addressTitles[AddressType.Home]"
        [isChangeable]="consultantDataIsChangable$ | async"
        (navigateTo)="navigateTo(StartNowAppStep.WaitBusinessTools)"
      ></app-sna-info-card-header>
      <app-address-card
        [address]="selectAddress(startNowAppData.addressInfo.addresses, AddressType.Home)"
        [countryStates]="countryStates$ | async"
      >
      </app-address-card>
    </div>

    <div
      class="col-12 col-md-6 mt-3"
      *ngIf="
        selectAddress(startNowAppData.addressInfo.addresses, AddressType.Billing) as billingAddress
      "
    >
      <app-sna-info-card-header
        [cardTitle]="addressTitles[AddressType.Billing]"
        [isChangeable]="consultantDataIsChangable$ | async"
        (navigateTo)="navigateTo(StartNowAppStep.Payment)"
      ></app-sna-info-card-header>
      <app-address-card [address]="billingAddress" [countryStates]="countryStates$ | async">
      </app-address-card>
    </div>

    <div
      *ngIf="
        selectAddress(
          startNowAppData.addressInfo.addresses,
          AddressType.Shipping
        ) as shippingAddress
      "
      class="col-12 col-md-6 mt-3"
    >
      <app-sna-info-card-header
        [cardTitle]="addressTitles[AddressType.Shipping]"
        [isChangeable]="consultantDataIsChangable$ | async"
        (navigateTo)="navigateTo(StartNowAppStep.WaitBusinessTools)"
      ></app-sna-info-card-header>
      <app-address-card [address]="shippingAddress" [countryStates]="countryStates$ | async">
      </app-address-card>
    </div>

    <div class="col-12 col-md-6 mt-3">
      <app-sna-info-card-header
        cardTitle="Kit"
        i18n-cardTitle
        [isChangeable]="consultantDataIsChangable$ | async"
        (navigateTo)="navigateTo(StartNowAppStep.SelectStarterKit)"
      ></app-sna-info-card-header>
      <app-starter-kit-info-card
        [starterKit]="startNowAppData.selectedStarterKit"
        [orderSummary]="orderSummary$ | async"
        [isZeroDepositAvailable]="isZeroDepositAvailable$ | async"
        [paymentStepSkipped]="paymentStepSkipped$ | async"
      ></app-starter-kit-info-card>
    </div>

    <div *ngIf="startNowAppData.addressInfo.addresses.length === 3" class="col-12 col-md-6 mt-3">
      <ng-container *ngTemplateOutlet="passwordCard"></ng-container>
    </div>

    <div class="col-12 col-md-6 mt-3">
      <app-sna-info-card-header
        cardTitle="Recruiter"
        i18n-cardTitle
        [isChangeable]="consultantDataIsChangable$ | async"
        (navigateTo)="navigateTo(StartNowAppStep.ConfirmConsultant)"
      ></app-sna-info-card-header>
      <app-recruiter-card
        [consultantName]="startNowAppData.selectedConsultant.fullName"
        [stepProcessing]="stepProcessing$ | async"
        [consultantDataCanNotBeModified]="consultantDataCanNotBeModified$ | async"
        [(confirmRecruiter)]="confirmRecruiter"
      ></app-recruiter-card>
      <div *ngIf="!confirmRecruiter && isSubmitted">
        <span class="validation-error-message" i18n>
          Please confirm on your recruiter before finalizing your application!
        </span>
      </div>
    </div>

    <div *ngIf="startNowAppData.addressInfo.addresses.length < 3" class="col-12 col-md-6 mt-3">
      <ng-container *ngTemplateOutlet="passwordCard"></ng-container>
    </div>
  </div>
  <div
    *ngIf="(isZeroDepositAvailable$ | async) && (paymentStepSkipped$ | async)"
    class="col-12 mt-4"
  >
    <app-less-deposit-information></app-less-deposit-information>
  </div>

  <app-sna-consultant-submit-component
    #snaConsultantSubmit
    [paymentHandler]="paymentHandler"
    [isPaymentProviderFirstLoading]="isLoading"
    (consultantSubmitted)="onConsultantSubmitted($event)"
  >
  </app-sna-consultant-submit-component>
</div>

<ng-template #passwordCard>
  <app-sna-info-card-header
    cardTitle="Consultant's Corner Password"
    i18n-cardTitle
    [isChangeable]="consultantDataIsChangable$ | async"
    (navigateTo)="navigateTo(StartNowAppStep.PasswordBackofficePortal)"
  ></app-sna-info-card-header>
  <app-password-card [password]="startNowAppData.userInfo.password"></app-password-card>
</ng-template>

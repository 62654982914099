<div class="card" *ngIf="address">
  <div class="ph-row p-md-2">
    <span class="text-double-dot" i18n>Address Line 1</span>
    <strong data-private>{{ address.address1 }}</strong>
  </div>
  <div class="ph-row px-md-2 pb-md-2" *ngIf="!!address.address2">
    <span class="text-double-dot" i18n>Address Line 2</span>
    <strong data-private>{{ address.address2 }}</strong>
  </div>
  <div class="ph-row px-md-2 pb-md-2" *ngIf="!!address.address3">
    <span class="text-double-dot" i18n>Address Line 3</span>
    <strong data-private>{{ address.address3 }}</strong>
  </div>
  <div class="ph-row px-md-2 pb-md-2">
    <span class="text-double-dot" i18n>City</span>
    <strong data-private>{{ address.city }}</strong>
  </div>
  <div class="ph-row px-md-2 pb-md-2">
    <span class="text-double-dot" i18n>State</span>
    <strong>{{ stateName }}</strong>
  </div>
  <div class="ph-row px-md-2 pb-md-2 border-bottom-0">
    <span class="text-double-dot" i18n>ZIP Code</span>
    <strong>{{ address.zip }}</strong>
  </div>
</div>

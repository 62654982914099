<div class="card">
  <div class="ph-row p-md-2">
    <span class="text-double-dot" i18n>Kit Selection</span>
    <strong>
      {{ isMexEnv ? starterKit.description1 : UsaStarterKitName }}
    </strong>
  </div>
  <div *ngIf="isMexEnv; else usa">
    <div class="ph-row px-md-2 pb-md-2">
      <span class="text-double-dot" i18n>Total cost</span>
      <strong>{{ starterKit.price | phCurrency }}</strong>
    </div>
    <div class="ph-row px-md-2 pb-md-2 border-bottom-0">
      <span i18n>Includes VAT and shipping.</span>
    </div>
  </div>
  <ng-template #usa>
    <div *ngIf="orderSummary">
      <div class="ph-row p-md-2">
        <span class="text-double-dot" i18n>Price</span>
        <strong>
          {{ orderSummary?.price | phCurrency }}
        </strong>
      </div>
      <div class="ph-row p-md-2">
        <span class="text-double-dot" i18n>State & local taxes</span>
        <strong>
          {{ orderSummary?.taxAmount | phCurrency }}
        </strong>
      </div>
      <div
        class="ph-row p-md-2"
        [ngClass]="{
          'border-bottom-0':
            !orderSummary?.lessDeposit &&
            !isZeroDepositAvailable &&
            !paymentStepSkipped &&
            orderSummary?.lessDeposit
        }"
      >
        <span class="text-double-dot" i18n>Total cost</span>
        <strong>
          {{ orderSummary?.totalCost | phCurrency }}
        </strong>
      </div>
      <ng-container
        *ngIf="
          !!orderSummary?.lessDeposit ||
          (isZeroDepositAvailable && paymentStepSkipped && orderSummary?.lessDeposit === 0)
        "
      >
        <div class="ph-row p-md-2">
          <span class="text-double-dot" i18n>Less deposit</span>
          <strong>
            {{ orderSummary?.lessDeposit | phCurrency }}
          </strong>
        </div>
        <div class="ph-row p-md-2">
          <span class="text-double-dot" i18n>Balance due</span>
          <strong>
            {{ orderSummary?.balanceDue | phCurrency }}
          </strong>
        </div>
        <div class="ph-row p-md-2 border-bottom-0">
          <span class="text-double-dot" i18n>You pay</span>
          <strong>
            {{ orderSummary?.lessDeposit | phCurrency }}
          </strong>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>

import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Country } from '@core/enums/country.enum';
import { StartNowPaymentType } from '@core/enums/start-now-app.enum';
import { AppState } from '@core/store';
import {
  selectApplicationRegistrationId,
  selectDepositPaymentValue,
  selectIsReducedDepositAvailable,
  selectIsZeroDepositAvailable,
  selectReducedDepositPaymentValue,
  selectSelectedStartNowPaymentType,
  selectSelectedStarterKit,
  selectStarterKitStep,
  selectStarterKits,
} from '@core/store/start-now-app';
import { StarterKit, StarterKitStep } from '@core/store/start-now-app/start-now-app-state-models';
import {
  checkIfNewPaymentRequired,
  fetchOrderSummary,
  resetOrderSummary,
  resetStarterKitStep,
  stepProcessing,
  updateIsDepositPayment,
  updateIsReducedDepositPayment,
  updateIsZeroDepositPayment,
  updatePaymentStepSkipped,
  updateStartNowAppSelectedStarterKit,
} from '@core/store/start-now-app/start-now-app.actions';
import { environment } from '@env';
import { Store, select } from '@ngrx/store';
import { isUsaEnv } from '@shared/utils/environment-utils';
import { StatusCodes } from 'http-status-codes';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { StartNowStepBaseComponent } from '../../start-now-app-step-base/start-now-step-base.component';

@Component({
  selector: 'app-select-starter-kit-step',
  templateUrl: './select-starter-kit-step.component.html',
  styleUrls: ['./select-starter-kit-step.component.scss'],
})
export class SelectStarterKitStepComponent
  extends StartNowStepBaseComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  readonly StatusCodes = StatusCodes;
  starterKits$: Observable<StarterKit[]>;
  selectedStarterKit$: Observable<StarterKit>;
  selectedStarterKit: StarterKit;
  starterKitStepData$: Observable<StarterKitStep>;
  depositPaymentValue$: Observable<number>;
  country = environment.country;
  isDepositPayment: boolean = false;
  isReducedDepositPayment: boolean = false;
  isReducedDepositAvailable$: Observable<boolean>;
  isZeroDepositPayment: boolean = false;
  isZeroDepositAvailable$: Observable<boolean>;
  Country = Country;
  reducedDepositPaymentValue$: Observable<number>;
  public readonly StartNowPaymentType = StartNowPaymentType;
  public selectedStartNowPaymentType: StartNowPaymentType = StartNowPaymentType.FullPayment;
  titles = {
    [Country.Mexico]: $localize`Select your starter kit`,
    [Country.Usa]: $localize`Share + earn kit`,
  };

  constructor(
    private store$: Store<AppState>,
    injector: Injector,
  ) {
    super(injector, 'SNA Step - 4 Select Starter Kit');
  }

  ngOnInit(): void {
    this.store$.dispatch(resetOrderSummary());
    this.starterKits$ = this.store$.select(selectStarterKits);
    this.starterKitStepData$ = this.store$.select(selectStarterKitStep);
    this.selectedStarterKit$ = this.store$.select(selectSelectedStarterKit);
    if (isUsaEnv) {
      this.depositPaymentValue$ = this.store$.select(selectDepositPaymentValue);
      this.reducedDepositPaymentValue$ = this.store$.select(selectReducedDepositPaymentValue);
      this.isReducedDepositAvailable$ = this.store$.select(selectIsReducedDepositAvailable);
      this.isZeroDepositAvailable$ = this.store$.select(selectIsZeroDepositAvailable);
    }
    this.listenStarterKitStepFlag();
    this.listenIsNextEnabled();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store$.dispatch(resetStarterKitStep());
  }

  ngAfterViewInit(): void {
    this.selectDefaultStarterKit();
    this.prepopulateSelectedStartNowPaymentType();
  }

  submitStep(): void {
    if (!!this.selectedStarterKit) {
      if (this.selectedStartNowPaymentType == StartNowPaymentType.FullPayment) {
        this.store$.dispatch(updatePaymentStepSkipped({ paymentStepSkipped: false }));
      }
      this.store$.dispatch(stepProcessing({ stepProcessing: true }));

      this.checkIfNewPaymentRequired();
    }
  }

  onChangeStartNowPaymentType(newValue: StartNowPaymentType): void {
    let zeroPayment: boolean = false;
    this.isZeroDepositAvailable$.pipe(take(1)).subscribe((val) => {
      zeroPayment = val;
    });
    this.selectedStartNowPaymentType = newValue;
    if (this.selectedStartNowPaymentType == StartNowPaymentType.FullPayment) {
      this.isReducedDepositPayment = false;
      this.isDepositPayment = false;
      this.isZeroDepositPayment = false;
      this.store$.dispatch(updatePaymentStepSkipped({ paymentStepSkipped: false }));
    } else if (
      this.selectedStartNowPaymentType == StartNowPaymentType.DepositAmount ||
      this.selectedStartNowPaymentType == StartNowPaymentType.ReducedDepositAmount
    ) {
      this.isReducedDepositPayment =
        this.selectedStartNowPaymentType == StartNowPaymentType.ReducedDepositAmount ? true : false;
      this.isDepositPayment =
        this.selectedStartNowPaymentType == StartNowPaymentType.DepositAmount ? true : false;
      this.isZeroDepositPayment =
        this.selectedStartNowPaymentType == StartNowPaymentType.ReducedDepositAmount && zeroPayment
          ? true
          : false;
      this.store$.dispatch(
        updatePaymentStepSkipped({ paymentStepSkipped: this.isZeroDepositPayment }),
      );
    }
  }

  selectStarterKit(selectedStarterKit: StarterKit): void {
    this.selectedStarterKit = selectedStarterKit;
  }

  isSelected(selectedStarterKit: StarterKit, starterKit: StarterKit) {
    return selectedStarterKit?.id === starterKit?.id;
  }

  protected createFormGroup(): FormGroup {
    throw new Error('Method not implemented.');
  }

  /** Check if new payment required based on starter kit changes based on back/forth UI navigation */
  private checkIfNewPaymentRequired() {
    this.subscriptions.add(
      this.store$
        .select(selectApplicationRegistrationId)
        .pipe(
          take(1),
          filter((applicationRegistrationId) => !!applicationRegistrationId),
        )
        .subscribe((applicationRegistrationId) => {
          // check and update the starterKitStepData.fetched
          this.store$.dispatch(
            checkIfNewPaymentRequired({
              applicationRegistrationId,
              starterKitId: this.selectedStarterKit.id,
              isDepositPayment: this.isDepositPayment,
              isReducedDepositPayment: this.isReducedDepositPayment,
              isZeroDepositPayment: this.isZeroDepositPayment,
            }),
          );
        }),
    );
  }

  private selectDefaultStarterKit(): void {
    this.subscriptions.add(
      this.selectedStarterKit$
        .pipe(
          filter((selectedStarterKit) => !!selectedStarterKit),
          take(1),
        )
        .subscribe((selectedStarterKit) => {
          this.selectStarterKit(selectedStarterKit);
        }),
    );
  }

  private prepopulateSelectedStartNowPaymentType(): void {
    const selectedStartNowPaymentTypeSub = this.store$
      .select(selectSelectedStartNowPaymentType)
      .pipe(
        filter((selectedStartNowPaymentType) => !!selectedStartNowPaymentType),
        take(1),
      )
      .subscribe(this.onChangeStartNowPaymentType.bind(this));
    this.subscriptions.add(selectedStartNowPaymentTypeSub);
  }

  private listenStarterKitStepFlag(): void {
    this.subscriptions.add(
      this.starterKitStepData$
        .pipe(
          select((starterKitStepData) => starterKitStepData?.fetched),
          filter((fetched) => fetched),
        )
        .subscribe(() =>
          // update and set starterKitStep isNextEnabled and errors
          this.store$.dispatch(
            updateStartNowAppSelectedStarterKit({
              selectedStarterKit: this.selectedStarterKit,
              selectedStartNowPaymentType: this.selectedStartNowPaymentType,
              isDepositPayment: this.isDepositPayment,
              isReducedDepositPayment: this.isReducedDepositPayment,
              isZeroDepositPayment: this.isZeroDepositPayment,
            }),
          ),
        ),
    );
  }

  private listenIsNextEnabled(): void {
    this.subscriptions.add(
      this.starterKitStepData$
        .pipe(
          filter(
            (starterKitStepData) =>
              starterKitStepData?.isNextEnabled &&
              !starterKitStepData?.errors?.length &&
              !starterKitStepData?.statusCode,
          ),
        )
        .subscribe(() => this.nextStep()),
    );
  }

  private nextStep(): void {
    this.store$.dispatch(updateIsDepositPayment({ isDepositPayment: this.isDepositPayment }));
    this.store$.dispatch(
      updateIsReducedDepositPayment({ isReducedDepositPayment: this.isReducedDepositPayment }),
    );
    this.store$.dispatch(
      updateIsZeroDepositPayment({ isZeroDepositPayment: this.isZeroDepositPayment }),
    );
    this.store$.dispatch(stepProcessing({ stepProcessing: false }));
    this.store$.dispatch(fetchOrderSummary());
    this.goToNextStep.emit();
  }
}

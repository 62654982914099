import { Component, Input } from '@angular/core';
import { CartRoutes } from '@core/constants/routes.const';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cart-icon',
  templateUrl: './cart-icon.component.html',
  styleUrls: ['./cart-icon.component.scss'],
})
export class CartIconComponent {
  @Input() quantityCount$: Observable<number>;

  readonly ShoppingCartRoute = CartRoutes.ShoppingCart;

  constructor() {}
}

/* eslint-disable indent */
import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { StartNowAppRoutes } from '@core/constants/routes.const';
import { ConsultantFinancialStateEnum } from '@core/enums/consultant-financial-state.enum';
import { AppState } from '@core/store';
import { selectConsultantFinancialState } from '@core/store/consultant';
import { Store } from '@ngrx/store';
import { InfoModalComponent } from '@shared/components/info-modal/info-modal.component';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-not-allowed-to-place-order-notification-initializer',
  templateUrl: './not-allowed-to-place-order-notification-initializer.component.html',
  styleUrls: ['./not-allowed-to-place-order-notification-initializer.component.scss'],
})
export class NotAllowedToPlaceOrderNotificationInitializerComponent
  implements AfterViewInit, OnDestroy
{
  private subscriptions: Subscription = new Subscription();
  @ViewChild('infoModal') private infoModal: InfoModalComponent;

  constructor(private store$: Store<AppState>) {}

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.store$
        .select(selectConsultantFinancialState)
        .pipe(
          filter(() => !window.location.pathname.includes(`/${StartNowAppRoutes.StartNowApp}`)),
          filter(() => !window.location.pathname.includes('/deliveryinfo')),
          filter((state) => state === ConsultantFinancialStateEnum.NotAllowedToPlaceOrders),
        )
        .subscribe(() => {
          this.infoModal.open();
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

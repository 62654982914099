import { RecipeAndMoreRoutes } from '@core/constants/routes.const';
import { RelatedCarouselSlide } from '@core/models/related-carousel-slide.model';
import { RecipeItem } from '@core/store/recipe/recipe-state-models';

export function createRecipeDetailsUrl(recipeUrl: string): string {
  return `/${RecipeAndMoreRoutes.Recipe}/${RecipeAndMoreRoutes.Detail}/${recipeUrl}`;
}

export function createBlogDetailsUrl(blogUrl: string): string {
  return `/${RecipeAndMoreRoutes.Blog}/${RecipeAndMoreRoutes.Detail}/${blogUrl}`;
}

export function getRelatedSlidesFromRelatedRecipes(recipes: RecipeItem[]): RelatedCarouselSlide[] {
  return recipes.map((r) => ({
    images: r.images,
    targetUrl: createRecipeDetailsUrl(r.url),
  }));
}

<div class="footer-top ph-footer shade-{{ shade }}">
  <div class="py-3 d-flex flex-wrap justify-content-center">
    <div class="container">
      <div class="row d-flex align-items-center justify-content-center">
        <div class="my-2 my-md-0 col-xs-12 col-md-4"> </div>
        <div class="my-2 my-md-0 social-icons col-xs-12 col-md-4">
          <ng-container *ngIf="feature.footerSocial">
            <div class="row">
              <div class="col-12 py-2 d-flex justify-content-center" i18n>
                Be social.&nbsp;<strong>Connect with us. </strong></div
              >
              <div class="col-12 d-flex justify-content-center">
                <a
                  aria-label="Facebook"
                  target="_blank"
                  href="https://www.facebook.com/princesshouse"
                  class="link-highlight"
                >
                  <img class="icon" alt="Facebook" src="assets/images/social/FACEBOOK.svg" />
                </a>
                <a
                  aria-label="Twitter"
                  target="_blank"
                  href="https://twitter.com/princesshouseco"
                  class="link-highlight"
                >
                  <img class="icon" alt="Twitter" src="assets/images/social/TWITTER.svg" />
                </a>
                <a
                  aria-label="Pinterest"
                  target="_blank"
                  href="https://www.pinterest.com/princesshouse/"
                  class="link-highlight"
                >
                  <img class="icon" alt="Pinterest" src="assets/images/social/PINTEREST.svg" />
                </a>
                <a
                  aria-label="Instagram"
                  target="_blank"
                  href="https://www.instagram.com/princesshouseinc/"
                  class="link-highlight"
                >
                  <img class="icon" alt="Instagram" src="assets/images/social/INSTAGRAM.svg" />
                </a>
                <a
                  aria-label="Youtube"
                  target="_blank"
                  href="https://www.youtube.com/user/PrincessHouseInc"
                  class="link-highlight"
                >
                  <img class="icon" alt="Youtube" src="assets/images/social/YOU-TUBE.svg" />
                </a>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="my-2 my-md-0 col-xs-12 col-md-4">
          <a
            *ngIf="feature.consultantCorner"
            aria-label="Consultant's Corner"
            target="_blank"
            class="cc-button link-highlight"
            href="{{ consultantCornerUrl() }}"
          >
            <span i18n>CONSULTANT'S CORNER</span>
            <i alt="" class="bi bi-caret-right-fill"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

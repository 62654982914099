import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from '@base/components/layout/default-layout/default-layout.component';
import { ConsultantRoutes } from '@core/constants/routes.const';
import { ConsultantGuardService } from '@core/services/consultant-guard.service';
import { ConsultantFinderPageComponent } from './consultant-finder-page/consultant-finder-page.component';
import { ConsultantLocatorPageComponent } from './consultant-locator-page/consultant-locator-page.component';
import { MyOpenPartiesPagesComponent } from './my-open-parties-pages/my-open-parties-pages.component';
import { MyStoryPageComponent } from './my-story-page/my-story-page.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: ConsultantRoutes.MyStoryPageRoute,
        component: MyStoryPageComponent,
        canActivate: [ConsultantGuardService],
        data: { title: $localize`PH - My Story Page - Title` },
      },
      {
        path: ConsultantRoutes.MyOpenPartiesRoute,
        component: MyOpenPartiesPagesComponent,
        canActivate: [ConsultantGuardService],
        data: { title: $localize`PH - My Open Parties Page - Title` },
      },
      {
        path: 'consultant-finder',
        component: ConsultantFinderPageComponent,
        data: {
          title: $localize`Search for a Consultant`,
          description: $localize`Search for a Consultant - Description`,
        },
      },
      {
        path: 'consultant-locator',
        component: ConsultantLocatorPageComponent,
        data: {
          title: $localize`PH - Consultant Locator Page - Title`,
          description: $localize`PH - Consultant Locator Page - Description`,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ConsultantRoutingModule {}

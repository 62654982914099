<div class="card d-md-none">
  <div class="ph-row p-md-2 border-bottom-0">
    <span class="text-double-dot" i18n>Password</span>
    <strong>
      <span>
        {{ showPassword ? password : '*'.repeat(password.length) }}
      </span>
      <span class="pointer" (click)="togglePasswordShowing()">
        <i
          [ngClass]="{
            bi: true,
            'bi-eye-slash': showPassword,
            'bi-eye': !showPassword
          }"
        >
        </i>
      </span>
    </strong>
  </div>
</div>
<div class="card d-none d-md-block">
  <div class="ph-row p-md-2 d-flex justify-content-between border-bottom-0">
    <span>
      <span class="text-double-dot" i18n>Password</span>
      <strong>
        {{ showPassword ? password : '*'.repeat(password.length) }}
      </strong>
    </span>
    <span class="pointer" (click)="togglePasswordShowing()">
      <i
        [ngClass]="{
          bi: true,
          'bi-eye-slash': showPassword,
          'bi-eye': !showPassword
        }"
      >
      </i>
    </span>
  </div>
</div>

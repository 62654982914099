<div class="footer-bottom ph-footer shade-{{ shade }}">
  <div class="container-fluid copyright py-3 flex justify-content-center">
    <div class="row flex-stretch d-flex justify-content-center">
      <div
        *ngIf="feature.footerExtra"
        class="py-1 pr-md-0 col-sm-12 col-md-3 col-lg-3 d-flex justify-content-between align-content-stretch"
      >
        <a
          target="_blank"
          aria-label="Better Business Bureau"
          i18n-aria-label
          class="d-flex link-highlight"
          href="http://www.bbb.org/boston/business-reviews/glassware-wholesale-and-manufacturers/princess-house-inc-in-taunton-ma-61/#bbbonlineclick"
        >
          <img class="icon" alt="bbb" src="assets/images/footer/BBB.svg" />
        </a>
      </div>

      <div class="filler"></div>

      <div
        [ngClass]="{
          'py-1 d-flex justify-content-center justify-content-md-end align-items-end': true,
          'col-sm-12 col-md-5 col-lg-5': feature.footerExtra,
          'col-12': feature.footerExtra
        }"
      >
        &copy;{{ currentYear }}&nbsp;<span i18n>Princess House, Inc. All Rights Reserved</span></div
      >
    </div>
  </div>
</div>

<div class="d-md-none">
  <ng-container *ngFor="let orderLine of sortableOrderLines">
    <div class="row order-card p-2">
      <a
        [routerLink]="'/{{ productRoute }}/{{ pRoute }}/' + orderLine.sku"
        class="col-4 d-flex align-items-center pl-3"
      >
        <img
          class="img-thumbnail"
          phImage
          [images]="orderLine.images"
          [thumbnailDesktop]="thumbnail.productCheckoutDesktop"
          [thumbnailMobile]="thumbnail.productCheckoutMobile"
        />
      </a>
      <div class="col">
        <div class="row card-text">
          <div class="col">
            <div class="card-text-category">{{ orderLine.collectionName }}</div>
            <a
              [routerLink]="'/{{ productRoute }}/{{ pRoute }}/' + orderLine.sku"
              class="card-text-productname product-name"
              >{{ orderLine.productName | titlecase }}</a
            >
            <div class="font-weight-bold">#{{ orderLine.sku }}</div>
          </div>
        </div>
        <div class="row mt-2 mb-3">
          <div class="col">
            <span class="qty mr-2">{{ orderLine.quantity }}X</span>
            <span class="discounted-price mr-2">
              {{ orderLine.discountedPrice | phCurrency }}
            </span>
            <ng-container *ngIf="orderLine.discountedPrice < orderLine.originalPrice">
              <span class="original-price">{{ orderLine.originalPrice | phCurrency }}</span>
            </ng-container>
          </div>
        </div>
        <div class="row">
          <div class="col"> {{ orderLine.total | phCurrency }} </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="d-none d-md-flex">
  <div class="d-flex justify-content-center align-items-center order-content-row">
    <table class="table">
      <thead>
        <tr>
          <th
            *ngFor="let column of orderLineColumns"
            scope="col"
            class="text-uppercase"
            (click)="onSort(column.type)"
            role="button"
          >
            <span>{{ column.title }}</span>
            <i
              *ngIf="sortColumn === column.type"
              [ngClass]="{
                bi: true,
                'bi-caret-up-fill': direction === tableSortDirection.asc,
                'bi-caret-down-fill': direction === tableSortDirection.desc
              }"
            ></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let orderLine of sortableOrderLines">
          <td class="w-auto">
            <div class="row">
              <a
                [routerLink]="'/{{ productRoute }}/{{ pRoute }}/' + orderLine.sku"
                class="col-6 d-flex align-items-center"
              >
                <img
                  phImage
                  [images]="orderLine.images"
                  [thumbnailDesktop]="thumbnail.productCheckoutDesktop"
                  [thumbnailMobile]="thumbnail.productCheckoutMobile"
                  class="img-thumbnail"
                />
              </a>
              <div class="col py-2 pl-0 align-self-center">
                <div class="text-secondary product">{{ orderLine.collectionName }}</div>
                <a
                  [routerLink]="'/{{ productRoute }}/{{ pRoute }}/' + orderLine.sku"
                  class="font-weight-bold product-name"
                >
                  {{ orderLine.productName | titlecase }}
                </a>
                <div class="product text-secondary font-weight-bold">#{{ orderLine.sku }}</div>
              </div>
            </div>
          </td>
          <td class="w-7">
            <div>
              <p>{{ orderLine.quantity }}</p>
            </div>
          </td>
          <td class="w-auto">
            <div>
              <p>{{ orderLine.discountedPrice | phCurrency }}</p>
            </div>
          </td>
          <td class="w-auto">
            <div>
              <p>{{ orderLine.total | phCurrency }}</p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

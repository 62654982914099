import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserRoute } from '@core/constants/routes.const';
import { CountryStateService } from '@core/services/country-state.service';
import { UserService } from '@core/services/user.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { getErrorMessage } from '@shared/utils/get-error-message';
import { getErrorTitle } from '@shared/utils/get-error-title';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { AppState } from '..';
import { logOutResetConcluded } from '../auth/auth.actions';
import { AddressType } from './address-state-models';
import * as addressActions from './address.actions';

@Injectable()
export class AddressEffects {
  fetchAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addressActions.fetchAddresses),
      mergeMap(() =>
        this.userService.fetchAddresses().pipe(
          map((res) => {
            const addresses = res.addresses.map((address) => {
              const addressLine3 = address.addressLine3 ?? '';
              return {
                type: address.default ? AddressType.default : AddressType.other,
                address: `${address.addressLine1} ${address.addressLine2} ${addressLine3}`,
                ...address,
              };
            });
            return addressActions.fetchAddressesSuccess({ addresses });
          }),
          catchError((error) => of(addressActions.fetchAddressesFailure(error))),
        ),
      ),
    ),
  );

  createAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addressActions.createAddress),
      mergeMap(({ address }) =>
        this.userService.createAddress(address).pipe(
          map((res) => {
            if (!res.saveSuccessful) {
              return addressActions.addressValidation({ addressValidation: res });
            } else {
              return addressActions.createAddressSuccess({ addressValidation: res });
            }
          }),
          catchError((error) => of(addressActions.createAddressFailure(error))),
        ),
      ),
    ),
  );

  createAddressSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addressActions.createAddressSuccess),
        tap(() => this.router.navigate([UserRoute.MyAccount, UserRoute.Addresses])),
      ),
    { dispatch: false },
  );

  createAddressFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addressActions.createAddressFailure),
        filter(({ error }) => !!error),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  updateAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addressActions.updateAddress),
      mergeMap(({ address }) =>
        this.userService.updateAddress(address).pipe(
          map((res) => {
            if (!res.saveSuccessful) {
              return addressActions.addressValidation({ addressValidation: res });
            } else {
              return addressActions.updateAddressSuccess({ addressValidation: res });
            }
          }),
          catchError((error) => of(addressActions.updateAddressFailure(error))),
        ),
      ),
    ),
  );

  updateAddressSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addressActions.updateAddressSuccess),
        tap(() => this.router.navigate([UserRoute.MyAccount, UserRoute.Addresses])),
      ),
    { dispatch: false },
  );

  updateAddressFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addressActions.updateAddressFailure),
        tap(({ error }) => {
          this.toastr.error(getErrorMessage(error), getErrorTitle(error));
        }),
      ),
    { dispatch: false },
  );

  deleteAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addressActions.deleteAddress),
      mergeMap(({ id }) =>
        this.userService.deleteAddress(id).pipe(
          map(() => addressActions.deleteAddressSuccess()),
          catchError((error) => of(addressActions.deleteAddressFailure(error))),
        ),
      ),
    ),
  );

  deleteAddressSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addressActions.deleteAddressSuccess),
        tap(() => this.router.navigate([UserRoute.MyAccount, UserRoute.Addresses])),
      ),
    { dispatch: false },
  );

  fetchCountryStates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addressActions.fetchCountryStates),
      mergeMap(() =>
        this.countryStateService.fetchStatesByCountry().pipe(
          map((res) => addressActions.fetchCountryStatesSuccess({ countryStates: res.states })),
          catchError((error) => of(addressActions.fetchCountryStatesFailure(error))),
        ),
      ),
    ),
  );

  resetAddressState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addressActions.resetAddressState),
      map(() => logOutResetConcluded({ state: 'addressState' })),
    ),
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private countryStateService: CountryStateService,
    private router: Router,
    private toastr: ToastrService,
    private store: Store<AppState>,
  ) {}
}

<ng-container *ngIf="consultant$ | async as consultant">
  <div class="container root p-3 text-white">
    <div class="row">
      <div class="col-12 col-md-2 align-self-center">
        <div class="text-center">
          <img
            [src]="consultant.profileImage?.url || imagePlaceholder"
            [altText]="consultant.profileImage?.altText"
          />
        </div>
      </div>

      <div class="col-12 col-md-6 mt-md-0 mt-3">
        <div>
          <span class="h1 mb-0 text-uppercase">{{ consultant.displayName }}</span>
        </div>
        <div>
          <span class="h5 mb-0 font-weight-bold" i18n>
            Consultant in {{ consultant.city | uppercase }}, {{ consultant.state }}
          </span>
        </div>
        <div class="mt-1">
          <div>
            <hr />
          </div>
          <div class="pointer" routerLink="my-story">
            <span class="h2 mb-0" i18n>My Princess House </span>
            <span class="inline text-nowrap">
              <span class="h2 mb-0">Story</span>
              <i class="bi bi-caret-right-fill h2"></i>
            </span>
          </div>
          <div>
            <div [innerHtml]="consultant.story?.storyBody" class="truncate-overflow"></div>
            <a class="font-italic" routerLink="my-story" i18n>Read more</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4 align-self-center">
        <div class="mt-3 mt-md-0">
          <a
            *ngIf="consultant.parties?.length"
            class="button link-highlight w-100 mb-2"
            routerLink="my-open-parties"
          >
            <span class="text-uppercase" i18n>My open parties</span>
            <i class="bi bi-caret-right-fill"></i>
          </a>
          <a
            *ngIf="feature.cart"
            routerLink="hostess-special-offers"
            class="button link-highlight w-100 mb-2"
          >
            <span class="text-uppercase" i18n>Hostess specials</span>
            <i class="bi bi-caret-right-fill"></i>
          </a>
          <a
            *ngIf="feature.cart"
            [routerLink]="Routes.CustomerSpecialOffers"
            class="button link-highlight w-100"
          >
            <span class="text-uppercase" i18n>Customer specials</span>
            <i class="bi bi-caret-right-fill"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>

import { Component, Input } from '@angular/core';
import { ContactInfo } from '@core/store/start-now-app/start-now-app-state-models';

@Component({
  selector: 'app-contact-information-card',
  templateUrl: './contact-information-card.component.html',
  styleUrls: ['./contact-information-card.component.scss'],
})
export class ContactInformationCardComponent {
  @Input() contactInfo: ContactInfo;

  constructor() {}
}

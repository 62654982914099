import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeliveryInfoLayoutComponent } from '@base/components/layout/delivery-info-layout/delivery-info-layout.component';
import { DeliveryInfoRoutes } from '@core/constants/routes.const';
import { DeliveryInfoPageComponent } from './components/deliver-info-page/delivery-info-page.component';

const routes: Routes = [
  {
    path: '',
    component: DeliveryInfoLayoutComponent,
    children: [
      {
        path: DeliveryInfoRoutes.DeliveryInfo,
        component: DeliveryInfoPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DeliveryInfoRoutingModule {}

<app-modal #modal [modalConfig]="modalConfig">
  <div>
    <div class="text-center">
      <p class="font-weight-bold" i18n>
        We recognized you have an active registartion at Princess House. You can log in now for a
        better shopping experinece.
      </p>
    </div>
    <iframe
      id="loginFrame"
      #loginframe
      [src]="B2CSignin + '?prefill_email=' + this.email | safeUrl"
      frameborder="0"
      scrolling="no"
    >
    </iframe>
    <div class="my-4 text-center">
      <a
        class="continue-without-login w-100 text-nowrap text-reset pointer px-md-1"
        (click)="continueWithoutLogin()"
        tabindex="0"
        i18n
      >
        Continue without login
      </a>
    </div>
  </div>
</app-modal>

/* eslint-disable max-len */
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ShippingAddressFormComponent } from '@cart/components/checkout-page/checkout-personal-info/shipping-address-form/shipping-address-form.component';
import { CountryName } from '@core/constants/country-name';
import { ValidEmailRegExp } from '@core/constants/patterns';
import { phErrorMessages } from '@core/constants/ph-error-messages';
import { CartRoutes } from '@core/constants/routes.const';
import { ZipCodeMinimumLength } from '@core/constants/zip-code-minimum-length';
import { FormErrorTypes } from '@core/enums/form-error-type.enum';
import { PhExceptionErrorType } from '@core/enums/ph-exception-error-type.enum';
import { CheckoutAddress, CheckoutUserModel } from '@core/models/checkout.model';
import { GtmService } from '@core/services/gtm.service';
import { LogRocketService } from '@core/services/logrocket.service';
import { AppState } from '@core/store';
import { selectAddressIsFetched, selectAddresses, selectCountryStates } from '@core/store/address';
import { Address, AddressVerification } from '@core/store/address/address-state-models';
import { fetchAddresses, fetchCountryStates } from '@core/store/address/address.actions';
import { selectIsAuthenticated } from '@core/store/auth';
import { selectCart } from '@core/store/cart';
import {
  selectAddressErrorType,
  selectAddressVerification,
  selectCurrentAddress,
  selectEmailCheckErrorType,
  selectEmailChecked,
  selectPersonalInfo,
  selectSaveSuccessful,
  selectUpdatePersonalInfoIsLoading,
} from '@core/store/checkout';
import { PersonalInfo } from '@core/store/checkout/checkout-state-models';
import {
  cancelPersonalInfoUpdate,
  checkUserByEmail,
  openSavedAddressesModal,
  resetCheckoutAddressValidationModal,
  resetEmailCheck,
  resetSaveSuccessful,
  resetSavedAddressesModal,
  updatePersonalInfo,
} from '@core/store/checkout/checkout.actions';
import {
  selectAssignedConsultant,
  selectCurrentConsultant,
  selectIsOutdatedData,
} from '@core/store/consultant';
import {
  fetchAssignedConsultantOnCheckout,
  setIsOutdatedData,
} from '@core/store/consultant/consultant.actions';
import { selectUser, selectUserIsFetched } from '@core/store/user';
import { UserModel } from '@core/store/user/user-state-models';
import { fetchUser } from '@core/store/user/user.actions';
import { setVoucherEmail } from '@core/store/voucher/voucher.actions';
import { environment } from '@env';
import { concatLatestFrom } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { SelectOption } from '@shared/components/select/select.component';
import { TextFieldInputComponent } from '@shared/components/text-field-input/text-field-input.component';
import { countryStatesToSelectOptions } from '@shared/utils/address-utils';
import { emailValidator } from '@shared/utils/email-validator-utils';
import { isMexEnv } from '@shared/utils/environment-utils';
import { scrollToTop } from '@shared/utils/scroll-utils';
import { matchValidator } from '@shared/utils/validation.utils';
import { Observable, Subscription, combineLatest, fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  take,
  withLatestFrom,
} from 'rxjs/operators';

@Component({
  selector: 'app-checkout-personal-info',
  templateUrl: './checkout-personal-info.component.html',
  styleUrls: ['../checkout-slide.shared.scss', './checkout-personal-info.component.scss'],
})
export class CheckoutPersonalInfoComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output()
  clickNext: EventEmitter<number> = new EventEmitter<number>();

  @Input() title: string;

  @ViewChild('address', { static: true })
  shippingAddressForm: ShippingAddressFormComponent;

  @ViewChild('emailInput') emailInput: TextFieldInputComponent;
  @ViewChild('emailConfirmInput') emailConfirmInput: TextFieldInputComponent;

  readonly PhErrorTypes = PhExceptionErrorType;
  readonly BillingCustomer = 'billingCustomer';
  readonly FirstName = 'firstName';
  readonly LastName = 'lastName';
  readonly Email = 'email';
  readonly EmailConfirm = 'emailConfirm';
  readonly PhoneNumber = 'phoneNumber';
  readonly Updates = 'updates';
  readonly ShippingAddress = 'shippingAddress';
  readonly Address = 'address';
  readonly FormErrorTypes = FormErrorTypes;
  readonly ErrorMessages = phErrorMessages;
  readonly ShippingCartRoute = CartRoutes.ShoppingCart;

  personalInfoFormGroup: FormGroup;
  shippingAddressVerification$: Observable<AddressVerification>;
  countryStates$: Observable<SelectOption[]>;
  isAuthenticated$: Observable<boolean>;
  isNewsLetterSubscribe$: Observable<boolean>;
  userAddresses$: Observable<Address[]>;
  currentAddress$: Observable<Address>;
  disableButton$: Observable<boolean>;
  emailErrorType$: Observable<PhExceptionErrorType>;
  addressErrorType$: Observable<PhExceptionErrorType>;
  nextPageId: number;
  loginEmail: string;
  hideBillingAddressForm: boolean = true;
  showNameCheckbox: boolean = true;

  private addressDefault: CheckoutAddress = {
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    phoneNumber: ['', [Validators.required]],
    address: {
      id: [''],
      addressLine1: ['', [Validators.required]],
      addressLine2: [''],
      city: ['', [Validators.required]],
      country: [CountryName[environment.country]],
      state: ['', [Validators.required]],
      zipCode: ['', [Validators.required, Validators.minLength(ZipCodeMinimumLength)]],
    },
  };

  private userDefault: CheckoutUserModel = {
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    email: ['', [Validators.required, emailValidator(ValidEmailRegExp)]],
    emailConfirm: ['', [Validators.required]],
    phoneNumber: ['', [Validators.required]],
    updates: [false],
  };

  private subscriptions: Subscription = new Subscription();
  private verifiedShippingAddress: boolean;
  private isFormPrefilled: boolean = false;

  constructor(
    private store$: Store<AppState>,
    private fb: FormBuilder,
    private gtmService: GtmService,
    private logrocketService: LogRocketService,
  ) {}

  ngOnInit(): void {
    this.setShippingAddressVerification();

    if (isMexEnv) {
      this.addressDefault.address.addressLine3 = [''];
    }

    this.store$.dispatch(resetSaveSuccessful());
    this.store$.dispatch(fetchCountryStates());
    this.countryStates$ = countryStatesToSelectOptions(this.store$.select(selectCountryStates));
    this.emailErrorType$ = this.store$.select(selectEmailCheckErrorType);
    this.addressErrorType$ = this.store$.select(selectAddressErrorType);
    this.disableButton$ = combineLatest([
      this.store$.select(selectUpdatePersonalInfoIsLoading),
      this.emailErrorType$,
      this.store$.select(selectEmailChecked),
    ]).pipe(
      map(
        ([loading, error, emailChecked]) =>
          loading || error === PhExceptionErrorType.Email || !emailChecked,
      ),
    );
    this.isAuthenticated$ = this.store$.select(selectIsAuthenticated);
    this.isNewsLetterSubscribe$ = this.store$
      .select(selectUser)
      .pipe(select((user) => user.newsletters));
    this.personalInfoFormGroup = this.createFormGroup();
    this.currentAddress$ = this.store$.select(selectCurrentAddress);

    this.subscribeAddressValueChanges();
    this.subscribeUserAuthenticated();
    this.subscribePrepopulateForm();
    this.subscribeSaveSuccessful();
    this.resetEmailCheck();
    this.addressVerificationErrorHandling();

    this.gtmCheckout();
  }

  ngAfterViewInit(): void {
    if (this.emailConfirmInput) {
      const emailBlur$ = fromEvent(this.emailInput.input.nativeElement, 'blur').pipe(startWith({}));
      const emailKeyup$ = fromEvent(this.emailInput.input.nativeElement, 'keyup').pipe(
        debounceTime(500),
        startWith({}),
        distinctUntilChanged(),
      );

      const emailConfirmBlur$ = fromEvent(this.emailConfirmInput.input.nativeElement, 'blur').pipe(
        startWith({}),
      );
      const emailConfirmKeyup$ = fromEvent(
        this.emailConfirmInput.input.nativeElement,
        'keyup',
      ).pipe(debounceTime(500), startWith({}), distinctUntilChanged());

      this.subscriptions.add(
        combineLatest([emailBlur$, emailKeyup$, emailConfirmBlur$, emailConfirmKeyup$])
          .pipe(withLatestFrom(this.isAuthenticated$.pipe(filter((isAuth) => !isAuth))))
          .subscribe(() => {
            const emailField = this.personalInfoFormGroup.get(
              `${this.BillingCustomer}.${this.Email}`,
            );
            if (!!this.emailConfirmInput.value) {
              this.updateEmailConfirmValidity();
            }
            if (
              emailField.valid &&
              this.emailInput.value === this.emailConfirmInput.value &&
              this.emailInput.value !== ''
            ) {
              this.loginEmail = this.emailInput.value;
              this.store$.dispatch(checkUserByEmail({ email: this.emailInput.value }));
            }
          }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  next(nextPageId: number): void {
    this.personalInfoFormGroup.markAllAsTouched();
    this.updateEmailConfirmValidity();
    this.nextPageId = nextPageId - 1;

    if (this.personalInfoFormGroup.valid) {
      this.store$.dispatch(setIsOutdatedData({ isOutdatedData: true }));
      let personalInfo = this.personalInfoFormGroup.value;
      this.logrocketService.logRocketIdentify(personalInfo.billingCustomer.email);
      this.store$.dispatch(
        updatePersonalInfo({ personalInfo, skipAddressVerification: this.verifiedShippingAddress }),
      );
      this.resetAddressVerificationError();
    }
  }

  saveShippingAddressForm = (formGroup: FormGroup): void => {
    this.personalInfoFormGroup.patchValue({
      [this.ShippingAddress]: {
        ...formGroup.value,
      },
    });
    const personalInfo: PersonalInfo = this.personalInfoFormGroup.value;

    this.store$.dispatch(
      updatePersonalInfo({
        personalInfo,
        skipAddressVerification: true,
      }),
    );
    this.store$.dispatch(resetCheckoutAddressValidationModal());
    this.verifiedShippingAddress = true;
  };

  onDismissShippingAddress = (): void => {
    this.store$.dispatch(resetCheckoutAddressValidationModal());
    this.store$.dispatch(cancelPersonalInfoUpdate());
  };

  dismissSavedAddressesModal = (): void => {
    this.store$.dispatch(resetSavedAddressesModal());
  };

  selectAddress(selectedAddressId: number): void {
    this.userAddresses$
      .pipe(
        take(1),
        map((addresses) => addresses.find((address) => address.id === selectedAddressId)),
      )
      .subscribe((selectedAddress) => {
        this.showNameCheckbox = !selectedAddress.firstName || !selectedAddress.lastName;
        this.personalInfoFormGroup.controls[this.ShippingAddress].patchValue({
          firstName: selectedAddress.firstName,
          lastName: selectedAddress.lastName,
          address: {
            id: selectedAddress.id,
            addressLine1: selectedAddress.addressLine1,
            addressLine2: selectedAddress.addressLine2,
            city: selectedAddress.city,
            state: selectedAddress.state,
            zipCode: selectedAddress.zipCode,
          },
        });
        if (isMexEnv) {
          this.personalInfoFormGroup.controls[this.ShippingAddress].patchValue({
            addressLine3: selectedAddress.addressLine3,
          });
        }
        this.verifiedShippingAddress = true;
      });
  }

  changeAddress(): void {
    this.store$.dispatch(
      openSavedAddressesModal({
        currentAddress: {
          ...this.personalInfoFormGroup.get(`${this.ShippingAddress}.${this.Address}`).value,
        },
      }),
    );
  }

  toggleShippingName(usePersonalInfoName: boolean) {
    this.personalInfoFormGroup.get(`${this.ShippingAddress}`).patchValue({
      [this.FirstName]: usePersonalInfoName
        ? this.personalInfoFormGroup.get(`${this.BillingCustomer}.${this.FirstName}`).value
        : '',
      [this.LastName]: usePersonalInfoName
        ? this.personalInfoFormGroup.get(`${this.BillingCustomer}.${this.LastName}`).value
        : '',
    });
  }

  private prepopulateForm(
    userIsFetched: boolean,
    user: UserModel,
    addressIsFetched: boolean,
    address: Address,
    personalInfoFromCheckout: PersonalInfo,
  ): void {
    let personalInfo: PersonalInfo;
    if (!!personalInfoFromCheckout) {
      personalInfo = personalInfoFromCheckout;
    } else if (userIsFetched && addressIsFetched) {
      personalInfo = {
        billingCustomer: { ...user, emailConfirm: user.email, updates: user.newsletters },

        shippingAddress: {
          firstName: address?.firstName || '',
          lastName: address?.lastName || '',
          phoneNumber: '',
          address,
        },
      };
    }
    if (personalInfo && !this.isFormPrefilled) {
      this.personalInfoFormGroup.patchValue(personalInfo);
      this.showNameCheckbox =
        !personalInfo.shippingAddress.firstName || !personalInfo.shippingAddress.lastName;
      this.store$.dispatch(checkUserByEmail({ email: personalInfo.billingCustomer.email }));
      this.verifiedShippingAddress = true;
      this.isFormPrefilled = true;
    }
  }

  private subscribeAddressValueChanges(): void {
    this.subscriptions.add(
      this.personalInfoFormGroup
        .get(`${this.ShippingAddress}.${this.Address}`)
        .valueChanges.subscribe(() => (this.verifiedShippingAddress = false)),
    );
  }

  private setShippingAddressVerification(): void {
    this.shippingAddressVerification$ = this.store$
      .select(selectAddressVerification)
      .pipe(select((data) => data?.shippingAddressVerificationResult));
  }

  private addressVerificationErrorHandling(): void {
    this.subscriptions.add(
      this.shippingAddressVerification$.subscribe((verification) => {
        if (!!verification && !verification?.correctedAddress && verification?.isValid === false) {
          const shippingAddressForm = this.personalInfoFormGroup?.get(
            `${this.ShippingAddress}.${this.Address}`,
          ) as FormGroup;
          Object.keys(shippingAddressForm.controls).forEach((key) => {
            shippingAddressForm.get(key).setErrors({ uniqueness: true });
          });
        }
      }),
    );
  }

  private createFormGroup(): FormGroup {
    return this.fb.group({
      [this.BillingCustomer]: this.fb.group(this.userDefault, {
        validators: matchValidator(this.EmailConfirm, this.Email, this.FormErrorTypes.emailConfirm),
      }),
      [this.ShippingAddress]: this.shippingAddressForm.createGroup(this.addressDefault),
    });
  }

  private subscribeUserAuthenticated(): void {
    this.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        take(1),
      )
      .subscribe(() => {
        this.store$.dispatch(fetchAddresses());
        this.store$.dispatch(fetchUser());
        this.userAddresses$ = this.store$.select(selectAddresses);
      });
  }

  private subscribePrepopulateForm(): void {
    this.subscriptions.add(
      combineLatest([
        this.store$.select(selectUser),
        this.store$
          .select(selectAddresses)
          .pipe(map((addresses) => addresses.find((address) => address.default))),
        this.store$.select(selectPersonalInfo),
      ])
        .pipe(
          concatLatestFrom(() => [
            this.isAuthenticated$,
            this.store$.select(selectUserIsFetched),
            this.store$.select(selectAddressIsFetched),
          ]),
          filter(([[, , personalInfo], isAuth]) => isAuth || !!personalInfo),
        )
        .subscribe(([[user, address, personalInfo], , userIsFetched, addressIsFetched]) => {
          this.prepopulateForm(userIsFetched, user, addressIsFetched, address, personalInfo);
        }),
    );
  }

  private subscribeSaveSuccessful(): void {
    this.subscriptions.add(
      combineLatest([
        this.store$.select(selectSaveSuccessful),
        this.store$.select(selectIsOutdatedData),
      ])
        .pipe(
          filter(([saveSuccessful, isOutdatedData]) => saveSuccessful && !isOutdatedData),
          concatLatestFrom(() => [
            this.store$.select(selectCurrentConsultant),
            this.store$.select(selectAssignedConsultant),
            this.store$.select(selectIsAuthenticated),
          ]),
        )
        .subscribe(([, currentConsultant, assignedConsultant, isAuthenticated]) => {
          if (isAuthenticated && !currentConsultant && assignedConsultant) {
            this.store$.dispatch(fetchAssignedConsultantOnCheckout());
          } else {
            if (isMexEnv) {
              const email = this.personalInfoFormGroup
                .get(this.BillingCustomer)
                .get(this.Email).value;
              this.store$.dispatch(setVoucherEmail({ email }));
            }
            this.clickNext.emit(this.nextPageId);
            scrollToTop();
          }
        }),
    );
  }

  private gtmCheckout() {
    this.store$
      .select(selectCart)
      .pipe(take(1))
      .subscribe((cart) => this.gtmService.beginCheckout(cart));
  }

  private updateEmailConfirmValidity(): void {
    this.personalInfoFormGroup
      .get(`${this.BillingCustomer}.${this.EmailConfirm}`)
      .updateValueAndValidity({ emitEvent: false });
  }

  private resetEmailCheck(): void {
    const emailField = this.personalInfoFormGroup.get(`${this.BillingCustomer}.${this.Email}`);
    const emailConfirmField = this.personalInfoFormGroup.get(
      `${this.BillingCustomer}.${this.EmailConfirm}`,
    );
    this.subscriptions.add(
      combineLatest([emailField.valueChanges, emailConfirmField.valueChanges]).subscribe(() =>
        this.store$.dispatch(resetEmailCheck()),
      ),
    );
  }

  private resetAddressVerificationError(): void {
    this.subscriptions.add(
      this.personalInfoFormGroup
        .get(`${this.ShippingAddress}.${this.Address}`)
        .valueChanges.pipe(take(1))
        .subscribe(() => {
          const addressForm = this.personalInfoFormGroup.get(
            `${this.ShippingAddress}.${this.Address}`,
          ) as FormGroup;
          Object.keys(addressForm.controls).forEach((key) =>
            addressForm.get(key).updateValueAndValidity(),
          );
        }),
    );
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthRoutes } from '@core/constants/routes.const';
import { Locale } from '@core/enums/locale';
import { AppState } from '@core/store';
import { selectStateIsLogoutConcluded as selectLogOutSuccess } from '@core/store/auth';
import { logOut } from '@core/store/auth/auth.actions';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { getLanguage } from '@shared/utils/locale-utils';
import { b2cPolicies } from 'app/auth-config';
import { filter, first } from 'rxjs/operators';
import { LoggerService } from './logger.service';

const COMMON_SCOPES = ['openid', 'offline_access', environment.b2c.customerScope];

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private redirectUri: string;

  constructor(
    private msalService: MsalService,
    private store$: Store<AppState>,
    private loggerService: LoggerService,
    @Inject(LOCALE_ID) private localeId: Locale,
  ) {
    this.redirectUri =
      this.localeId === Locale.En
        ? `/${AuthRoutes.B2CSigninTarget}`
        : `/${this.localeId}/${AuthRoutes.B2CSigninTarget}`;
  }

  setActiveAccount(): void {
    const accounts = this.msalService.instance.getAllAccounts();
    const hasActiveAccount = this.msalService.instance.getActiveAccount();

    if (accounts.length && !hasActiveAccount) {
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
  }

  logIn(extraQueryParameters: { [key: string]: string } = {}): void {
    this.msalService.instance.loginRedirect({
      scopes: COMMON_SCOPES,
      extraQueryParameters: { ui_locales: getLanguage(this.localeId), ...extraQueryParameters },
      redirectUri: this.redirectUri,
    });
  }

  logInPhone(): void {
    this.msalService.instance.loginRedirect({
      scopes: COMMON_SCOPES,
      extraQueryParameters: { ui_locales: getLanguage(this.localeId) },
      redirectUri: this.redirectUri,
      authority: b2cPolicies.authorities.signInPhone.authority,
    });
  }

  logOut(): void {
    this.store$.dispatch(logOut());
    this.store$
      .select(selectLogOutSuccess)
      .pipe(
        filter((s) => s),
        first(),
      )
      .subscribe(() => this.msalService.logoutRedirect());
    sessionStorage.clear();
  }

  refreshToken(): void {
    this.msalService.instance
      .acquireTokenSilent({
        scopes: COMMON_SCOPES,
      })
      .catch((error) => {
        this.loggerService.warn('Error refreshing token silently. Logging out', error);
        this.logOut();
      });
  }
}

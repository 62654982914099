<div class="root container my-4">
  <div class="row">
    <div class="col-12 col-md-6 container">
      <div class="row">
        <h2 i18n class="col-12 font-weight-bolder">Login via Mobile Number</h2>
      </div>
      <iframe
        id="loginFrame"
        #loginframe
        [src]="B2CSigninPhone | safeUrl"
        frameborder="0"
        scrolling="no"
      >
      </iframe>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartNowAppLayoutComponent } from '@base/components/layout/start-now-app-layout/start-now-app-layout.component';
import { StartNowAppRoutes } from '@core/constants/routes.const';
import { StartNowAppComponent } from './components/start-now-app.component';

const routes: Routes = [
  {
    path: '',
    component: StartNowAppLayoutComponent,
    children: [
      {
        path: StartNowAppRoutes.StartNowApp,
        component: StartNowAppComponent,
        data: {
          title: $localize`New Consultant Application`,
          description: $localize`New Consultant Application - Description`,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StartNowAppRoutingModule {}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UserInfo } from '@core/store/start-now-app/start-now-app-state-models';
import { isUsaEnv } from '@shared/utils/environment-utils';
import { ssnMasking } from '@shared/utils/ssn-masking-utils';

@Component({
  selector: 'app-personal-info-card',
  templateUrl: './personal-info-card.component.html',
  styleUrls: ['./personal-info-card.component.scss'],
})
export class PersonalInfoCardComponent implements OnInit, OnChanges {
  @Input() userInfo: UserInfo;

  fullName: string;
  dateFormat: string;
  isUsaEnv: boolean;
  maskedSsnNumber: string;

  constructor() {}

  ngOnInit(): void {
    this.isUsaEnv = isUsaEnv;
    this.dateFormat = isUsaEnv ? 'MM/dd/yyyy' : 'd-MMMM-y';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userInfo?.currentValue) {
      const currentUserInfo = changes.userInfo.currentValue;

      if (currentUserInfo.middleName === '') {
        this.fullName = `${currentUserInfo.firstName} ${currentUserInfo.lastName}`;
      } else {
        // eslint-disable-next-line max-len
        this.fullName = `${currentUserInfo.firstName} ${currentUserInfo.middleName} ${currentUserInfo.lastName}`;
      }
      if (currentUserInfo.ssnNumber) {
        this.maskedSsnNumber = ssnMasking(currentUserInfo.ssnNumber);
      }
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Address, CountryState } from '@core/store/start-now-app/start-now-app-state-models';

@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss'],
})
export class AddressCardComponent implements OnInit {
  @Input() address: Address;
  @Input() countryStates: CountryState[] = [];

  stateName: string;

  constructor() {}

  ngOnInit(): void {
    const countryName = this.countryStates.find((state) => state.code === this.address.state)?.name;
    this.stateName = countryName ? countryName : this.address.state;
  }
}

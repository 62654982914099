import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { AccountInfo } from '@azure/msal-common';
import { LocalStorageKey } from '@core/enums/local-storage-key.enum';
import { TokenClaims } from '@core/models/token-claims.model';
import { LocalStorageService } from '@core/services/local-storage.service';
import { AppState } from '@core/store';
import { logInFailed, logInSuccess, setAuthLoading } from '@core/store/auth/auth.actions';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-auth-initializer',
  template: '',
})
export class AuthInitializerComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  eventSubscription: Subscription = new Subscription();

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private store$: Store<AppState>,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
  ) {}

  ngOnInit() {
    this.initAuthEvents();
    this.dispatchLoginState();
  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
  }

  private initAuthEvents() {
    this.msalService.instance.enableAccountStorageEvents();

    this.eventSubscription.add(
      this.msalBroadcastService.inProgress$.subscribe((status: InteractionStatus) => {
        switch (status) {
          case InteractionStatus.None:
            this.store$.dispatch(setAuthLoading({ loading: false }));
            break;

          default:
            this.store$.dispatch(setAuthLoading({ loading: true }));
            break;
        }
      }),
    );

    this.eventSubscription.add(
      this.msalBroadcastService.msalSubject$.subscribe((event: EventMessage) => {
        switch (event.eventType) {
          case EventType.LOGIN_SUCCESS:
          case EventType.ACCOUNT_ADDED:
            this.dispatchLoginState();
            break;

          case EventType.SSO_SILENT_SUCCESS:
            this.dispatchLoginState(false);
            break;

          case EventType.ACQUIRE_TOKEN_SUCCESS:
            this.handlePhoneVerification(
              (event.payload as AccountInfo).idTokenClaims as TokenClaims,
            );
            break;

          case EventType.ACQUIRE_TOKEN_FAILURE:
            this.msalService.logoutRedirect();
            break;

          default:
            break;
        }
      }),
    );
  }

  private dispatchLoginState(isNewLogin: boolean = true) {
    if (this.isAccountExist()) {
      this.store$.dispatch(logInSuccess({ isNewLogin }));
    } else {
      this.store$.dispatch(logInFailed({ error: null }));
    }
  }

  private isAccountExist(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  private handlePhoneVerification(tokenClaims: TokenClaims): void {
    const toVerifyPhoneNumber = this.localStorageService.getItem(
      LocalStorageKey.toVerifyPhoneNumber,
    ) as string | null;

    if (toVerifyPhoneNumber && tokenClaims.phone.endsWith(toVerifyPhoneNumber)) {
      this.toastr.success(
        $localize`Mobile number verification is success`,
        $localize`Mobile number verified!`,
      );
      this.localStorageService.removeItem(LocalStorageKey.toVerifyPhoneNumber);
    }
  }
}

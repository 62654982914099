<div *ngIf="startNowAppData">
  <div class="row">
    <div class="col-12 col-md-6">
      <app-sna-info-card-header
        cardTitle="Personal Information"
        i18n-cardTitle
        [isChangeable]="false"
      ></app-sna-info-card-header>
      <app-personal-info-card [userInfo]="startNowAppData.userInfo"></app-personal-info-card>
    </div>

    <div class="col-12 col-md-6">
      <app-sna-info-card-header
        cardTitle="Contact Information"
        i18n-cardTitle
        [isChangeable]="false"
      ></app-sna-info-card-header>
      <app-contact-information-card
        [contactInfo]="startNowAppData.contactInfo"
      ></app-contact-information-card>
    </div>

    <div class="col-12 col-md-6 mt-3">
      <app-sna-info-card-header
        [cardTitle]="addressTitles[AddressType.Home]"
        [isChangeable]="false"
      ></app-sna-info-card-header>
      <app-address-card
        [address]="selectAddress(startNowAppData.addressInfo.addresses, AddressType.Home)"
        [countryStates]="countryStates$ | async"
      >
      </app-address-card>
    </div>

    <div
      class="col-12 col-md-6 mt-3"
      *ngIf="
        selectAddress(
          startNowAppData.addressInfo.addresses,
          AddressType.Shipping
        ) as shippingAddress
      "
    >
      <app-sna-info-card-header
        [cardTitle]="addressTitles[AddressType.Shipping]"
        [isChangeable]="false"
      ></app-sna-info-card-header>
      <app-address-card [address]="shippingAddress" [countryStates]="countryStates$ | async">
      </app-address-card>
    </div>

    <div
      class="col-12 col-md-6 mt-3"
      *ngIf="
        selectAddress(startNowAppData.addressInfo.addresses, AddressType.Billing) as billingAddress
      "
    >
      <app-sna-info-card-header
        [cardTitle]="addressTitles[AddressType.Billing]"
        [isChangeable]="consultantDataIsChangable$ | async"
      ></app-sna-info-card-header>
      <app-address-card [address]="billingAddress" [countryStates]="countryStates$ | async">
      </app-address-card>
    </div>

    <div class="col-12 col-md-6 mt-3" *ngIf="(paymentStepSkipped$ | async) === false">
      <app-sna-info-card-header
        cardTitle="Payment"
        i18n-cardTitle
        [isChangeable]="false"
      ></app-sna-info-card-header>
      <app-payment-card
        [paymentInfo]="startNowAppData.startNowAppPaymentInfo"
        [skipPayment]="paymentStepSkipped$ | async"
      ></app-payment-card>
    </div>

    <div class="col-12 col-md-6 mt-3">
      <app-sna-info-card-header
        cardTitle="Kit"
        i18n-cardTitle
        [isChangeable]="false"
      ></app-sna-info-card-header>
      <app-starter-kit-info-card
        [starterKit]="startNowAppData.selectedStarterKit"
        [orderSummary]="orderSummary$ | async"
        [isZeroDepositAvailable]="isZeroDepositAvailable$ | async"
        [paymentStepSkipped]="paymentStepSkipped$ | async"
      ></app-starter-kit-info-card>
    </div>
    <div
      class="col-12 mt-4"
      *ngIf="(isZeroDepositAvailable$ | async) && (paymentStepSkipped$ | async)"
    >
      <app-less-deposit-information></app-less-deposit-information>
    </div>
  </div>
</div>

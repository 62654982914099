import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-password-card',
  templateUrl: './password-card.component.html',
  styleUrls: ['./password-card.component.scss'],
})
export class PasswordCardComponent {
  @Input() password: string;

  showPassword: boolean = false;

  constructor() {}

  togglePasswordShowing() {
    this.showPassword = !this.showPassword;
  }
}

import { CreateCardPaymentErrorType } from '@core/enums/create-card-payment-error-type.enum';
import { SuccessfulStatus } from '@core/enums/successful-status.enum';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StartNowAppState } from './start-now-app-state-models';

export const startNowAppStateFeatureKey = 'startNowAppState';

export const selectStartNowAppStateFeature = createFeatureSelector<StartNowAppState>(
  startNowAppStateFeatureKey,
);

export const selectReCaptchaIsValid = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) =>
    state ? state.consultantFinderStep.isReCaptchaValidateSuccess : SuccessfulStatus.BeforeCall,
);

export const selectCanAddressOverride = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) =>
    state ? state.addressValidationModal.payload?.allowedMelissaOverride : true,
);

export const selectStepProcessing = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.stepProcessing : false),
);

export const selectSecurityKey = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.startNowAppData.securityKey : null),
);

export const selectApplicationRegistrationId = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.startNowAppData.applicationRegistrationId : null),
);

export const selectStartNowAppData = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.startNowAppData : null),
);

export const selectUserInfo = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.startNowAppData.userInfo : null),
);

export const selectAboutYouIsNextEnabled = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.aboutYouStep.isNextEnabled : false),
);

export const selectAboutYouStep = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.aboutYouStep : null),
);

export const selectContactInfoIsNextEnabled = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.contactStep.isNextEnabled : false),
);

export const selectAddressInfoIsNextEnabled = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.addressValidationStep.isNextEnabled : false),
);

export const selectStarterKitStep = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.starterKitStep : null),
);

export const selectStarterKits = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.startNowAppData.starterKits : []),
);

export const selectAddresses = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.startNowAppData.addressInfo.addresses : null),
);

export const selectAddressInfo = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.startNowAppData.addressInfo : null),
);

export const selectAddressValidationModalData = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.addressValidationModal : null),
);

export const selectAreTermsConditionsAgreed = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.startNowAppData.areTermsConditionsAgreed : false),
);

export const selectCountryStateNames = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) =>
    state ? state.startNowAppData.addressInfo.countryStates?.map((s) => s.name) : [],
);

export const selectSnaCountryStates = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.startNowAppData.addressInfo.countryStates : []),
);

export const selectConsultantFinderStep = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.consultantFinderStep : null),
);

export const selectSkipConsultantList = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) =>
    state ? state.consultantFinderStep.consultantFinderResult.skipConsultantList : null,
);

export const selectConsultantFinderCriteria = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.consultantFinderStep.consultantFinderCriteria : null),
);

export const selectContactUniquenessCheck = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.contactStep.contactUniquenessCheck : null),
);

export const selectContactStep = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.contactStep : null),
);

export const selectAddressValidationStep = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.addressValidationStep : null),
);

export const selectSelectedStarterKit = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.startNowAppData.selectedStarterKit : null),
);

export const selectSelectedStartNowPaymentType = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.startNowAppData.selectedStartNowPaymentType : null),
);

export const selectDepositPaymentValue = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.depositPaymentValue : null),
);

export const selectReducedDepositPaymentValue = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.reducedDepositPaymentValue : null),
);

export const selectOrderSummary = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.paymentStep.orderSummary : null),
);

export const selectIsDepositPayment = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) =>
    state?.startNowAppData?.selectedStarterKit
      ? state.startNowAppData.selectedStarterKit?.isDepositPayment
      : false,
);

export const selectIsReducedDeposit = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) =>
    state ? state.startNowAppData?.selectedStarterKit?.isReducedDepositPayment : false,
);

export const selectIsZeroDepositAvailable = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.isZeroDepositAvailable : false),
);

export const selectIsReducedDepositAvailable = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.isReducedDepositAvailable : false),
);

export const selectClientToken = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) =>
    state ? state.startNowAppData.startNowAppPaymentInfo.cardPaymentInfo?.clientToken : '',
);

export const selectSnaPaymentInfo = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.startNowAppData.startNowAppPaymentInfo : null),
);

export const selectCardPaymentInfo = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) =>
    state ? state.startNowAppData.startNowAppPaymentInfo?.cardPaymentInfo : null,
);

export const selectPayPalOrder = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) =>
    state ? state.startNowAppData.startNowAppPaymentInfo.externalPaymentInfo?.order?.orderId : null,
);

export const selectCreatedCardPaymentMethodResponse = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) =>
    state ? state.startNowAppData.startNowAppPaymentInfo.createdCardPaymentMethod : null,
);

export const selectSavedCard = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => {
    const paymentMethod = state?.startNowAppData?.startNowAppPaymentInfo.createdCardPaymentMethod;
    return paymentMethod &&
      paymentMethod.errorType == CreateCardPaymentErrorType.None &&
      paymentMethod.card
      ? paymentMethod.card
      : null;
  },
);

export const selectSavedAccount = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => {
    const paymentMethod = state?.startNowAppData?.startNowAppPaymentInfo.createdCardPaymentMethod;
    return paymentMethod &&
      paymentMethod.errorType == CreateCardPaymentErrorType.None &&
      paymentMethod.account
      ? paymentMethod.account
      : null;
  },
);

export const selectRegistrationCodeValidity = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.registrationCodeCheck : null),
);

export const selectCreatedVoucherPaymentMethodResponse = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) =>
    state ? state.startNowAppData.startNowAppPaymentInfo.createdVoucherPaymentMethod : null,
);

export const selectVoucherPaymentInfo = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) =>
    state ? state.startNowAppData.startNowAppPaymentInfo.voucherPaymentInfo : null,
);

export const selectVoucherReceived = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) =>
    state
      ? state.startNowAppData?.startNowAppPaymentInfo?.voucherPaymentInfo?.vouchersReceived
      : false,
);

export const selectCreateVoucherErrorTypes = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) =>
    state
      ? state?.startNowAppData?.startNowAppPaymentInfo?.createdVoucherPaymentMethod?.errorTypes
      : [],
);

export const selectCanSubmitConsultantInfo = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.startNowAppData.canSubmitConsultantInfo : null),
);

export const selectSubmittedConsultantInfo = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.startNowAppData.submittedConsultantInfo : null),
);

export const selectConsultantStepsSkipped = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.consultantStepsSkipped : null),
);

export const selectPaymentStepSkipped = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.paymentStepSkipped : false),
);

export const selectRegistrationCodeStepSkipped = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.registrationCodeStepSkipped : false),
);

export const selectPassword = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.startNowAppData.userInfo.password : ''),
);

export const selectCongratulationStepMode = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.congratulationStepMode : null),
);

export const selectAgreementStep = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.agreementStep : null),
);

export const selectVoucherBarcodeUrls = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.voucherBarCodeUrls : []),
);

export const selectConfirmRecruiter = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.confirmRecruiter : false),
);

export const selectConsultantDataCanNotBeModified = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) =>
    state ? state?.startNowAppData?.submittedConsultantInfo?.consultantDataCanNotBeModified : false,
);

export const selectSubmitConsultantStatusCode = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) =>
    state ? state?.startNowAppData?.submitConsultantFailStatusCode : null,
);

export const selectCanChangeSaveCard = createSelector(
  selectStartNowAppStateFeature,
  (state: StartNowAppState) => (state ? state.canChangeSavedCard : true),
);

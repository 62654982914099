import { Component } from '@angular/core';
import { UserRoute } from '@core/constants/routes.const';

@Component({
  templateUrl: './returns.component.html',
  styleUrls: ['./returns.component.scss'],
})
export class ReturnsComponent {
  readonly NewReturnRoute = UserRoute.New;

  constructor() {}
}

export const AuthRoutes = {
  B2CSignin: 'b2c-signin',
  B2CSigninPhone: 'b2c-signin-phone',
  B2CSigninTarget: 'b2c-signin-target',
  Login: 'login',
  LoginViaPhone: 'login-via-phone',
};

export const BaseRoutes = {
  ContactUs: 'contact-us',
  CatalogsAndMore: 'catalogs-and-more',
  ClearConsultant: 'clear-consultant',
  PartyOrderPayment: 'party-order-payment',
  CustomerSpecialOffers: 'customer-special-offers',
};

export const CartRoutes = {
  ShoppingCart: 'shopping-cart',
  Checkout: 'checkout',
};

export const ConditionalContentRoutes = {
  HostessSpecialOffers: 'hostess-special-offers',
  About: 'about',
  StartYourBusiness: 'start-your-business',
  HostParty: 'host-a-party',
  Event: 'event',
  EventStaging1: 'event-staging-1',
  EventStaging2: 'event-staging-2',
};

export const ConsultantRoutes = {
  MyStoryPageRoute: 'my-story',
  MyOpenPartiesRoute: 'my-open-parties',
};

export const DeliveryInfoRoutes = {
  DeliveryInfo: 'delivery-info',
};

export const ProductRoutes = {
  Products: 'products',
  Product: 'product',
  Details: 'details',
  Detail: 'detail',
  P: 'p',
  NotFound: 'notfound',
};

export const StartNowAppRoutes = {
  StartNowApp: 'start-now-app',
};

export const UserRoute = {
  Registration: 'registration',
  MyAccount: 'myaccount',
  Profile: 'profile',
  Orders: 'orders',
  PaymentMethods: 'payment-methods',
  Addresses: 'addresses',
  Wishlist: 'wishlist',
  Recipes: 'recipes',
  Returns: 'returns',
  New: 'new',
};

export const RecipeAndMoreRoutes = {
  RecipesMore: 'recipes-more',
  Recipes: 'recipes',
  Recipe: 'recipe',
  Blogs: 'blogs',
  Blog: 'blog',
  Detail: 'detail',
};

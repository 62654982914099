import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BaseRoutes, ConditionalContentRoutes } from '@core/constants/routes.const';
import { AppState } from '@core/store';
import { selectHasCurrentConsultant } from '@core/store/consultant';
import { Store } from '@ngrx/store';
import { ProductUtilService } from '@shared/utils/product-util.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header-offers',
  templateUrl: './header-offers.component.html',
  styleUrls: ['./header-offers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderOffersComponent implements OnInit {
  readonly Routes = {
    CustomerSpecialOffers: BaseRoutes.CustomerSpecialOffers,
    HostessSpecialOffers: ConditionalContentRoutes.HostessSpecialOffers,
  };

  hasCurrentCurrentConsultant$: Observable<Boolean>;
  constructor(
    private store$: Store<AppState>,
    public productUtilService: ProductUtilService,
  ) {}

  ngOnInit(): void {
    this.hasCurrentCurrentConsultant$ = this.store$.select(selectHasCurrentConsultant);
  }
}

import { Country } from '@core/enums/country.enum';
import { Currency } from '@core/enums/currency.enum';
import { LogLevel } from '@core/enums/log-level.enum';

export const environment = {
  production: true,
  ecommerceBackendUri: 'https://staging-api.princesshouse.mx/',
  consultantCorner2Uri: 'https://mycorner.princesshouse.mx',
  startNowAppBackendUri: 'https://mycorner-api.princesshouse.mx/startnowapp/',
  ecommerceBackendClientId: 'storefrontApp',
  ecommerceBackendClientSecret: 'storefrontSecret',
  country: Country.Mexico,
  currency: Currency.Mexico,
  languages: [{ code: 'es-MX', lid: 2 }],
  nexioPaymentApiUri: 'https://api.nexiopay.com',
  ec1StartYourBusinessUrl: 'https://www.princesshouse.com/start-now-app/',
  agreementHtmlBaseUrl: 'https://ststartnowdev.blob.core.windows.net',
  storeLocatorUrl: 'https://www.paynet.com.mx/mapa-tiendas/index.html',
  gtmContainerId: 'GTM-N2WRRJQ',
  logRocketId: null,
  appcuesId: '134956',
  cookieBotId: '85f086ca-3fb5-4772-9f4d-57afcb11d5cd',
  recaptchaSiteKey: '6LdlTJAmAAAAAMRHU48dbSq8sX_Z8tj8os4RveCE',
  environmentName: null,
  highestConsolLogLevel: LogLevel.Error,
  b2c: {
    tenant: 'phecmexprodb2c',
    domain: 'ec-login.princesshouse.mx',
    clientId: 'd17631b8-e594-4109-9291-d9eedc54c1ba',
    customerScope:
      'https://phecmexprodb2c.onmicrosoft.com/71f6a50d-fdc2-4374-bcf3-ffb640e95512/customer',
  },
  feature: {
    product: true,
    consultantFinder: true,
    consultantCorner: true,
    footerSocial: false,
    footerExtra: false,
    contactUsLocation: false,
    voucherPaymentEC: false,
    voucherPaymentSNA: true,
    productReview: false,
    isAccessibeEnabled: true,
    mobileLogin: false,
  },
  tel: {
    mask: '(000) 000 0000',
    prefix: '+52 ',
    customerService: {
      prefix: '52',
      number: '800-265-0104',
    },
    career: {
      prefix: '52',
      number: '800-265-0104',
    },
  },
  datadog: null,
};

/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AuthService } from '@core/services/auth.service';
import { getB2CError } from '@shared/utils/handle-b2c-error-utils';
import { Observable, of } from 'rxjs';

@Component({
  templateUrl: 'b2c-signin.component.html',
  styleUrls: ['./b2c-signin.component.scss'],
})
export class B2cSigninComponent implements OnInit {
  errorMessage$: Observable<string>;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private msalBroadcastService: MsalBroadcastService,
  ) {}

  ngOnInit(): void {
    this.errorMessage$ = getB2CError(this.msalBroadcastService);
    this.login();
  }

  resetError(): void {
    this.errorMessage$ = of('');
    this.login();
  }

  private login(): void {
    const prefill_email = this.route.snapshot.queryParamMap.get('prefill_email');
    if (prefill_email) {
      this.authService.logIn({ prefill_email });
    } else {
      this.authService.logIn();
    }
  }
}

<div class="header-nav">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 d-flex justify-content-center align-items-center">
        <div class="d-md-none w-100">
          <app-search-box></app-search-box>
        </div>
        <nav
          class="navbar navbar-expand-md p-0"
          id="navigation"
          aria-label="Main navigation"
          i18n-aria-label
          (keyup.escape)="toggle()"
        >
          <div
            id="navbarCollapse"
            class="collapse navbar-collapse p-0"
            [ngbCollapse]="menuCollapsed"
            (shown)="onShown()"
          >
            <div class="row d-md-none">
              <div class="col-12 d-flex align-items-center bg-primary text-white">
                <button
                  class="bi bi-x-lg btn-close"
                  (click)="toggle()"
                  aria-label="Close navigation"
                  i18n-aria-label
                ></button>
              </div>
            </div>
            <ul
              class="navbar-nav p-0 m-0"
              (click)="toggle()"
              role="menu"
              aria-labelledby="navigationToggler"
            >
              <li *ngIf="feature.product" role="presentation">
                <a
                  class="link px-2"
                  routerLink="/{{ ProductRoutes.Products }}"
                  role="menuitem"
                  i18n
                  #firstElement
                  >Shop</a
                >
              </li>
              <li role="presentation"
                ><a
                  class="link px-2"
                  routerLink=""
                  (click)="onStartYourBusinessClick()"
                  role="menuitem"
                  i18n
                  >Start Your Business</a
                ></li
              >
              <li role="presentation"
                ><a
                  class="link px-2"
                  [routerLink]="'/' + ConditionalContentRoutes.HostParty"
                  role="menuitem"
                  i18n
                  >Host</a
                ></li
              >
              <li *ngIf="appInitService.Settings.ec.isRecipeEnabled" role="presentation"
                ><a class="link px-2" routerLink="/recipes-more" role="menuitem" i18n
                  >Recipes + More</a
                ></li
              >
              <li role="presentation"
                ><a
                  class="link px-2"
                  [routerLink]="'/' + ConditionalContentRoutes.About"
                  role="menuitem"
                  i18n
                  >Our Story</a
                ></li
              >
              <li *ngIf="isAuthenticated$ | async" role="presentation">
                <a class="link px-2 d-md-none" routerLink="" role="menuitem" (click)="logout()" i18n
                  >Logout</a
                >
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</div>

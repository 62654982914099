import { ConsultantResponse } from '@core/dto/consultant.dto';
import { PhErrorResponse } from '@core/dto/ph-error';
import { UpdateUserRequest } from '@core/dto/user.dto';
import { UserRegistrationForm } from '@core/models/user-registration.model';
import { createAction, props } from '@ngrx/store';
import { UserModel } from './user-state-models';

export enum UserActionTypes {
  resetUserStore = '[User] Reset user store',
  registerUser = '[User] Register User',
  registerUserSuccess = '[User] Register User Success',
  registerUserFailure = '[User] Register User Failure',
  fetchUser = '[User] Fetch user',
  fetchUserSuccess = '[User] Fetch user success',
  fetchUserFailure = '[User] Fetch user failure',
  updateUser = '[User] Update user',
  updateUserSuccess = '[User] Update user success',
  updateUserFailure = '[User] Update user failure',
  fetchUserWithConsultant = '[User] Fetch user with consultant data',
  fetchUserWithConsultantSuccess = '[User] Fetch user with consultant data success',
  fetchUserWithConsultantFailure = '[User] Fetch user with consultant data failure',
  updateAssignedConsultant = '[User] Update Assigned consultant',
  updateAssignedConsultantSuccess = '[User] Update Assigned consultant success',
  updateAssignedConsultantFailure = '[User] Update Assigned consultant failure',

  resetRegistrationFailedErrorType = '[User] Reset registration failed error type',
}

export const resetUserStore = createAction(UserActionTypes.resetUserStore);

/**
 * Register user
 */
export const registerUser = createAction(
  UserActionTypes.registerUser,
  props<{ payload: UserRegistrationForm; token: string }>(),
);

export const registerUserSuccess = createAction(
  UserActionTypes.registerUserSuccess,
  props<{ payload: { email: string } }>(),
);

export const registerUserFailure = createAction(
  UserActionTypes.registerUserFailure,
  props<{ error: PhErrorResponse }>(),
);

/**
 * Fetch user
 */
export const fetchUser = createAction(UserActionTypes.fetchUser);
export const fetchUserSuccess = createAction(
  UserActionTypes.fetchUserSuccess,
  props<{ user: UserModel }>(),
);
export const fetchUserFailure = createAction(
  UserActionTypes.fetchUserFailure,
  props<{ error: PhErrorResponse }>(),
);

/**
- * Fetch user with consultant data
- */
export const fetchUserWithConsultant = createAction(UserActionTypes.fetchUserWithConsultant);
export const fetchUserWithConsultantSuccess = createAction(
  UserActionTypes.fetchUserWithConsultantSuccess,
  props<{ user: UserModel; consultant: ConsultantResponse }>(),
);
export const fetchUserWithConsultantFailure = createAction(
  UserActionTypes.fetchUserWithConsultantFailure,
  props<{ error: PhErrorResponse }>(),
);

/**
 * Update user
 */
export const updateUser = createAction(
  UserActionTypes.updateUser,
  props<{ user: UpdateUserRequest }>(),
);
export const updateUserSuccess = createAction(
  UserActionTypes.updateUserSuccess,
  props<{ user: UserModel }>(),
);
export const updateUserFailure = createAction(
  UserActionTypes.updateUserFailure,
  props<{ error: PhErrorResponse }>(),
);

export const updateAssignedConsultant = createAction(
  UserActionTypes.updateAssignedConsultant,
  props<{ consultantProwessId: number }>(),
);

export const updateAssignedConsultantSuccess = createAction(
  UserActionTypes.updateAssignedConsultantSuccess,
);

export const updateAssignedConsultantFailure = createAction(
  UserActionTypes.updateAssignedConsultantFailure,
);

export const resetRegistrationFailedErrorType = createAction(
  UserActionTypes.resetRegistrationFailedErrorType,
);

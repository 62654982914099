<span class="font-italic text-span">
  <p class="pl-3 pr-4" i18n>
    *You selected the $0 deposit option for your Starter Kit. Once you reach $750 or more in personal sales during
    the first 60 days**, you’ll receive a Starter Kit with over $600 in products and business supplies – the full
    cost of $199*** will be automatically deducted from your profits.
  </p>
  <p class="pl-3 pr-5" i18n>
    If you do not reach $750 or more in sales during your first 60 days following submission of your application &
    agreement, you will continue to have access to Consultant’s Corner and your online store. However, you will
    forfeit the opportunity to purchase the Starter Kit for $199*** (which includes products and business supplies
    valued at over $600).
  </p>
</span>
<span class="font-italic text-span-no-background">
  <p class="pl-3 pr-4" i18n>
    **All references to “sales” refer to Qualifying Volume (QV), which can come from any combination of party
    and/or eCommerce orders submitted with a deposit of 50% or more during Period 9, 2024 (August 10, 2024 –
    September 6, 2024).
  </p>
  <p class="pl-3 pr-4" i18n>
    ***Plus tax, no delivery fee. There is no guarantee that a Consultant will earn any income or qualify for
    Princess House incentives and/or trips. In 2023, the average commissions paid to active Princess House
    Consultants was $1,103. And, on average, 1,089 hardworking Consultants achieve the Incentive Trip each year.
    Visit <a href="https://www.princesshouse.com/en-US/income-disclosure" target="_blank" class="tracking-url">princesshouse.com/income-disclosure</a> for more information.
  </p>
</span>

<div class="center">
  <div class="max-width d-flex align-items-center">
    <i class="bi bi-exclamation-circle-fill mr-3"></i>
    <span>{{ PromotionMessageLocale[message] }}</span>
  </div>

  <div class="w-100 d-flex justify-content-center mb-4">
    <button
      *ngIf="
        message === PromotionMessage.FullPriceWithoutRegularCustomerSpecial ||
        message === PromotionMessage.NonQualifyingPurchaseCustomerSpecial ||
        message === PromotionMessage.NonQualifyingPurchaseCollectAndSave
      "
      type="button"
      class="btn btn-primary mt-3"
      [routerLink]="'/' + CustomerSpecialOffersRoute"
      i18n
    >
      View Current Specials
    </button>
    <button
      *ngIf="message === PromotionMessage.SurpriseBuyProgram"
      type="button"
      class="btn btn-primary mt-3"
      (click)="addSurpriseBuy()"
      [disabled]="addSurpriseBuyLoading$ | async"
      i18n
    >
      Add Surprise Buy
    </button>
  </div>
</div>

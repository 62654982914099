<div class="card">
  <div class="ph-row p-md-2">
    <span class="text-double-dot" i18n>Your Recruiter</span>
    <strong>{{ consultantName }}</strong>
  </div>
  <div class="ph-row px-md-2 pb-md-2 border-bottom-0">
    <div class="form-check custom-checkbox">
      <input
        id="recruiter"
        type="checkbox"
        class="form-check-input custom-control-input"
        [(ngModel)]="confirmRecruiter"
        (ngModelChange)="confirmRecruiterChangeHandler($event)"
      />
      <label class="form-check-label text-uppercase mb-0 custom-control-label" for="recruiter" i18n>
        I confirm this recruiter is correct
      </label>
    </div>
  </div>
</div>
<span class="small-text" i18n>
  <span class="text-purple">Note:</span> If the recruiter listed above is not correct,
  <a
    (click)="navigateToStartNowApp()"
    role="button"
    class="font-weight-bold"
    [class]="{
      pointer: !stepProcessing && !consultantDataCanNotBeModified,
      disabled: stepProcessing || consultantDataCanNotBeModified
    }"
  >
    please click here to return to the home page.
  </a>
  You will need to complete the application under the correct recruiters website to continue.
</span>

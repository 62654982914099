import { Component, ViewChild } from '@angular/core';
import { BaseRoutes, CartRoutes } from '@core/constants/routes.const';
import { LocalStorageKey } from '@core/enums/local-storage-key.enum';
import { ModalConfig } from '@core/models/modal-config.model';
import { LocalStorageService } from '@core/services/local-storage.service';
import { ModalComponent } from '@shared/components/modal/modal.component';

@Component({
  selector: 'app-cart-item-updated-warning-modal',
  templateUrl: './cart-item-updated-warning-modal.component.html',
  styleUrls: ['./cart-item-updated-warning-modal.component.scss'],
})
export class CartItemUpdatedWarningModalComponent {
  readonly Routes = {
    CustomerSpecialOffers: BaseRoutes.CustomerSpecialOffers,
    ShoppingCart: CartRoutes.ShoppingCart,
  };

  modalConfig: ModalConfig = {
    ngbModalOptions: { size: 'md' },
    title: $localize`Expired Special(s) Removed`,
    hideCloseButton: true,
    hideDismissButton: true,
    onDismiss: this.close.bind(this),
    onClose: this.close.bind(this),
    onDismissHeader: this.close.bind(this),
  };

  @ViewChild('modal') private modal: ModalComponent;

  constructor(private localStorageService: LocalStorageService) {}

  open(): void {
    this.modal.open();
  }

  close(): void {
    this.localStorageService.removeItem(LocalStorageKey.isCartUpdated);
    this.modal.close();
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AuthService } from '@core/services/auth.service';
import { getB2CError } from '@shared/utils/handle-b2c-error-utils';
import { Observable, of, Subscription } from 'rxjs';

@Component({
  templateUrl: 'b2c-signin.component.html',
  styleUrls: ['./b2c-signin.component.scss'],
})
export class B2cSigninPhoneComponent implements OnInit, OnDestroy {
  errorMessage$: Observable<string>;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private msalBroadcastService: MsalBroadcastService,
  ) {}

  ngOnInit(): void {
    this.errorMessage$ = getB2CError(this.msalBroadcastService);

    this.authService.logInPhone();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  resetError(): void {
    this.errorMessage$ = of('');
    this.authService.logInPhone();
  }
}

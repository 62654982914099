import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ConsultantFinderModalComponent } from '@consultant/consultant-finder-modal/consultant-finder-modal.component';
import { BaseRoutes, StartNowAppRoutes } from '@core/constants/routes.const';
import { Country } from '@core/enums/country.enum';
import { RouterQueryParams } from '@core/enums/router-query-param.enum';
import { WebsiteMode } from '@core/enums/website-mode.enum';
import { AppState } from '@core/store';
import { selectIsNewLogin } from '@core/store/auth';
import { selectCurrentConsultant, selectIsCurrentConsultantCleared } from '@core/store/consultant';
import { Consultant } from '@core/store/consultant/consultant-state-models';
import {
  fetchCurrentConsultantByLoginName,
  fetchCurrentConsultantByVanityName,
} from '@core/store/consultant/consultant.actions';
import { selectUser } from '@core/store/user';
import { updateAssignedConsultant } from '@core/store/user/user.actions';
import { selectWebsiteMode } from '@core/store/website-mode';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, take, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-consultant-initializer',
  templateUrl: './consultant-initializer.component.html',
})
export class ConsultantInitializerComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private store$: Store<AppState>,
    private router: Router,
    private location: Location,
  ) {}

  readonly Routes = {
    ClearConsultant: BaseRoutes.ClearConsultant,
  };

  currentConsultant$: Observable<Consultant>;

  private subscriptions: Subscription = new Subscription();

  @ViewChild('consultantFinder') private consultantFinderModal: ConsultantFinderModalComponent;

  ngOnInit(): void {
    this.currentConsultant$ = this.store$.select(selectCurrentConsultant);

    this.initSetCidParam();
    this.initFetchCurrentConsultant();
    this.initConsultantAssigner();
  }

  ngAfterViewInit(): void {
    this.initOpenConsultantFinder();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initFetchCurrentConsultant() {
    // eslint-disable-next-line max-len
    /* TODO: should refactor the logic: handle local storage here & isCurrentConsultantFetched in separate action */
    this.initFetchCurrentConsultantByVanityName();
    /* TODO: After all the CC1 users are moved to CC2 have to remove the loginName 7 lnid*/
    this.initFetchCurrentConsultantByLoginName();
  }

  private initOpenConsultantFinder() {
    combineLatest([
      this.store$.select(selectIsNewLogin),
      this.store$.select(selectWebsiteMode),
      this.store$.select(selectUser),
    ])
      .pipe(
        filter(([, , user]) => !!user.email && !user.activeConsultantProwessId),
        filter(([newLogin]) => newLogin),
        filter(([, websiteMode]) => websiteMode === WebsiteMode.corporate),
        filter(() => !window.location.href.includes('/deliveryinfo')),
        take(1),
      )
      .subscribe(() => this.consultantFinderModal.open());
  }

  private initSetCidParam() {
    this.subscriptions.add(
      combineLatest([
        this.router.events.pipe(filter((e) => e instanceof NavigationEnd)),
        this.currentConsultant$.pipe(distinctUntilChanged()),
      ])
        .pipe(
          map(([navEnd, currentConsultant]: [NavigationEnd, Consultant]) => ({
            navEnd,
            currentConsultant,
          })),
          filter(
            ({ navEnd }) =>
              !navEnd.url.includes(`/${this.Routes.ClearConsultant}`) &&
              !navEnd.url.includes(`/${StartNowAppRoutes.StartNowApp}`),
          ),
          filter(({ currentConsultant }) => !!currentConsultant),
          distinctUntilChanged(
            (prev, curr) =>
              prev.currentConsultant.vanityName === curr.currentConsultant.vanityName &&
              prev.navEnd.id === curr.navEnd.id,
          ),
        )
        .subscribe(({ currentConsultant }) => this.setCidUrlParam(currentConsultant.vanityName)),
    );
  }

  private initFetchCurrentConsultantByVanityName() {
    this.subscriptions.add(
      combineLatest([
        this.router.events.pipe(filter((e) => e instanceof NavigationStart)),
        this.currentConsultant$,
      ])
        .pipe(
          withLatestFrom(this.store$.select(selectIsCurrentConsultantCleared)),
          map<
            [[NavigationStart, Consultant], boolean],
            {
              navStart: NavigationStart;
              currentConsultant: Consultant;
              currentConsultantCleared: boolean;
            }
          >(([[navStart, currentConsultant], currentConsultantCleared]) => ({
            navStart,
            currentConsultant,
            currentConsultantCleared,
          })),
          filter(({ currentConsultantCleared }) => !currentConsultantCleared),
          filter(({ navStart }) => !navStart.url.includes(`/${this.Routes.ClearConsultant}`)),
          filter(
            ({ navStart }) =>
              this.router.parseUrl(navStart.url).queryParams?.[RouterQueryParams.cid] ||
              !this.router.parseUrl(navStart.url).queryParams?.[RouterQueryParams.lnid],
          ),
          distinctUntilChanged(
            ({ navStart: prevEvent }, { navStart: currEvent, currentConsultant }) => {
              const prevCid = this.router.parseUrl(prevEvent.url).queryParams?.[
                RouterQueryParams.cid
              ];
              const currCid = this.router.parseUrl(currEvent.url).queryParams?.[
                RouterQueryParams.cid
              ];
              return prevCid === currCid || (!currCid && !!currentConsultant);
            },
          ),
        )
        .subscribe(({ navStart }) => {
          const currUrlTree = this.router.parseUrl(navStart.url);
          this.store$.dispatch(
            fetchCurrentConsultantByVanityName({
              vanityName: currUrlTree.queryParams?.[RouterQueryParams.cid],
              filterCountry: this.shouldFilterCountry(navStart.url),
            }),
          );
        }),
    );
  }

  private initFetchCurrentConsultantByLoginName() {
    this.subscriptions.add(
      combineLatest([
        this.router.events.pipe(filter((e) => e instanceof NavigationStart)),
        this.currentConsultant$,
      ])
        .pipe(
          filter(
            ([navStart]: [NavigationStart, Consultant]) =>
              !navStart.url.includes(`/${this.Routes.ClearConsultant}`),
          ),
          filter(
            ([navStart]: [NavigationStart, Consultant]) =>
              !this.router.parseUrl(navStart.url).queryParams?.[RouterQueryParams.cid] &&
              this.router.parseUrl(navStart.url).queryParams?.[RouterQueryParams.lnid],
          ),
          distinctUntilChanged(
            (
              [prevEvent]: [NavigationStart, Consultant],
              [currEvent, currConsultant]: [NavigationStart, Consultant],
            ) => {
              const prevLnid = this.router.parseUrl(prevEvent.url).queryParams[
                RouterQueryParams.lnid
              ];
              const currLnid = this.router.parseUrl(currEvent.url).queryParams[
                RouterQueryParams.lnid
              ];
              return prevLnid === currLnid || (!currLnid && !!currConsultant);
            },
          ),
        )
        .subscribe(([navEnd]: [NavigationStart, Consultant]) => {
          const currUrlTree = this.router.parseUrl(navEnd.url);
          this.store$.dispatch(
            fetchCurrentConsultantByLoginName({
              loginName: currUrlTree.queryParams[RouterQueryParams.lnid],
              filterCountry: this.shouldFilterCountry(navEnd.url),
            }),
          );
        }),
    );
  }

  private initConsultantAssigner() {
    const user$ = this.store$.select(selectUser);

    combineLatest([user$, this.currentConsultant$])
      .pipe(
        filter(([user]) => user.email && !user.activeConsultantProwessId),
        filter(([, currentConsultant]) => !!currentConsultant),
        take(1),
      )
      .subscribe(([, currentConsultant]) => {
        this.store$.dispatch(
          updateAssignedConsultant({ consultantProwessId: currentConsultant.beeNumber }),
        );
      });
  }

  private setCidUrlParam(cid: string): void {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set(RouterQueryParams.cid, cid);
    this.location.replaceState(this.router.url.split('?')[0], urlParams.toString());
  }

  private shouldFilterCountry(route: string): boolean {
    const currentRoute = route.split('?')[0];
    let filterCountry = true;

    if (environment.country === Country.Mexico) {
      filterCountry = currentRoute != `/${StartNowAppRoutes.StartNowApp}`;
    }

    return filterCountry;
  }
}

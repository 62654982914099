<div class="root">
  <app-header-container>
    <app-header-sna></app-header-sna>
  </app-header-container>

  <div id="mainContent">
    <router-outlet></router-outlet>
  </div>

  <div>
    <app-footer-container>
      <app-footer-top [shade]="shade.Shade2"></app-footer-top>
      <app-footer-bottom [shade]="shade.Shade5"></app-footer-bottom>
    </app-footer-container>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { PaymentCardType, PaymentType } from '@core/enums/payment-type.enum';
import { StartNowAppPaymentInfo } from '@core/store/start-now-app/start-now-app-state-models';
import { environment } from '@env';

@Component({
  selector: 'app-payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss'],
})
export class PaymentCardComponent {
  @Input() paymentInfo: StartNowAppPaymentInfo;
  @Input() skipPayment: boolean;

  public readonly CardType = PaymentCardType;
  public readonly PaymentType = PaymentType;
  public readonly storeLocatorUrl = environment.storeLocatorUrl;

  readonly cardTypeText = {
    [PaymentCardType.DebitCard]: $localize`Debit card`,
    [PaymentCardType.CreditCard]: $localize`Credit card`,
  };

  constructor() {}
}

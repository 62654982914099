import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CartRoutes, ConsultantRoutes, StartNowAppRoutes } from '@core/constants/routes.const';
import { AppState } from '@core/store';
import {
  selectCurrentConsultantParties,
  selectIsConsultantCheckingDone,
  selectParty,
} from '@core/store/consultant';
import { Store } from '@ngrx/store';
import { ConfirmModalComponent } from '@shared/components/confirm-modal/confirm-modal.component';
import { combineLatest } from 'rxjs';
import { filter, take, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-open-party-notification-initializer',
  templateUrl: './open-party-notification-initializer.component.html',
})
export class OpenPartyNotificationInitializerComponent implements OnInit {
  @ViewChild('notificationModal') private notificationModal: ConfirmModalComponent;

  constructor(
    private store$: Store<AppState>,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const party$ = this.store$.select(selectParty);
    const openParties$ = this.store$.select(selectCurrentConsultantParties);
    const isConsultantCheckingDone$ = this.store$.select(selectIsConsultantCheckingDone);

    combineLatest([openParties$, isConsultantCheckingDone$])
      .pipe(
        filter(() => !window.location.href.includes(`/${CartRoutes.Checkout}`)),
        filter(() => !window.location.href.includes(`/${StartNowAppRoutes.StartNowApp}`)),
        filter(() => !window.location.href.includes('/deliveryinfo')),
        filter(([, isConsultantCheckingDone]) => isConsultantCheckingDone),
        withLatestFrom(party$),
        filter(([[openParties], party]) => openParties?.length && party === null),
        take(1),
      )
      .subscribe(() => this.notificationModal.open());
  }

  onYes() {
    this.router.navigate([ConsultantRoutes.MyOpenPartiesRoute]);
  }
}

import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ConsultantRoutes } from '@core/constants/routes.const';
import { ConsultantHeaderContent } from '@core/enums/consultant-header-content.enum';
import { AppState } from '@core/store';
import { selectHasCurrentConsultant } from '@core/store/consultant';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-header-consultant',
  templateUrl: './header-consultant.component.html',
  styleUrls: ['./header-consultant.component.scss'],
})
export class HeaderConsultantComponent implements OnInit {
  hasCurrentCurrentConsultant$: Observable<Boolean>;
  displayConsultantHeaderContent: ConsultantHeaderContent = ConsultantHeaderContent.none;
  readonly ConsultantHeaderContent = ConsultantHeaderContent;

  constructor(
    private store$: Store<AppState>,
    @Inject(LOCALE_ID) private localeId: string,
    private router: Router,
  ) {
    this.router.events
      .pipe(
        startWith(new NavigationEnd(0, window.location.pathname, window.location.pathname)),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => this.changeConsultantHeaderContentDisplay(event.url));
  }

  ngOnInit(): void {
    this.hasCurrentCurrentConsultant$ = this.store$.select(selectHasCurrentConsultant);
  }

  private changeConsultantHeaderContentDisplay(url: string) {
    switch (url.replace(`/${this.localeId}`, '').split('?')[0]) {
      case '/' + ConsultantRoutes.MyStoryPageRoute:
      case '/' + ConsultantRoutes.MyOpenPartiesRoute:
        this.displayConsultantHeaderContent = ConsultantHeaderContent.consultantBanner;
        break;

      default:
        this.displayConsultantHeaderContent = ConsultantHeaderContent.none;
        break;
    }
  }
}
